<template id="home">
  <div class="container">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <h1>Home</h1>
      <hr />
      <h2>Statistiek</h2>
      <div class="row">
        <div class="col-6" v-if="permissions.voeding">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Voeding</h5>
              <p class="card-text">Vandaag: {{ aantalVoeding }} pakketten</p>
              <v-chart class="chart" :option="voedingOption" />
            </div>
          </div>
        </div>
        <div class="col-6" v-if="permissions.voeding">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Voeding per type</h5>
              <v-chart class="chart" :option="voedingTypeOption" />
            </div>
          </div>
        </div>
        <div class="col-6" v-if="permissions.zeephuisje">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Zeephuisje</h5>
              <p class="card-text">
                Deze maand: {{ aantalZeephuisje }} pakketten
              </p>
              <v-chart class="chart" :option="zeephuisjeOption" />
              <!-- <zeephuisje-chart :chartdata="zeephuisjeData"></zeephuisje-chart> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authService } from "../_services/auth.service";
import { dashboardService } from "@/_services/dashboard.service";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";


use([
  BarChart,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent
]);


export default {
  template: "#home",
  components: { VChart },
  data: function () {
    return {
      loading: false,
      permissions: {
        voeding: false,
        zeephuisje: false,
      },
      voedingOption: {},
      zeephuisjeOption: {},
      voedingTypeOption: {},
      aantalVoeding: 0,

      zeephuisjeData: {
        labels: [],
        datasets: [],
      },
      aantalZeephuisje: 0,
    };
  },
  provide: {
    [THEME_KEY]: "light"
  },
  methods: {
    handleVoedingResponse: function (resp) {
      this.voedingOption = {
        xAxis: {
          type: 'category',
          data: Object.keys(resp).map((x) => x.substring(0, 10)),
          axisLabel: {
            //show: false,
            rotate: 40,
            interval: 0
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: Object.values(resp),
            type: 'bar',
            showBackground: true,
            itemStyle: {
              color: 'rgb(80, 105, 176)'
            },
            label: {
              show: true,
              position: 'top',
              valueAnimation: true
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      for (let key in resp) {
        if (key.substr(0, 10) == new Date().toISOString().substr(0, 10)) {
          this.aantalVoeding = resp[key];
          break;
        }
      }
    },
    handleVoedingTypeResponse: function (resp) {
      let types = [];
      for (let key in resp) {
        for (let key2 in resp[key]) {
          if (!types.includes(key2)) {
            types.push(key2);
          }
        }
      }

      // put Gewoon in front
      types = types.filter(x => x != "Gewoon");
      types.unshift("Gewoon");

      const series = types.map(t => {
        return {
          name: t,
          type: 'bar',
          stack: 'total',
          label: {
            show: true
          },
          emphasis: {
            focus: 'series'
          },
          data: Object.keys(resp).map((x) => resp[x][t])
        }
      })

      this.voedingTypeOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: Object.keys(resp).map((x) => x.substring(0, 10)),
          axisLabel: {
            //show: false,
            rotate: 40,
            interval: 0
          }
        },
        yAxis: {
          type: 'value'
        },
        series: series,
      }
    },
    handleZeephuisjeResponse: function (resp) {
      this.zeephuisjeOption = {
        xAxis: {
          type: 'category',
          data: Object.keys(resp).map((x) => x.substring(0, 10))
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: Object.values(resp),
            type: 'bar',
            showBackground: true,
            itemStyle: {
              color: 'rgb(80, 105, 176)'
            },
            label: {
              show: true,
              position: 'top',
              valueAnimation: true
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }

      for (let key in resp) {
        if (key.substr(0, 10) == new Date().toISOString().substr(0, 7)) {
          this.aantalZeephuisje = resp[key];
          break;
        }
      }
    },
  },

  created: function () {
    // if name of user is "Markt" redirect to the scanner
    const userInfo = JSON.parse(window.localStorage.getItem("user"));
    if (userInfo.name == "Markt") {
      this.$router.push("/markten/scan");
      return
    }

    authService.canIDo("GET", "/v1/dashboard/voeding/").then((r) => {
      this.permissions.voeding = r;
      if (r) {
        dashboardService.getVoeding().then(this.handleVoedingResponse);
        dashboardService.getVoedingType().then(this.handleVoedingTypeResponse);
      }
    });
    authService.canIDo("GET", "/v1/dashboard/zeephuisje/").then((r) => {
      this.permissions.zeephuisje = r;
      if (r) {
        dashboardService.getZeephuisje().then(this.handleZeephuisjeResponse);
      }
    });
  },
};
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>