import config from "config";
import { authHeader } from "../_helpers/auth-header";

let permissionsCache = null;

const login = (username, password) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${config.apiUrl}/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user.token) {
        localStorage.setItem("user", JSON.stringify(user));
      }

      return user;
    });
};

const fetchWebauthLoginChallenge = () => {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };

  return fetch(
    `${config.apiUrl}/auth/webauthn/login/begin`,
    requestOptions
  ).then(handleResponse);
};

const fetchWebauthLoginFinish = (data) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/auth/webauthn/login/finish`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user.token) {
        localStorage.setItem("user", JSON.stringify(user));
      }

      return user;
    });
};

const fetchWebauthRegisterChallenge = (username) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(
    `${config.apiUrl}/v1/users/auth/webauthn/register/${username}/begin`,
    requestOptions
  ).then(handleResponse);
};

const fetchWebauthRegisterFinish = (username, data) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.apiUrl}/v1/users/auth/webauthn/register/${username}/finish`,
    requestOptions
  ).then(handleResponse);
};

const logout = () => {
  localStorage.removeItem("user");
};

const getName = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user.name;
};

const getRoles = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/auth/whoami/roles`, requestOptions).then(
    handleResponse
  );
};

// verb, path
const canIDo = async (needVerb, needPath) => {
  // allow nothing if not logged in
  if (!localStorage.key("user")) {
    return false;
  }

  if (!permissionsCache) {
    permissionsCache = await getRoles();
  }

  for (let role of permissionsCache) {
    let isVerb = false;
    let isPath = false;

    for (let verb of role.verbs) {
      if (verb.content === needVerb) {
        isVerb = true;
        break;
      }
    }

    if (!isVerb) {
      break;
    }

    for (let ep of role.endpoints) {
      if (needPath.match(new RegExp(ep.content.replace("*", ".*")))) {
        isPath = true;
        break;
      }
      console.log(needPath, ep.content);
    }

    if (isVerb && isPath) {
      return true;
    }
  }

  return false;
};

const check = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/auth/check`, requestOptions).then(
    handleResponse
  );
};

export const authService = {
  login,
  logout,
  check,
  canIDo,
  getName,
  fetchWebauthRegisterChallenge,
  fetchWebauthRegisterFinish,
  fetchWebauthLoginChallenge,
  fetchWebauthLoginFinish,
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};
