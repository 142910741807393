<template>
  <div class="container" id="app">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row">
        <div class="col-12 mb-2 mt-2">
          <span class="float-start">
            <button type="button" class="btn btn-light btn-lg" :disabled="saving" v-on:click="goBack()">
              <i class="fad fa-long-arrow-left" />Terug
            </button>
          </span>
          <span class="float-end" v-if="inschrijving.aanwezig">
            <button type="button" class="btn btn-outline-danger btn-lg" v-on:click="uitschrijven()" :disabled="saving">
              <i class="far fa-sad-tear"></i> Uitschrijven
            </button>
            &nbsp;
            <button type="button" class="btn btn-primary btn-lg" v-on:click="print()" :disabled="saving">
              <i class="far fa-print" /> Print
            </button>
            &nbsp;
            <button type="button" class="btn btn-success btn-lg" v-on:click="save()" :disabled="saving || !hasChanges()">
              <i class="far fa-save" /> Opslaan
            </button>
          </span>
          <span class="float-end" v-else>
            <button type="button" class="btn btn-success btn-lg" v-on:click="inschrijven()" :disabled="saving">
              <i class="fas fa-calendar-plus"></i> Inschrijven voor {{ inschrijving.evenement.naam }}
            </button>
          </span>
        </div>
      </div>
      <h3>Info</h3>
      <div class="row mb-3 materiaal-info" v-if="getStatus(lid) == 'Niet Actief'">
        <div class="col-3 mb-3">
          <h5>Status</h5>
          <p class="info" style="color: red">Niet Actief</p>
        </div>
      </div>
      <div class="row mb-3 materiaal-info" v-if="lid.nietBetalen">
        <div class="col-3 mb-3">
          <h5>Info</h5>
          <p class="info" style="color: red">Niet Betalen</p>
        </div>
      </div>
      <div class="row mb-3 materiaal-info">
        <div class="col-3 mb-3">
          <h5>Naam</h5>
          <p class="info">
            {{ lid.gezin.leden.find((l) => l.gezinsHoofd).voornaam }}
            {{ lid.gezin.leden.find((l) => l.gezinsHoofd).achternaam }}
          </p>
        </div>
        <div class="col-3 mb-3">
          <h5>MVM Nummer</h5>
          <p class="info">MVM{{ lid.mvmnummer }}</p>
        </div>
        <div class="col-3">
          <h5>Opmerking</h5>
          <textarea class="form-control" v-model="inschrijving.opmerking" rows="2"></textarea>
        </div>
        <div class="col-3 mb-3 text-end" v-if="inschrijving.aanwezig">
          <VueSparkles>
            <span class="badge bg-success text-bg-success fs-3"><i class="fas fa-check"></i> Ingeschreven</span>
          </VueSparkles>
        </div>
      </div>
      
      <div>
        <table class="table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead class="table-dark">
            <tr>
              <th>Ingeschreven</th>
              <th>Naam</th>
              <th>Geslacht</th>
              <th>Leeftijd</th>
              <th>Opmerking</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="kind in kinderen" v-bind:key="kind.ID">
              <td class="text-center" style="width: 120px;">
                <input type="checkbox" v-model="kind.aanwezig" />
              </td>
              <td>
                <input v-model="kind.naam" class="form-control" required/>
              </td>
              <td>
                <multiselect v-model="kind.geslacht" :options="['Jongen', 'Meisje']"
                  placeholder="Selecteer een" :allow-empty="false"
                  deselect-label=""></multiselect>
              </td>
              <td style="width: 120px;">
                <input v-model.number="kind.leeftijd" class="form-control" type="number" step="0.5" />
              </td>
              <td>
                <input v-model="kind.opmerking" class="form-control" placeholder="Opmerking" />
              </td>
              <td style="width: 50px">
                <button type="button" class="btn btn-outline-danger" v-on:click="removeKind(kind)">
                  <i class="fad fa-trash" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <h3 class="col-12">
          <span class="float-end">
            <button type="button" class="btn btn-success" v-on:click="addKind()">
              <i class="fas fa-plus-square" /> Kind Toevoegen
            </button>
          </span>
        </h3>
      </div>
      
    </div>
  </div>
</template>

<script>
import { evenementenService } from "../../_services/evenementen.service";
import { ledenService } from "../../_services/leden.service";
import { getStatus } from "../../_helpers/leden";

import Multiselect from "vue-multiselect";
import VueSparkles from  'vue-sparkles'; // what... this world needs more sparkles!

import { jsPDF } from "jspdf";
import * as JsBarcode from "jsbarcode";

import {
  Logo,
  PoppinsMedium,
  PoppinsRegluar,
} from "../../_helpers/pdf-resources";


export default {
  props: ["evenementid","id"],
  components: {
    Multiselect,
    VueSparkles,
  },
  data: function () {
    return {
      getStatus,
      saving: false,
      loading: true,
      inschrijving:{},
      kinderen: [],
      originalData: "",
      originalKinderenData: "",
      lid: {
        gezin: {leden: []},
      },
    };
  },
  methods: {
    print: async function () {
      // Default export is a4 paper, portrait, using millimeters for units
      let doc = new jsPDF();
      doc.addFileToVFS("Poppins-Medium.ttf", PoppinsMedium);
      doc.addFileToVFS("Poppins-Regular.ttf", PoppinsRegluar);
      doc.addFont("Poppins-Medium.ttf", "Poppins Medium", "normal");
      doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");

      doc.addImage(`data:image/png;base64,${Logo}`, "PNG", 0, 0, 65, 40);

      var canvas = document.createElement("canvas");
      JsBarcode(canvas, this.lid.mvmnummer, {format: "CODE128", displayValue: false, width: 40, height: 120});
      doc.addImage(canvas.toDataURL("image/png"), "PNG", 160, 20, 40, 15);

      doc.setFont("Poppins Medium");
      doc.setFontSize(20);

      doc.text(`Inschrijving ${this.inschrijving.evenement.naam}`, 10, 50);
      doc.setLineWidth(0.5);
      doc.setDrawColor("black");
      doc.setFillColor("white");
      doc.rect(10, 60, 190, 60, "FD");

      doc = await new Promise((resolve) => {
        doc.html(`
          <div style="font-family: 'arial'; font-size: 14px;">
          <p style="text-decoration: underline; font-size: 17px;">Sinterklaas komt op zaterdag 30 november naar Moeders voor Moeders</p>
          <p>U bent welkom met de kinderen vanaf 12 tot 16 uur. De sint zal toekomen om 13 uur.</p>
          <ul>
            <li>Er is een Sintenkermis met kraampjes, lekkere hapjes en een drankje, en animatie op straat. Gelieve uw kinderen warm te kleden en regenkleding te voorzien bij slecht weer.</li>
            <li>Als u zich inschrijft verwachten wij u met uw kinderen op die dag! Wij geven vooraf geen speelgoed mee voor Sinterklaas. Kan u toch niet aanwezig zijn om dringende redenen? Verwittig ons <u>zo spoedig mogelijk</u> en <u>ten laatste de dag zelf</u> (03/272 53 84 of mail naar info@moedersvoormoeders.be)</li>
          </ul>
          </div>
        `,{
          callback: (doc) => resolve(doc),
          x: 15,
          y: 65,
          width: 180,
          windowWidth: 720,
        })
      });


      const startGegevens = 130;
      doc.setFont("Poppins");
      doc.setFontSize(14);
      doc.text("Uw gegevens", 10, startGegevens);

      doc.setFont("helvetica");
      doc.setFontSize(10);
      doc.text("Naam:", 10, startGegevens+10);
      doc.text(`${this.lid.gezin.leden.find((l) => l.gezinsHoofd).voornaam} ${this.lid.gezin.leden.find((l) => l.gezinsHoofd).achternaam}`, 50, startGegevens+10);

      doc.text("MVM Nummer:", 10, startGegevens+15);
      doc.text(`MVM${this.lid.mvmnummer}`, 50, startGegevens+15);

      if (this.inschrijving.evenement.perKind) {
        const startKinderen = 155;

        doc.setFontSize(7);
        const createHeaders = (headers) => {
          var result = [];
          for (let key in headers) {
            result.push({
              id: key,
              name: key,
              prompt: key,
              width: headers[key],
              align: "left",
              cellStart: (e, doc) => doc.setFont("helvetica")
            });
          }
          return result;
        };

        const createData = (kinderen) =>  {
          var result = [];
          for (let kind of kinderen) {
            if (!kind.aanwezig) {
              continue;
            }
            result.push({
              Naam: kind.naam,
              Geslacht: kind.geslacht,
              Leeftijd: kind.leeftijd.toString(),
              Opmerking: kind.opmerking || " ",
            });
          }
          return result;
        }
        doc.setFont("Poppins");
        doc.setFontSize(14);
        doc.text("Ingeschreven kinderen", 10, startKinderen);
        doc.table(10, startKinderen+5, createData(this.kinderen), createHeaders({"Naam":90, "Geslacht":40, "Leeftijd":35, "Opmerking":90}) , { autoSize: false, headerBackgroundColor: "black", headerTextColor: "white", fontSize: 10, cellStart: (e, doc) => doc.setFont("helvetica")});
      }

      doc.setFont("helvetica");
      //doc.setFontSize(10);
      //doc.text("VZW Moeders voor Moeders - Tel 03/272 53 84", 10, 285);
      doc.setFontSize(7);
      doc.text(`Dit document werd automatisch aangemaakt op ${new Date().toLocaleString()}`, 120, 290);

      doc.autoPrint({ variant: "non-conform" });
      //doc.autoPrint({variant: 'javascript'});
      doc.save("autoprint.pdf");
    },
    validate: function () {
      for (let kind of this.kinderen) {
        if (!kind.naam) {
          throw new Error("Naam kind niet ingevuld!");
        }
        if (!kind.geslacht) {
          throw new Error("Geslacht kind niet ingevuld!");
        }
      }
    },
    addKind: function () {
      this.kinderen.push({
        voornaam: "",
        achternaam: "",
        leeftijd: 0,
        geslacht: "",
        aanwezig: true,
      });
    },
    removeKind: function (obj) {
      this.kinderen = this.kinderen.filter((aObj) => aObj != obj);
    },
    inschrijven: function() {
      try {
        this.validate();
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
        return;
      }
      this.inschrijving.aanwezig = true;
      this.save();
    },
    uitschrijven: function() {
      this.inschrijving.aanwezig = false;
      this.save();
    },
    save: async function () {
      try {
        this.validate();

        this.inschrijving.kinderen = this.kinderen;

        await evenementenService.updateInschrijving(this.evenementid, this.id, this.inschrijving);
        await this.loadData();

        this.$Simplert.open({
          title: "Opgeslagen!",
          message: "",
          type: "success",
          customCloseBtnText: "Sluiten",
        });

        return true;
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
        return false;
      }
    },
    hasChanges: function () {
      if (!this.originalData) {
        return false;
      }
      
      if (JSON.stringify(this.inschrijving) !== this.originalData) {
        return true;
      }
      
      if (this.inschrijving.aanwezig) {
        if (JSON.stringify(this.kinderen) !== this.originalKinderenData) {
          return true;
        }
      }

      return false;
    },
    goBack: function () {
      let vm = this;
      if (this.hasChanges()) {
        this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: function () {
            vm.save().then((ok) => {
              if (ok) {
                vm.$router.push({ name: "evenementen-search" });
              }
            });
          },
          onClose: function () {
            vm.$router.push({ name: "evenementen-search" });
          },
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Teruggaan zonder opslagen",
        });
      } else {
        vm.$router.push({ name: "evenementen-search" });
      }
    },
    loadData: async function () {
      this.lid = await ledenService.get(this.id);

      const data = await evenementenService.getInschrijving(
        this.evenementid,
        this.id,
      );

      this.originalData = JSON.stringify(data);
      this.originalKinderenData = JSON.stringify(data.kinderen)

      this.inschrijving = data;
      this.kinderen = data.kinderen;

      if (!data.aanwezig) {
        // first time let's mark all children as present
        this.kinderen.forEach((k) => {
          k.aanwezig = true;
        });
      }
    },
  },

  created: async function () {
    this.loading = true;
    await this.loadData();
    this.loading = false;
  },
};
</script>
