export const verlengen = (lidmaatschappen) => {
  let lastEind = null;
  for (let lms of lidmaatschappen) {
    if (lms.vroegtijdigBeindigd) {
      continue;
    }
    // if eind was in the past do not use it
    if (new Date(lms.eind) < new Date()) {
      continue;
    }

    if (new Date(lms.eind) > lastEind) {
      lastEind = new Date(lms.eind);
    }
  }
  if (lastEind === null) {
    lastEind = new Date();
  }

  let newEnd = new Date(
    lastEind.getFullYear() + 1,
    lastEind.getMonth(),
    getLastDayOfMonth(lastEind.getFullYear() + 1, lastEind.getMonth()),
    23,
    59,
    59,
    999
  );

  return [].concat(
    {
      start: lastEind,
      eind: newEnd,
    },
    ...lidmaatschappen
  );
};

function getLastDayOfMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}
