<template id="search">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="float-start my-3">
          <h1>Eenmalige Hulp</h1>
        </div>
        <div class="float-end my-3">
          <!--<button
            type="button"
            class="btn btn-primary btn-lg"
            v-on:click="readEID()"
          >
            <i class="fas fa-id-card" /> Lees eID
          </button>
          &nbsp;-->
          <button
            type="button"
            class="btn btn-success btn-lg"
            v-on:click="newPunctuele()"
          >
            <i class="far fa-plus" /> Nieuw
          </button>
        </div>
      </div>
    </div>
    <form m v-on:submit.prevent="search">
      <div class="row">
        <div class="col-8 big-search">
          <div class="input-group mb-1">
            <div class="input-group-prepend">
              <div class="input-group-text">{{ prefix || "&nbsp;" }}</div>
            </div>
            <input
              type="text"
              class="form-control"
              ref="search"
              v-model="searchQuery"
              :disabled="searching"
              v-focus
            />
          </div>
        </div>
        <div class="col-4">
          <button
            type="button"
            class="btn btn-lg btn-success"
            v-on:click="search"
            :disabled="searching"
          >
            <i class="fas fa-search"></i
            ><span class="d-none d-md-inline"> Zoeken</span>
          </button>
        </div>
      </div>
    </form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">E-Nummer</th>
          <th scope="col">Voornaam</th>
          <th scope="col">Naam</th>
          <th scope="col">Inschrijfdatum</th>
          <th scope="col">Land</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in results" v-bind:key="result.id">
          <td>E{{ result.enummer }}</td>
          <td>{{ result.gezin.leden.find((l) => l.gezinsHoofd).voornaam }}</td>
          <td>
            {{ result.gezin.leden.find((l) => l.gezinsHoofd).achternaam }}
          </td>

          <td>
            {{
              new Date(
                result.gezin.leden.find((l) => l.gezinsHoofd).inschrijfdatum
              ).toLocaleDateString("nl-BE")
            }}
          </td>
          <td>
            {{ result.gezin.leden.find((l) => l.gezinsHoofd).geboorteland }}
          </td>
          <td>
            <button class="btn btn-primary" v-on:click="foundCallback(result)">
              Open <i class="fas fa-external-link-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <paginate
      :page-count="pageCount"
      :page-range="3"
      :margin-pages="0"
      containerClass="pagination"
      page-class="page-item"
      page-link-class="page-link"
      prev-text="Vorige"
      prev-class="page-item"
      prev-link-class="page-link"
      next-text="Volgende"
      next-class="page-item"
      next-link-class="page-link"
      :click-handler="pageClickCallback"
    >
    </paginate>
  </div>
</template>

<script>
import { punctueleService } from "../../_services/punctuele.service";
import { eidService } from "../../_services/eid.service";
import { formatRRN } from "../../_helpers/rrn";

export default {
  template: "#punctuele-search",
  components: {},
  data: function () {
    return {
      loading: false,
      searchQuery: "",
      searching: false,
      pageCount: 0,
      results: [],
    };
  },
  created: function () {
    this.loadPage(1);
  },
  watch: {
    searchQuery: function () {
      if (this.searchQuery.length > 3 || this.searchQuery.length === 0) {
        this.loadPage(1);
      }
    },
  },

  methods: {
    pageClickCallback: function (page) {
      this.loadPage(page);
    },
    readEID: async function () {
      this.loading = true;
      try {
        const result = await eidService.read();
        if (!result.nationalNumber) {
          throw new Error("Geen geldig rijksregisternummer gevonden");
        }
        const { data } = await punctueleService.getAllForRRN(
          formatRRN(result.nationalNumber)
        );
        if (data.length === 0) {
          this.newPunctuele(true);
        } else {
          this.foundCallback(data[0]);
        }
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij lezen van eID!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }
      this.loading = false;
    },
    search: async function () {
      this.searching = true;
      try {
        await this.loadPage(1);
      } catch (e) {
        console.error(e);
      }
      this.searching = false;
    },

    foundCallback: function (result) {
      this.$router.push({
        name: "punctuele-details",
        params: { id: result.enummer },
      });
    },
    newPunctuele: function (readEID) {
      this.$router.push({
        name: "punctuele-details",
        params: { id: 0, readEID },
      });
    },
    loadPage: async function (num) {
      this.pageNumber = num;

      const { data, totalEntries } = await punctueleService.getAll(
        num,
        this.searchQuery
      );

      this.results = data;
      this.pageCount = Math.ceil(totalEntries / 10);
      if (this.pageCount < 1) {
        this.pageCount = 1;
      }
    },
  },
};
</script>

<style scoped>
.col-title {
  font-weight: bold;
  display: block;
}
</style>
