import config from "config";
import { authHeader } from "../_helpers/auth-header";
import { authService } from "../_services/auth.service";
import { getCircularReplacer } from "../_helpers/json";

const getAllEvenementen = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/evenementen/`,
    requestOptions
  ).then(handleResponse);
};

const createEvenement = (evenement) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(evenement),
  };

  return fetch(
    `${config.apiUrl}/v1/evenementen/`,
    requestOptions
  ).then(handleResponse);
}

const getInschrijving = (evenementID, mvmNummer) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/evenementen/${evenementID}/${mvmNummer}`,
    requestOptions
  ).then(handleResponse);
};

const updateInschrijving = (evenementID, mvmNummer, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(`${config.apiUrl}/v1/evenementen/${evenementID}/${mvmNummer}`, requestOptions).then(
    handleResponse
  );
};



const setAanwezig = (evenementID, mvmNummer) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      ...authHeader(),
    },
  };

  return fetch(`${config.apiUrl}/v1/evenementen/${evenementID}/${mvmNummer}/aanwezig`, requestOptions).then(
    handleResponse
  );
};

export const evenementenService = {
  getAllEvenementen,
  createEvenement,
  getInschrijving,
  updateInschrijving,
  setAanwezig,
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};