<template id="search">
  <div>
    <div v-if="showModal">
      <EvenementAdd :hideModal="hideModal" />
    </div>
    <div class="row text-end mt-2">
      <div class="col-3 offset-7">
        <select class="form-select" v-model="evenementID" @change="setEvenementID">
          <option value="0" disabled>Selecteer een evenement</option>
          <option v-for="evenement in allEvenementen" :key="evenement.ID" :value="evenement.ID">
            {{ evenement.naam }}
          </option>
        </select>
      </div>
      <div class="col-1">
        <button class="btn btn-success" @click="addEvenement">
          <i class="fas fa-plus"></i> Evenement
        </button>
      </div>
    </div>
    
    <Search v-if="evenementID != 0" :title="evenement.naam + ' inschrijvingen'" :foundCallback="foundCallback" />
  </div>
</template>

<script>
import Search from "../_components/Search.vue";
import { evenementenService } from "../../_services/evenementen.service";
import EvenementAdd from "./Add.vue";

export default {
  template: "#search",
  components: { Search, EvenementAdd },
  data: function () {
    return {
      evenementID: 0,
      evenement: {},
      allEvenementen: [],
      loading: false,
      searchQuery: "",
      prefix: "MVM",
      searching: false,
      results: [],
      showModal: false,
    };
  },

  created: function() {
    this.loadEvenementen();
  },

  methods: {
    foundCallback: function (result) {
      this.$router.push({
        name: "evenementen-details",
        params: { id: result.mvmnummer, evenementid: this.evenementID },
      });
    },
    loadEvenementen: async function() {
      this.loading = true;
      this.allEvenementen = await evenementenService.getAllEvenementen();

      // fetch evenementID from local storage 
      this.evenementID = parseInt(localStorage.getItem("evenementID"), 10) || 0;
      this.evenement = this.allEvenementen.find(e => e.ID == this.evenementID);
      this.loading = false;
    },
    setEvenementID: function(e) {
      const id = e.target.value;
      localStorage.setItem("evenementID", `${id}`);
      this.evenement = this.allEvenementen.find(e => e.id == id);
    },
    hideModal: function () {
      this.showModal = false;
      this.modalData = null;
      this.loadEvenementen();
    },
    addEvenement: function () {
      this.showModal = true;
    },
  },
};
</script>