<template id="nav">
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <a class="navbar-brand" href="#"><img src="/images/logo-white.png" alt="Moeders voor Moeders" height="50"
          class="d-inline-block align-text-top ms-5" />
      </a>
      <span v-if="apiURL == 'https://api.staging.mvm.digital'" class="badge bg-framboos fs-6"><i
          class="fas fa-flask"></i> Staging</span>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav me-auto">
          <li :class="$route.name == 'home' ? 'nav-item active' : 'nav-item'">
            <router-link class="nav-link p-3 p-lg-2" to="/home"><i class="far fa-home"></i> Home</router-link>
          </li>
          <li v-if="permissions.leden" :class="$route.name.indexOf('leden') == 0 ? 'nav-item active' : 'nav-item'
        ">
            <router-link class="nav-link p-3 p-lg-2" to="/leden/search"><i class="fas fa-user-check"></i> Leden
            </router-link>
          </li>
          <li v-if="permissions.punctuele" :class="$route.name.indexOf('punctuele') == 0
          ? 'nav-item active'
          : 'nav-item'
        ">
            <router-link class="nav-link p-3 p-lg-2" to="/punctuele/search"><i class="fas fa-life-ring"></i> Eenmalige
              Hulp</router-link>
          </li>
          <li v-if="permissions.materiaal" :class="$route.name.indexOf('materiaal') == 0
          ? 'nav-item active'
          : 'nav-item'
        ">
            <router-link class="nav-link p-3 p-lg-2" to="/materiaal/search"><i class="far fa-tshirt"></i> Materiaal</router-link>
          </li>
          <li v-if="permissions.voeding" :class="$route.name.indexOf('voeding') == 0
          ? 'nav-item active'
          : 'nav-item'
        ">
            <router-link class="nav-link p-3 p-lg-2" to="/voeding/search"><i class="far fa-utensils"></i> Voeding</router-link>
          </li>
          <li v-if="permissions.onthaal" :class="$route.name.indexOf('onthaal') == 0
          ? 'nav-item active'
          : 'nav-item'
        ">
            <router-link class="nav-link p-3 p-lg-2" to="/onthaal/search"><i class="fas fa-concierge-bell"></i>
              Onthaal</router-link>
          </li>
          <li v-if="permissions.zeephuisje" :class="$route.name.indexOf('zeephuisje') == 0
          ? 'nav-item active'
          : 'nav-item'
        ">
            <router-link class="nav-link p-3 p-lg-2" to="/zeephuisje/search">
              <i class="fas fa-pump-soap"></i> Zeephuisje
            </router-link>
          </li>
          <li v-if="permissions.evenementen" :class="$route.name.indexOf('evenementen') == 0 || $route.name.indexOf('sinterklaas') == 0
          ? 'nav-item dropdown active'
          : 'nav-item dropdown'
        ">
            <a class="nav-link p-3 p-lg-2 dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"><i
                class="fas fa-ticket-alt"></i> Evenementen
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item p-3 p-lg-2" to="/evenementen/search" @click="closeDropdown">
                  <i class="fas fa-pen-alt"></i> Inschrijvingen
                </router-link>
              </li>
              <li v-if="permissions.sinterklaas">
                <router-link class="dropdown-item p-3 p-lg-2" to="/sinterklaas/search" @click="closeDropdown">
                  <i class="far fa-staff"></i> Sinterklaas Onthaal
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="permissions.markten || permissions.marktenScan" :class="$route.name.indexOf('markten') == 0
          ? 'nav-item dropdown active'
          : 'nav-item dropdown'
        ">
            <a class="nav-link p-3 p-lg-2 dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"><i
                class="fas fa-shopping-basket"></i> Markten
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" @click="closeDropdown">
              <li v-if="permissions.markten">
                <router-link class="dropdown-item p-3 p-lg-2" to="/markten/beheer">
                  <i class="fas fa-pen-alt"></i> Beheer
                </router-link>
              </li>
              <li v-if="permissions.marktenScan">
                <router-link class="dropdown-item p-3 p-lg-2" to="/markten/scan" @click="closeDropdown">
                  <i class="far fa-scanner"></i> Scannen
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="permissions.beheer" :class="$route.name.indexOf('beheer') == 0
          ? 'nav-item dropdown active'
          : 'nav-item dropdown'
        ">
            <a class="nav-link p-3 p-lg-2 dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"><i
                class="fas fa-user-tie"></i> Beheer
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/beheer/gebruikers">
                  <i class="fas fa-users"></i> gebruikers
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown">
            <a class="nav-link p-3 p-lg-2 dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false"><i
                class="fas fa-user"></i> {{ name }}</a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" href="#" @click="logout">
                  <i class="fas fa-sign-out"></i> Afmelden
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <!-- <form class="form-inline my-2 my-lg-0">
          <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
        </form>-->
      </div>
    </nav>

    <!-- Nested route -->
    <router-view></router-view>
  </div>
</template>

<script>
import config from "config";
import { authService } from "../_services/auth.service";

export default {
  data: function () {
    return {
      apiURL: config.apiUrl,
      permissions: {
        materiaal: false,
        voeding: false,
        sinterklaas: false,
        onthaal: false,
        zeephuisje: false,
        punctuele: false,
        beheer: false,
        leden: false,
        evenementen: false,
        markten: false,
        marktenScan: false,
      },
      name: "",
    };
  },
  methods: {
    canIDo: async function (verb, path) {
      return await authService.canIDo(verb, path);
    },
    logout: function () {
      authService.logout();
      this.$router.push("/login");
    },
    closeDropdown() {
      const openDropdown = document.querySelector('.dropdown-menu.show');
      if (openDropdown) {
        openDropdown.classList.remove('show');
        openDropdown.closest('.dropdown').querySelector('.dropdown-toggle').setAttribute('aria-expanded', 'false');
      }
    },
  },
  created() {
    authService.canIDo("GET", "/v1/materiaal/klant").then((r) => {
      this.permissions.materiaal = r;
    });
    authService.canIDo("GET", "/v1/voeding/klant").then((r) => {
      this.permissions.voeding = r;
    });
    authService.canIDo("GET", "/v1/sinterklaas/klant").then((r) => {
      this.permissions.sinterklaas = r;
    });
    authService.canIDo("GET", "/v1/onthaal/klant").then((r) => {
      this.permissions.onthaal = r;
    });
    authService.canIDo("GET", "/v1/zeephuisje/klant").then((r) => {
      this.permissions.zeephuisje = r;
    });
    authService.canIDo("GET", "/v1/beheer/user").then((r) => {
      this.permissions.beheer = r;
    });
    authService.canIDo("GET", "/v1/evenementen/something-to-make-the-regex-work").then((r) => {
      this.permissions.evenementen = r;
    });
    authService
      .canIDo("GET", "/v1/punctuele/something-to-make-the-regex-work")
      .then((r) => {
        this.permissions.punctuele = r;
      });
    authService
      .canIDo("GET", "/v1/leden/something-to-make-the-regex-work")
      .then((r) => {
        this.permissions.leden = r;
      });
    authService
      .canIDo("GET", "/v1/markten/something-to-make-the-regex-work")
      .then((r) => {
        this.permissions.markten = r;
      });
    authService
      .canIDo("GET", "/v1/markten/scan/something-to-make-the-regex-work")
      .then((r) => {
        this.permissions.marktenScan = r;
      });
    this.name = authService.getName();
  },
};
</script>
