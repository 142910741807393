export const Postcodes = [
  { postCode: "0612", gemeente: "Sinterklaas" },
  { postCode: "1000", gemeente: "Brussel" },
  {
    postCode: "1005",
    gemeente: "Verenigde Vergadering van de Gemeenschappelijke ",
  },
  { postCode: "1006", gemeente: "Raad van de Vlaamse Gemeenschapscommissie" },
  {
    postCode: "1007",
    gemeente: "Assemblée de la Commission Communautaire Française",
  },
  { postCode: "1008", gemeente: "Kamer van Volksvertegenwoordigers" },
  { postCode: "1009", gemeente: "Belgische Senaat" },
  { postCode: "1011", gemeente: "Vlaams Parlement" },
  { postCode: "1012", gemeente: "Parlement de la communauté française" },
  { postCode: "1020", gemeente: "Laken" },
  { postCode: "1030", gemeente: "Schaarbeek" },
  { postCode: "1031", gemeente: "Christelijke Sociale Organisaties" },
  { postCode: "1033", gemeente: "RTL-TVI" },
  {
    postCode: "1035",
    gemeente: "Ministerie van het Brussels Hoofdstedelijk Gewest",
  },
  { postCode: "1040", gemeente: "Etterbeek" },
  { postCode: "1041", gemeente: "International press center" },
  { postCode: "1043", gemeente: "VRT" },
  { postCode: "1044", gemeente: "RTBF" },
  { postCode: "1046", gemeente: "European External Action Service" },
  { postCode: "1047", gemeente: "Europees Parlement" },
  { postCode: "1048", gemeente: "Europese unie - Raad" },
  { postCode: "1049", gemeente: "Europese unie - Commissie" },
  { postCode: "1050", gemeente: "Elsene" },
  { postCode: "1060", gemeente: "Sint-Gillis" },
  { postCode: "1070", gemeente: "Anderlecht" },
  { postCode: "1080", gemeente: "Sint-Jans-Molenbeek" },
  { postCode: "1081", gemeente: "Koekelberg" },
  { postCode: "1082", gemeente: "Sint-Agatha-Berchem" },
  { postCode: "1083", gemeente: "Ganshoren" },
  { postCode: "1090", gemeente: "Jette" },
  { postCode: "1099", gemeente: "Brussel X" },
  { postCode: "1100", gemeente: "Postcheque" },
  { postCode: "1101", gemeente: "Scanning" },
  { postCode: "1105", gemeente: "ACTISOC" },
  { postCode: "1110", gemeente: "NATO" },
  { postCode: "1120", gemeente: "Neder-Over-Heembeek" },
  { postCode: "1130", gemeente: "Haren" },
  { postCode: "1140", gemeente: "Evere" },
  { postCode: "1150", gemeente: "Sint-Pieters-Woluwe" },
  { postCode: "1160", gemeente: "Oudergem" },
  { postCode: "1170", gemeente: "Watermaal-Bosvoorde" },
  { postCode: "1180", gemeente: "Ukkel" },
  { postCode: "1190", gemeente: "Vorst" },
  { postCode: "1200", gemeente: "Sint-Lambrechts-Woluwe" },
  { postCode: "1210", gemeente: "Sint-Joost-ten-Node" },
  { postCode: "1212", gemeente: "FOD Mobiliteit" },
  { postCode: "1300", gemeente: "Limal" },
  { postCode: "1300", gemeente: "Wavre" },
  { postCode: "1301", gemeente: "Bierges" },
  { postCode: "1310", gemeente: "La Hulpe" },
  { postCode: "1315", gemeente: "Glimes" },
  { postCode: "1315", gemeente: "Incourt" },
  { postCode: "1315", gemeente: "Opprebais" },
  { postCode: "1315", gemeente: "Piétrebais" },
  { postCode: "1315", gemeente: "Roux-Miroir" },
  { postCode: "1320", gemeente: "Beauvechain" },
  { postCode: "1320", gemeente: "Hamme-Mille" },
  { postCode: "1320", gemeente: "l'Écluse" },
  { postCode: "1320", gemeente: "Nodebais" },
  { postCode: "1320", gemeente: "Tourinnes-la-Grosse" },
  { postCode: "1325", gemeente: "Bonlez" },
  { postCode: "1325", gemeente: "Chaumont-Gistoux" },
  { postCode: "1325", gemeente: "Corroy-le-Grand" },
  { postCode: "1325", gemeente: "Dion-Valmont" },
  { postCode: "1325", gemeente: "Longueville" },
  { postCode: "1330", gemeente: "Rixensart" },
  { postCode: "1331", gemeente: "Rosières" },
  { postCode: "1332", gemeente: "Genval" },
  { postCode: "1340", gemeente: "Ottignies" },
  { postCode: "1341", gemeente: "Céroux-Mousty" },
  { postCode: "1342", gemeente: "Limelette" },
  { postCode: "1348", gemeente: "Louvain-la-Neuve" },
  { postCode: "1350", gemeente: "Enines" },
  { postCode: "1350", gemeente: "Folx-les-Caves" },
  { postCode: "1350", gemeente: "Jandrain-Jandrenouille" },
  { postCode: "1350", gemeente: "Jauche" },
  { postCode: "1350", gemeente: "Marilles" },
  { postCode: "1350", gemeente: "Noduwez" },
  { postCode: "1350", gemeente: "Orp-le-Grand" },
  { postCode: "1357", gemeente: "Linsmeau" },
  { postCode: "1357", gemeente: "Neerheylissem" },
  { postCode: "1357", gemeente: "Opheylissem" },
  { postCode: "1360", gemeente: "Malèves-Sainte-Marie-Wastinnes" },
  { postCode: "1360", gemeente: "Orbais" },
  { postCode: "1360", gemeente: "Perwez" },
  { postCode: "1360", gemeente: "Thorembais-les-Béguines" },
  { postCode: "1360", gemeente: "Thorembais-Saint-Trond" },
  { postCode: "1367", gemeente: "Autre-Eglise" },
  { postCode: "1367", gemeente: "Bomal" },
  { postCode: "1367", gemeente: "Geest-Gérompont-Petit-Rosière" },
  { postCode: "1367", gemeente: "Gérompont" },
  { postCode: "1367", gemeente: "Grand-Rosière-Hottomont" },
  { postCode: "1367", gemeente: "Huppaye" },
  { postCode: "1367", gemeente: "Mont-Saint-André" },
  { postCode: "1367", gemeente: "Ramillies" },
  { postCode: "1370", gemeente: "Dongelberg" },
  { postCode: "1370", gemeente: "Jauchelette" },
  { postCode: "1370", gemeente: "Jodoigne" },
  { postCode: "1370", gemeente: "Jodoigne-Souveraine" },
  { postCode: "1370", gemeente: "Lathuy" },
  { postCode: "1370", gemeente: "Mélin" },
  { postCode: "1370", gemeente: "Piétrain" },
  { postCode: "1370", gemeente: "Saint-Jean-Geest" },
  { postCode: "1370", gemeente: "Saint-Remy-Geest" },
  { postCode: "1370", gemeente: "Zétrud-Lumay" },
  { postCode: "1380", gemeente: "Couture-Saint-Germain" },
  { postCode: "1380", gemeente: "Lasne-Chapelle-Saint-Lambert" },
  { postCode: "1380", gemeente: "Maransart" },
  { postCode: "1380", gemeente: "Ohain" },
  { postCode: "1380", gemeente: "Plancenoit" },
  { postCode: "1390", gemeente: "Archennes" },
  { postCode: "1390", gemeente: "Biez" },
  { postCode: "1390", gemeente: "Bossut-Gottechain" },
  { postCode: "1390", gemeente: "Grez-Doiceau" },
  { postCode: "1390", gemeente: "Nethen" },
  { postCode: "1400", gemeente: "Monstreux" },
  { postCode: "1400", gemeente: "Nivelles" },
  { postCode: "1401", gemeente: "Baulers" },
  { postCode: "1402", gemeente: "Thines" },
  { postCode: "1404", gemeente: "Bornival" },
  { postCode: "1410", gemeente: "Waterloo" },
  { postCode: "1420", gemeente: "Braine-l'Alleud" },
  { postCode: "1421", gemeente: "Ophain-Bois-Seigneur-Isaac" },
  { postCode: "1428", gemeente: "Lillois-Witterzée" },
  { postCode: "1430", gemeente: "Bierghes" },
  { postCode: "1430", gemeente: "Quenast" },
  { postCode: "1430", gemeente: "Rebecq-Rognon" },
  { postCode: "1435", gemeente: "Corbais" },
  { postCode: "1435", gemeente: "Hévillers" },
  { postCode: "1435", gemeente: "Mont-Saint-Guibert" },
  { postCode: "1440", gemeente: "Braine-le-Château" },
  { postCode: "1440", gemeente: "Wauthier-Braine" },
  { postCode: "1450", gemeente: "Chastre-Villeroux-Blanmont" },
  { postCode: "1450", gemeente: "Cortil-Noirmont" },
  { postCode: "1450", gemeente: "Gentinnes" },
  { postCode: "1450", gemeente: "Saint-Géry" },
  { postCode: "1457", gemeente: "Nil-Saint-Vincent-Saint-Martin" },
  { postCode: "1457", gemeente: "Tourinnes-Saint-Lambert" },
  { postCode: "1457", gemeente: "Walhain-Saint-Paul" },
  { postCode: "1460", gemeente: "Ittre" },
  { postCode: "1460", gemeente: "Virginal-Samme" },
  { postCode: "1461", gemeente: "Haut-Ittre" },
  { postCode: "1470", gemeente: "Baisy-Thy" },
  { postCode: "1470", gemeente: "Bousval" },
  { postCode: "1470", gemeente: "Genappe" },
  { postCode: "1471", gemeente: "Loupoigne" },
  { postCode: "1472", gemeente: "Vieux-Genappe" },
  { postCode: "1473", gemeente: "Glabais" },
  { postCode: "1474", gemeente: "Ways" },
  { postCode: "1476", gemeente: "Houtain-le-Val" },
  { postCode: "1480", gemeente: "Clabecq" },
  { postCode: "1480", gemeente: "Oisquercq" },
  { postCode: "1480", gemeente: "Saintes" },
  { postCode: "1480", gemeente: "Tubize" },
  { postCode: "1490", gemeente: "Court-Saint-Etienne" },
  { postCode: "1495", gemeente: "Marbais" },
  { postCode: "1495", gemeente: "Mellery" },
  { postCode: "1495", gemeente: "Sart-Dames-Avelines" },
  { postCode: "1495", gemeente: "Tilly" },
  { postCode: "1495", gemeente: "Villers-la-Ville" },
  { postCode: "1500", gemeente: "Halle" },
  { postCode: "1501", gemeente: "Buizingen" },
  { postCode: "1502", gemeente: "Lembeek" },
  { postCode: "1540", gemeente: "Herfelingen" },
  { postCode: "1540", gemeente: "Herne" },
  { postCode: "1541", gemeente: "Sint-Pieters-Kapelle" },
  { postCode: "1547", gemeente: "Bever" },
  { postCode: "1560", gemeente: "Hoeilaart" },
  { postCode: "1570", gemeente: "Galmaarden" },
  { postCode: "1570", gemeente: "Tollembeek" },
  { postCode: "1570", gemeente: "Vollezele" },
  { postCode: "1600", gemeente: "Oudenaken" },
  { postCode: "1600", gemeente: "Sint-Laureins-Berchem" },
  { postCode: "1600", gemeente: "Sint-Pieters-Leeuw" },
  { postCode: "1601", gemeente: "Ruisbroek" },
  { postCode: "1602", gemeente: "Vlezenbeek" },
  { postCode: "1620", gemeente: "Drogenbos" },
  { postCode: "1630", gemeente: "Linkebeek" },
  { postCode: "1640", gemeente: "Sint-Genesius-Rode" },
  { postCode: "1650", gemeente: "Beersel" },
  { postCode: "1651", gemeente: "Lot" },
  { postCode: "1652", gemeente: "Alsemberg" },
  { postCode: "1653", gemeente: "Dworp" },
  { postCode: "1654", gemeente: "Huizingen" },
  { postCode: "1670", gemeente: "Bogaarden" },
  { postCode: "1670", gemeente: "Heikruis" },
  { postCode: "1670", gemeente: "Pepingen" },
  { postCode: "1671", gemeente: "Elingen" },
  { postCode: "1673", gemeente: "Beert" },
  { postCode: "1674", gemeente: "Bellingen" },
  { postCode: "1700", gemeente: "Dilbeek" },
  { postCode: "1700", gemeente: "Sint-Martens-Bodegem" },
  { postCode: "1700", gemeente: "Sint-Ulriks-Kapelle" },
  { postCode: "1701", gemeente: "Itterbeek" },
  { postCode: "1702", gemeente: "Groot-Bijgaarden" },
  { postCode: "1703", gemeente: "Schepdaal" },
  { postCode: "1730", gemeente: "Asse" },
  { postCode: "1730", gemeente: "Bekkerzeel" },
  { postCode: "1730", gemeente: "Kobbegem" },
  { postCode: "1730", gemeente: "Mollem" },
  { postCode: "1731", gemeente: "Relegem" },
  { postCode: "1731", gemeente: "Zellik" },
  { postCode: "1733", gemeente: "HighCo DATA" },
  { postCode: "1740", gemeente: "Ternat" },
  { postCode: "1741", gemeente: "Wambeek" },
  { postCode: "1742", gemeente: "Sint-Katherina-Lombeek" },
  { postCode: "1745", gemeente: "Mazenzele" },
  { postCode: "1745", gemeente: "Opwijk" },
  { postCode: "1750", gemeente: "Gaasbeek" },
  { postCode: "1750", gemeente: "Sint-Kwintens-Lennik" },
  { postCode: "1750", gemeente: "Sint-Martens-Lennik" },
  { postCode: "1755", gemeente: "Gooik" },
  { postCode: "1755", gemeente: "Kester" },
  { postCode: "1755", gemeente: "Leerbeek" },
  { postCode: "1755", gemeente: "Oetingen" },
  { postCode: "1760", gemeente: "Onze-Lieve-Vrouw-Lombeek" },
  { postCode: "1760", gemeente: "Pamel" },
  { postCode: "1760", gemeente: "Roosdaal" },
  { postCode: "1760", gemeente: "Strijtem" },
  { postCode: "1761", gemeente: "Borchtlombeek" },
  { postCode: "1770", gemeente: "Liedekerke" },
  { postCode: "1780", gemeente: "Wemmel" },
  { postCode: "1785", gemeente: "Brussegem" },
  { postCode: "1785", gemeente: "Hamme" },
  { postCode: "1785", gemeente: "Merchtem" },
  { postCode: "1790", gemeente: "Affligem" },
  { postCode: "1790", gemeente: "Essene" },
  { postCode: "1790", gemeente: "Hekelgem" },
  { postCode: "1790", gemeente: "Teralfene" },
  { postCode: "1800", gemeente: "Peutie" },
  { postCode: "1800", gemeente: "Vilvoorde" },
  { postCode: "1804", gemeente: "Cargovil" },
  { postCode: "1818", gemeente: "VTM" },
  { postCode: "1820", gemeente: "Melsbroek" },
  { postCode: "1820", gemeente: "Perk" },
  { postCode: "1820", gemeente: "Steenokkerzeel" },
  { postCode: "1830", gemeente: "Machelen" },
  { postCode: "1831", gemeente: "Diegem" },
  { postCode: "1840", gemeente: "Londerzeel" },
  { postCode: "1840", gemeente: "Malderen" },
  { postCode: "1840", gemeente: "Steenhuffel" },
  { postCode: "1850", gemeente: "Grimbergen" },
  { postCode: "1851", gemeente: "Humbeek" },
  { postCode: "1852", gemeente: "Beigem" },
  { postCode: "1853", gemeente: "Strombeek-Bever" },
  { postCode: "1860", gemeente: "Meise" },
  { postCode: "1861", gemeente: "Wolvertem" },
  { postCode: "1880", gemeente: "Kapelle-op-den-Bos" },
  { postCode: "1880", gemeente: "Nieuwenrode" },
  { postCode: "1880", gemeente: "Ramsdonk" },
  { postCode: "1910", gemeente: "Berg" },
  { postCode: "1910", gemeente: "Buken" },
  { postCode: "1910", gemeente: "Kampenhout" },
  { postCode: "1910", gemeente: "Nederokkerzeel" },
  { postCode: "1930", gemeente: "Nossegem" },
  { postCode: "1930", gemeente: "Zaventem" },
  { postCode: "1931", gemeente: "Brucargo" },
  { postCode: "1932", gemeente: "Sint-Stevens-Woluwe" },
  { postCode: "1933", gemeente: "Sterrebeek" },
  { postCode: "1934", gemeente: "Office Exchange Brussels Airport Remailing" },
  { postCode: "1935", gemeente: "Corporate Village" },
  { postCode: "1950", gemeente: "Kraainem" },
  { postCode: "1970", gemeente: "Wezembeek-Oppem" },
  { postCode: "1980", gemeente: "Eppegem" },
  { postCode: "1980", gemeente: "Zemst" },
  { postCode: "1981", gemeente: "Hofstade" },
  { postCode: "1982", gemeente: "Elewijt" },
  { postCode: "1982", gemeente: "Weerde" },
  { postCode: "2000", gemeente: "Antwerpen" },
  { postCode: "2018", gemeente: "Antwerpen" },
  { postCode: "2020", gemeente: "Antwerpen" },
  { postCode: "2030", gemeente: "Antwerpen" },
  { postCode: "2040", gemeente: "Antwerpen" },
  { postCode: "2040", gemeente: "Berendrecht" },
  { postCode: "2040", gemeente: "Lillo" },
  { postCode: "2040", gemeente: "Zandvliet" },
  { postCode: "2050", gemeente: "Antwerpen" },
  { postCode: "2060", gemeente: "Antwerpen" },
  { postCode: "2070", gemeente: "Burcht" },
  { postCode: "2070", gemeente: "Zwijndrecht" },
  { postCode: "2099", gemeente: "Antwerpen X" },
  { postCode: "2100", gemeente: "Deurne" },
  { postCode: "2110", gemeente: "Wijnegem" },
  { postCode: "2140", gemeente: "Borgerhout" },
  { postCode: "2150", gemeente: "Borsbeek" },
  { postCode: "2160", gemeente: "Wommelgem" },
  { postCode: "2170", gemeente: "Merksem" },
  { postCode: "2180", gemeente: "Ekeren" },
  { postCode: "2200", gemeente: "Herentals" },
  { postCode: "2200", gemeente: "Morkhoven" },
  { postCode: "2200", gemeente: "Noorderwijk" },
  { postCode: "2220", gemeente: "Hallaar" },
  { postCode: "2220", gemeente: "Heist-op-den-Berg" },
  { postCode: "2221", gemeente: "Booischot" },
  { postCode: "2222", gemeente: "Itegem" },
  { postCode: "2222", gemeente: "Wiekevorst" },
  { postCode: "2223", gemeente: "Schriek" },
  { postCode: "2230", gemeente: "Herselt" },
  { postCode: "2230", gemeente: "Ramsel" },
  { postCode: "2235", gemeente: "Houtvenne" },
  { postCode: "2235", gemeente: "Hulshout" },
  { postCode: "2235", gemeente: "Westmeerbeek" },
  { postCode: "2240", gemeente: "Massenhoven" },
  { postCode: "2240", gemeente: "Viersel" },
  { postCode: "2240", gemeente: "Zandhoven" },
  { postCode: "2242", gemeente: "Pulderbos" },
  { postCode: "2243", gemeente: "Pulle" },
  { postCode: "2250", gemeente: "Olen" },
  { postCode: "2260", gemeente: "Oevel" },
  { postCode: "2260", gemeente: "Tongerlo" },
  { postCode: "2260", gemeente: "Westerlo" },
  { postCode: "2260", gemeente: "Zoerle-Parwijs" },
  { postCode: "2270", gemeente: "Herenthout" },
  { postCode: "2275", gemeente: "Gierle" },
  { postCode: "2275", gemeente: "Lille" },
  { postCode: "2275", gemeente: "Poederlee" },
  { postCode: "2275", gemeente: "Wechelderzande" },
  { postCode: "2280", gemeente: "Grobbendonk" },
  { postCode: "2288", gemeente: "Bouwel" },
  { postCode: "2290", gemeente: "Vorselaar" },
  { postCode: "2300", gemeente: "Turnhout" },
  { postCode: "2310", gemeente: "Rijkevorsel" },
  { postCode: "2320", gemeente: "Hoogstraten" },
  { postCode: "2321", gemeente: "Meer" },
  { postCode: "2322", gemeente: "Minderhout" },
  { postCode: "2323", gemeente: "Wortel" },
  { postCode: "2328", gemeente: "Meerle" },
  { postCode: "2330", gemeente: "Merksplas" },
  { postCode: "2340", gemeente: "Beerse" },
  { postCode: "2340", gemeente: "Vlimmeren" },
  { postCode: "2350", gemeente: "Vosselaar" },
  { postCode: "2360", gemeente: "Oud-Turnhout" },
  { postCode: "2370", gemeente: "Arendonk" },
  { postCode: "2380", gemeente: "Ravels" },
  { postCode: "2381", gemeente: "Weelde" },
  { postCode: "2382", gemeente: "Poppel" },
  { postCode: "2387", gemeente: "Baarle-Hertog" },
  { postCode: "2390", gemeente: "Malle" },
  { postCode: "2390", gemeente: "Oostmalle" },
  { postCode: "2390", gemeente: "Westmalle" },
  { postCode: "2400", gemeente: "Mol" },
  { postCode: "2430", gemeente: "Eindhout" },
  { postCode: "2430", gemeente: "Vorst" },
  { postCode: "2431", gemeente: "Varendonk" },
  { postCode: "2431", gemeente: "Veerle" },
  { postCode: "2440", gemeente: "Geel" },
  { postCode: "2450", gemeente: "Meerhout" },
  { postCode: "2460", gemeente: "Kasterlee" },
  { postCode: "2460", gemeente: "Lichtaart" },
  { postCode: "2460", gemeente: "Tielen" },
  { postCode: "2470", gemeente: "Retie" },
  { postCode: "2480", gemeente: "Dessel" },
  { postCode: "2490", gemeente: "Balen" },
  { postCode: "2491", gemeente: "Olmen" },
  { postCode: "2500", gemeente: "Koningshooikt" },
  { postCode: "2500", gemeente: "Lier" },
  { postCode: "2520", gemeente: "Broechem" },
  { postCode: "2520", gemeente: "Emblem" },
  { postCode: "2520", gemeente: "Oelegem" },
  { postCode: "2520", gemeente: "Ranst" },
  { postCode: "2530", gemeente: "Boechout" },
  { postCode: "2531", gemeente: "Vremde" },
  { postCode: "2540", gemeente: "Hove" },
  { postCode: "2547", gemeente: "Lint" },
  { postCode: "2550", gemeente: "Kontich" },
  { postCode: "2550", gemeente: "Waarloos" },
  { postCode: "2560", gemeente: "Bevel" },
  { postCode: "2560", gemeente: "Kessel" },
  { postCode: "2560", gemeente: "Nijlen" },
  { postCode: "2570", gemeente: "Duffel" },
  { postCode: "2580", gemeente: "Beerzel" },
  { postCode: "2580", gemeente: "Putte" },
  { postCode: "2590", gemeente: "Berlaar" },
  { postCode: "2590", gemeente: "Gestel" },
  { postCode: "2600", gemeente: "Berchem" },
  { postCode: "2610", gemeente: "Wilrijk" },
  { postCode: "2620", gemeente: "Hemiksem" },
  { postCode: "2627", gemeente: "Schelle" },
  { postCode: "2630", gemeente: "Aartselaar" },
  { postCode: "2640", gemeente: "Mortsel" },
  { postCode: "2650", gemeente: "Edegem" },
  { postCode: "2660", gemeente: "Hoboken" },
  { postCode: "2800", gemeente: "Mechelen" },
  { postCode: "2800", gemeente: "Walem" },
  { postCode: "2801", gemeente: "Heffen" },
  { postCode: "2811", gemeente: "Hombeek" },
  { postCode: "2811", gemeente: "Leest" },
  { postCode: "2812", gemeente: "Muizen" },
  { postCode: "2820", gemeente: "Bonheiden" },
  { postCode: "2820", gemeente: "Rijmenam" },
  { postCode: "2830", gemeente: "Blaasveld" },
  { postCode: "2830", gemeente: "Heindonk" },
  { postCode: "2830", gemeente: "Tisselt" },
  { postCode: "2830", gemeente: "Willebroek" },
  { postCode: "2840", gemeente: "Reet" },
  { postCode: "2840", gemeente: "Rumst" },
  { postCode: "2840", gemeente: "Terhagen" },
  { postCode: "2845", gemeente: "Niel" },
  { postCode: "2850", gemeente: "Boom" },
  { postCode: "2860", gemeente: "Sint-Katelijne-Waver" },
  { postCode: "2861", gemeente: "Onze-Lieve-Vrouw-Waver" },
  { postCode: "2870", gemeente: "Breendonk" },
  { postCode: "2870", gemeente: "Liezele" },
  { postCode: "2870", gemeente: "Puurs" },
  { postCode: "2870", gemeente: "Ruisbroek" },
  { postCode: "2880", gemeente: "Bornem" },
  { postCode: "2880", gemeente: "Hingene" },
  { postCode: "2880", gemeente: "Mariekerke" },
  { postCode: "2880", gemeente: "Weert" },
  { postCode: "2890", gemeente: "Lippelo" },
  { postCode: "2890", gemeente: "Oppuurs" },
  { postCode: "2890", gemeente: "Sint-Amands" },
  { postCode: "2900", gemeente: "Schoten" },
  { postCode: "2910", gemeente: "Essen" },
  { postCode: "2920", gemeente: "Kalmthout" },
  { postCode: "2930", gemeente: "Brasschaat" },
  { postCode: "2940", gemeente: "Hoevenen" },
  { postCode: "2940", gemeente: "Stabroek" },
  { postCode: "2950", gemeente: "Kapellen" },
  { postCode: "2960", gemeente: "Brecht" },
  { postCode: "2960", gemeente: "Sint-Job-in-'t-Goor" },
  { postCode: "2960", gemeente: "Sint-Lenaarts" },
  { postCode: "2970", gemeente: "'s Gravenwezel" },
  { postCode: "2970", gemeente: "Schilde" },
  { postCode: "2980", gemeente: "Halle" },
  { postCode: "2980", gemeente: "Zoersel" },
  { postCode: "2990", gemeente: "Loenhout" },
  { postCode: "2990", gemeente: "Wuustwezel" },
  { postCode: "3000", gemeente: "Leuven" },
  { postCode: "3001", gemeente: "Heverlee" },
  { postCode: "3010", gemeente: "Kessel Lo" },
  { postCode: "3012", gemeente: "Wilsele" },
  { postCode: "3018", gemeente: "Wijgmaal" },
  { postCode: "3020", gemeente: "Herent" },
  { postCode: "3020", gemeente: "Veltem-Beisem" },
  { postCode: "3020", gemeente: "Winksele" },
  { postCode: "3040", gemeente: "Huldenberg" },
  { postCode: "3040", gemeente: "Loonbeek" },
  { postCode: "3040", gemeente: "Neerijse" },
  { postCode: "3040", gemeente: "Ottenburg" },
  { postCode: "3040", gemeente: "Sint-Agatha-Rode" },
  { postCode: "3050", gemeente: "Oud-Heverlee" },
  { postCode: "3051", gemeente: "Sint-Joris-Weert" },
  { postCode: "3052", gemeente: "Blanden" },
  { postCode: "3053", gemeente: "Haasrode" },
  { postCode: "3054", gemeente: "Vaalbeek" },
  { postCode: "3060", gemeente: "Bertem" },
  { postCode: "3060", gemeente: "Korbeek-Dijle" },
  { postCode: "3061", gemeente: "Leefdaal" },
  { postCode: "3070", gemeente: "Kortenberg" },
  { postCode: "3071", gemeente: "Erps-Kwerps" },
  { postCode: "3078", gemeente: "Everberg" },
  { postCode: "3078", gemeente: "Meerbeek" },
  { postCode: "3080", gemeente: "Duisburg" },
  { postCode: "3080", gemeente: "Tervuren" },
  { postCode: "3080", gemeente: "Vossem" },
  { postCode: "3090", gemeente: "Overijse" },
  { postCode: "3110", gemeente: "Rotselaar" },
  { postCode: "3111", gemeente: "Wezemaal" },
  { postCode: "3118", gemeente: "Werchter" },
  { postCode: "3120", gemeente: "Tremelo" },
  { postCode: "3128", gemeente: "Baal" },
  { postCode: "3130", gemeente: "Begijnendijk" },
  { postCode: "3130", gemeente: "Betekom" },
  { postCode: "3140", gemeente: "Keerbergen" },
  { postCode: "3150", gemeente: "Haacht" },
  { postCode: "3150", gemeente: "Tildonk" },
  { postCode: "3150", gemeente: "Wespelaar" },
  { postCode: "3190", gemeente: "Boortmeerbeek" },
  { postCode: "3191", gemeente: "Hever" },
  { postCode: "3200", gemeente: "Aarschot" },
  { postCode: "3200", gemeente: "Gelrode" },
  { postCode: "3201", gemeente: "Langdorp" },
  { postCode: "3202", gemeente: "Rillaar" },
  { postCode: "3210", gemeente: "Linden" },
  { postCode: "3210", gemeente: "Lubbeek" },
  { postCode: "3211", gemeente: "Binkom" },
  { postCode: "3212", gemeente: "Pellenberg" },
  { postCode: "3220", gemeente: "Holsbeek" },
  { postCode: "3220", gemeente: "Kortrijk-Dutsel" },
  { postCode: "3220", gemeente: "Sint-Pieters-Rode" },
  { postCode: "3221", gemeente: "Nieuwrode" },
  { postCode: "3270", gemeente: "Scherpenheuvel" },
  { postCode: "3271", gemeente: "Averbode" },
  { postCode: "3271", gemeente: "Zichem" },
  { postCode: "3272", gemeente: "Messelbroek" },
  { postCode: "3272", gemeente: "Testelt" },
  { postCode: "3290", gemeente: "Deurne" },
  { postCode: "3290", gemeente: "Diest" },
  { postCode: "3290", gemeente: "Schaffen" },
  { postCode: "3290", gemeente: "Webbekom" },
  { postCode: "3293", gemeente: "Kaggevinne" },
  { postCode: "3294", gemeente: "Molenstede" },
  { postCode: "3300", gemeente: "Bost" },
  { postCode: "3300", gemeente: "Goetsenhoven" },
  { postCode: "3300", gemeente: "Hakendover" },
  { postCode: "3300", gemeente: "Kumtich" },
  { postCode: "3300", gemeente: "Oorbeek" },
  { postCode: "3300", gemeente: "Oplinter" },
  { postCode: "3300", gemeente: "Sint-Margriete-Houtem" },
  { postCode: "3300", gemeente: "Tienen" },
  { postCode: "3300", gemeente: "Vissenaken" },
  { postCode: "3320", gemeente: "Hoegaarden" },
  { postCode: "3320", gemeente: "Meldert" },
  { postCode: "3321", gemeente: "Outgaarden" },
  { postCode: "3350", gemeente: "Drieslinter" },
  { postCode: "3350", gemeente: "Linter" },
  { postCode: "3350", gemeente: "Melkwezer" },
  { postCode: "3350", gemeente: "Neerhespen" },
  { postCode: "3350", gemeente: "Neerlinter" },
  { postCode: "3350", gemeente: "Orsmaal-Gussenhoven" },
  { postCode: "3350", gemeente: "Overhespen" },
  { postCode: "3350", gemeente: "Wommersom" },
  { postCode: "3360", gemeente: "Bierbeek" },
  { postCode: "3360", gemeente: "Korbeek-Lo" },
  { postCode: "3360", gemeente: "Lovenjoel" },
  { postCode: "3360", gemeente: "Opvelp" },
  { postCode: "3370", gemeente: "Boutersem" },
  { postCode: "3370", gemeente: "Kerkom" },
  { postCode: "3370", gemeente: "Neervelp" },
  { postCode: "3370", gemeente: "Roosbeek" },
  { postCode: "3370", gemeente: "Vertrijk" },
  { postCode: "3370", gemeente: "Willebringen" },
  { postCode: "3380", gemeente: "Bunsbeek" },
  { postCode: "3380", gemeente: "Glabbeek" },
  { postCode: "3381", gemeente: "Kapellen" },
  { postCode: "3384", gemeente: "Attenrode" },
  { postCode: "3390", gemeente: "Houwaart" },
  { postCode: "3390", gemeente: "Sint-Joris-Winge" },
  { postCode: "3390", gemeente: "Tielt" },
  { postCode: "3391", gemeente: "Meensel-Kiezegem" },
  { postCode: "3400", gemeente: "Eliksem" },
  { postCode: "3400", gemeente: "Ezemaal" },
  { postCode: "3400", gemeente: "Laar" },
  { postCode: "3400", gemeente: "Landen" },
  { postCode: "3400", gemeente: "Neerwinden" },
  { postCode: "3400", gemeente: "Overwinden" },
  { postCode: "3400", gemeente: "Rumsdorp" },
  { postCode: "3400", gemeente: "Wange" },
  { postCode: "3401", gemeente: "Waasmont" },
  { postCode: "3401", gemeente: "Walsbets" },
  { postCode: "3401", gemeente: "Walshoutem" },
  { postCode: "3401", gemeente: "Wezeren" },
  { postCode: "3404", gemeente: "Attenhoven" },
  { postCode: "3404", gemeente: "Neerlanden" },
  { postCode: "3440", gemeente: "Budingen" },
  { postCode: "3440", gemeente: "Dormaal" },
  { postCode: "3440", gemeente: "Halle-Booienhoven" },
  { postCode: "3440", gemeente: "Helen-Bos" },
  { postCode: "3440", gemeente: "Zoutleeuw" },
  { postCode: "3450", gemeente: "Geetbets" },
  { postCode: "3450", gemeente: "Grazen" },
  { postCode: "3454", gemeente: "Rummen" },
  { postCode: "3460", gemeente: "Assent" },
  { postCode: "3460", gemeente: "Bekkevoort" },
  { postCode: "3461", gemeente: "Molenbeek-Wersbeek" },
  { postCode: "3470", gemeente: "Kortenaken" },
  { postCode: "3470", gemeente: "Ransberg" },
  { postCode: "3470", gemeente: "Sint-Margriete-Houtem" },
  { postCode: "3471", gemeente: "Hoeleden" },
  { postCode: "3472", gemeente: "Kersbeek-Miskom" },
  { postCode: "3473", gemeente: "Waanrode" },
  { postCode: "3500", gemeente: "Hasselt" },
  { postCode: "3500", gemeente: "Sint-Lambrechts-Herk" },
  { postCode: "3501", gemeente: "Wimmertingen" },
  { postCode: "3510", gemeente: "Kermt" },
  { postCode: "3510", gemeente: "Spalbeek" },
  { postCode: "3511", gemeente: "Kuringen" },
  { postCode: "3511", gemeente: "Stokrooie" },
  { postCode: "3512", gemeente: "Stevoort" },
  { postCode: "3520", gemeente: "Zonhoven" },
  { postCode: "3530", gemeente: "Helchteren" },
  { postCode: "3530", gemeente: "Houthalen" },
  { postCode: "3540", gemeente: "Berbroek" },
  { postCode: "3540", gemeente: "Donk" },
  { postCode: "3540", gemeente: "Herk-de-Stad" },
  { postCode: "3540", gemeente: "Schulen" },
  { postCode: "3545", gemeente: "Halen" },
  { postCode: "3545", gemeente: "Loksbergen" },
  { postCode: "3545", gemeente: "Zelem" },
  { postCode: "3550", gemeente: "Heusden" },
  { postCode: "3550", gemeente: "Heusden-Zolder" },
  { postCode: "3550", gemeente: "Zolder" },
  { postCode: "3560", gemeente: "Linkhout" },
  { postCode: "3560", gemeente: "Lummen" },
  { postCode: "3560", gemeente: "Meldert" },
  { postCode: "3570", gemeente: "Alken" },
  { postCode: "3580", gemeente: "Beringen" },
  { postCode: "3581", gemeente: "Beverlo" },
  { postCode: "3582", gemeente: "Koersel" },
  { postCode: "3583", gemeente: "Paal" },
  { postCode: "3590", gemeente: "Diepenbeek" },
  { postCode: "3600", gemeente: "Genk" },
  { postCode: "3620", gemeente: "Gellik" },
  { postCode: "3620", gemeente: "Lanaken" },
  { postCode: "3620", gemeente: "Neerharen" },
  { postCode: "3620", gemeente: "Veldwezelt" },
  { postCode: "3621", gemeente: "Rekem" },
  { postCode: "3630", gemeente: "Eisden" },
  { postCode: "3630", gemeente: "Leut" },
  { postCode: "3630", gemeente: "Maasmechelen" },
  { postCode: "3630", gemeente: "Mechelen-aan-de-Maas" },
  { postCode: "3630", gemeente: "Meeswijk" },
  { postCode: "3630", gemeente: "Opgrimbie" },
  { postCode: "3630", gemeente: "Vucht" },
  { postCode: "3631", gemeente: "Boorsem" },
  { postCode: "3631", gemeente: "Uikhoven" },
  { postCode: "3640", gemeente: "Kessenich" },
  { postCode: "3640", gemeente: "Kinrooi" },
  { postCode: "3640", gemeente: "Molenbeersel" },
  { postCode: "3640", gemeente: "Ophoven" },
  { postCode: "3650", gemeente: "Dilsen" },
  { postCode: "3650", gemeente: "Dilsen-Stokkem" },
  { postCode: "3650", gemeente: "Elen" },
  { postCode: "3650", gemeente: "Lanklaar" },
  { postCode: "3650", gemeente: "Rotem" },
  { postCode: "3650", gemeente: "Stokkem" },
  { postCode: "3660", gemeente: "Opglabbeek" },
  { postCode: "3665", gemeente: "As" },
  { postCode: "3668", gemeente: "Niel-bij-As" },
  { postCode: "3670", gemeente: "Ellikom" },
  { postCode: "3670", gemeente: "Gruitrode" },
  { postCode: "3670", gemeente: "Meeuwen" },
  { postCode: "3670", gemeente: "Neerglabbeek" },
  { postCode: "3670", gemeente: "Wijshagen" },
  { postCode: "3680", gemeente: "Maaseik" },
  { postCode: "3680", gemeente: "Neeroeteren" },
  { postCode: "3680", gemeente: "Opoeteren" },
  { postCode: "3690", gemeente: "Zutendaal" },
  { postCode: "3700", gemeente: "'s Herenelderen" },
  { postCode: "3700", gemeente: "Berg" },
  { postCode: "3700", gemeente: "Diets-Heur" },
  { postCode: "3700", gemeente: "Haren" },
  { postCode: "3700", gemeente: "Henis" },
  { postCode: "3700", gemeente: "Kolmont" },
  { postCode: "3700", gemeente: "Koninksem" },
  { postCode: "3700", gemeente: "Lauw" },
  { postCode: "3700", gemeente: "Mal" },
  { postCode: "3700", gemeente: "Neerrepen" },
  { postCode: "3700", gemeente: "Nerem" },
  { postCode: "3700", gemeente: "Overrepen" },
  { postCode: "3700", gemeente: "Piringen" },
  { postCode: "3700", gemeente: "Riksingen" },
  { postCode: "3700", gemeente: "Rutten" },
  { postCode: "3700", gemeente: "Sluizen" },
  { postCode: "3700", gemeente: "Tongeren" },
  { postCode: "3700", gemeente: "Vreren" },
  { postCode: "3700", gemeente: "Widooie" },
  { postCode: "3717", gemeente: "Herstappe" },
  { postCode: "3720", gemeente: "Kortessem" },
  { postCode: "3721", gemeente: "Vliermaalroot" },
  { postCode: "3722", gemeente: "Wintershoven" },
  { postCode: "3723", gemeente: "Guigoven" },
  { postCode: "3724", gemeente: "Vliermaal" },
  { postCode: "3730", gemeente: "Hoeselt" },
  { postCode: "3730", gemeente: "Romershoven" },
  { postCode: "3730", gemeente: "Sint-Huibrechts-Hern" },
  { postCode: "3730", gemeente: "Werm" },
  { postCode: "3732", gemeente: "Schalkhoven" },
  { postCode: "3740", gemeente: "Beverst" },
  { postCode: "3740", gemeente: "Bilzen" },
  { postCode: "3740", gemeente: "Eigenbilzen" },
  { postCode: "3740", gemeente: "Grote-Spouwen" },
  { postCode: "3740", gemeente: "Hees" },
  { postCode: "3740", gemeente: "Kleine-Spouwen" },
  { postCode: "3740", gemeente: "Mopertingen" },
  { postCode: "3740", gemeente: "Munsterbilzen" },
  { postCode: "3740", gemeente: "Rijkhoven" },
  { postCode: "3740", gemeente: "Rosmeer" },
  { postCode: "3740", gemeente: "Waltwilder" },
  { postCode: "3742", gemeente: "Martenslinde" },
  { postCode: "3746", gemeente: "Hoelbeek" },
  { postCode: "3770", gemeente: "Genoelselderen" },
  { postCode: "3770", gemeente: "Herderen" },
  { postCode: "3770", gemeente: "Kanne" },
  { postCode: "3770", gemeente: "Membruggen" },
  { postCode: "3770", gemeente: "Millen" },
  { postCode: "3770", gemeente: "Riemst" },
  { postCode: "3770", gemeente: "Val-Meer" },
  { postCode: "3770", gemeente: "Vlijtingen" },
  { postCode: "3770", gemeente: "Vroenhoven" },
  { postCode: "3770", gemeente: "Zichen-Zussen-Bolder" },
  { postCode: "3790", gemeente: "Moelingen" },
  { postCode: "3790", gemeente: "Sint-Martens-Voeren" },
  { postCode: "3791", gemeente: "Remersdaal" },
  { postCode: "3792", gemeente: "Sint-Pieters-Voeren" },
  { postCode: "3793", gemeente: "Teuven" },
  { postCode: "3798", gemeente: "'s-Gravenvoeren" },
  { postCode: "3800", gemeente: "Aalst" },
  { postCode: "3800", gemeente: "Brustem" },
  { postCode: "3800", gemeente: "Engelmanshoven" },
  { postCode: "3800", gemeente: "Gelinden" },
  { postCode: "3800", gemeente: "Groot-Gelmen" },
  { postCode: "3800", gemeente: "Halmaal" },
  { postCode: "3800", gemeente: "Kerkom-bij-Sint-Truiden" },
  { postCode: "3800", gemeente: "Ordingen" },
  { postCode: "3800", gemeente: "Sint-Truiden" },
  { postCode: "3800", gemeente: "Zepperen" },
  { postCode: "3803", gemeente: "Duras" },
  { postCode: "3803", gemeente: "Gorsem" },
  { postCode: "3803", gemeente: "Runkelen" },
  { postCode: "3803", gemeente: "Wilderen" },
  { postCode: "3806", gemeente: "Velm" },
  { postCode: "3830", gemeente: "Berlingen" },
  { postCode: "3830", gemeente: "Wellen" },
  { postCode: "3831", gemeente: "Herten" },
  { postCode: "3832", gemeente: "Ulbeek" },
  { postCode: "3840", gemeente: "Bommershoven" },
  { postCode: "3840", gemeente: "Borgloon" },
  { postCode: "3840", gemeente: "Broekom" },
  { postCode: "3840", gemeente: "Gors-Opleeuw" },
  { postCode: "3840", gemeente: "Gotem" },
  { postCode: "3840", gemeente: "Groot-Loon" },
  { postCode: "3840", gemeente: "Haren" },
  { postCode: "3840", gemeente: "Hendrieken" },
  { postCode: "3840", gemeente: "Hoepertingen" },
  { postCode: "3840", gemeente: "Jesseren" },
  { postCode: "3840", gemeente: "Kerniel" },
  { postCode: "3840", gemeente: "Kolmont" },
  { postCode: "3840", gemeente: "Kuttekoven" },
  { postCode: "3840", gemeente: "Rijkel" },
  { postCode: "3840", gemeente: "Voort" },
  { postCode: "3850", gemeente: "Binderveld" },
  { postCode: "3850", gemeente: "Kozen" },
  { postCode: "3850", gemeente: "Nieuwerkerken" },
  { postCode: "3850", gemeente: "Wijer" },
  { postCode: "3870", gemeente: "Batsheers" },
  { postCode: "3870", gemeente: "Bovelingen" },
  { postCode: "3870", gemeente: "Gutshoven" },
  { postCode: "3870", gemeente: "Heers" },
  { postCode: "3870", gemeente: "Heks" },
  { postCode: "3870", gemeente: "Horpmaal" },
  { postCode: "3870", gemeente: "Klein-Gelmen" },
  { postCode: "3870", gemeente: "Mechelen-Bovelingen" },
  { postCode: "3870", gemeente: "Mettekoven" },
  { postCode: "3870", gemeente: "Opheers" },
  { postCode: "3870", gemeente: "Rukkelingen-Loon" },
  { postCode: "3870", gemeente: "Vechmaal" },
  { postCode: "3870", gemeente: "Veulen" },
  { postCode: "3890", gemeente: "Boekhout" },
  { postCode: "3890", gemeente: "Gingelom" },
  { postCode: "3890", gemeente: "Jeuk" },
  { postCode: "3890", gemeente: "Kortijs" },
  { postCode: "3890", gemeente: "Montenaken" },
  { postCode: "3890", gemeente: "Niel-bij-Sint-Truiden" },
  { postCode: "3890", gemeente: "Vorsen" },
  { postCode: "3891", gemeente: "Borlo" },
  { postCode: "3891", gemeente: "Buvingen" },
  { postCode: "3891", gemeente: "Mielen-boven-Aalst" },
  { postCode: "3891", gemeente: "Muizen" },
  { postCode: "3900", gemeente: "Overpelt" },
  { postCode: "3910", gemeente: "Neerpelt" },
  { postCode: "3910", gemeente: "Sint-Huibrechts-Lille" },
  { postCode: "3920", gemeente: "Lommel" },
  { postCode: "3930", gemeente: "Achel" },
  { postCode: "3930", gemeente: "Hamont" },
  { postCode: "3940", gemeente: "Hechtel" },
  { postCode: "3941", gemeente: "Eksel" },
  { postCode: "3945", gemeente: "Kwaadmechelen" },
  { postCode: "3945", gemeente: "Oostham" },
  { postCode: "3950", gemeente: "Bocholt" },
  { postCode: "3950", gemeente: "Kaulille" },
  { postCode: "3950", gemeente: "Reppel" },
  { postCode: "3960", gemeente: "Beek" },
  { postCode: "3960", gemeente: "Bree" },
  { postCode: "3960", gemeente: "Gerdingen" },
  { postCode: "3960", gemeente: "Opitter" },
  { postCode: "3960", gemeente: "Tongerlo" },
  { postCode: "3970", gemeente: "Leopoldsburg" },
  { postCode: "3971", gemeente: "Heppen" },
  { postCode: "3980", gemeente: "Tessenderlo" },
  { postCode: "3990", gemeente: "Grote-Brogel" },
  { postCode: "3990", gemeente: "Kleine-Brogel" },
  { postCode: "3990", gemeente: "Peer" },
  { postCode: "3990", gemeente: "Wijchmaal" },
  { postCode: "4000", gemeente: "Glain" },
  { postCode: "4000", gemeente: "Liège" },
  { postCode: "4000", gemeente: "Rocourt" },
  { postCode: "4020", gemeente: "Bressoux" },
  { postCode: "4020", gemeente: "Jupille-sur-Meuse" },
  { postCode: "4020", gemeente: "Liège" },
  { postCode: "4020", gemeente: "Wandre" },
  { postCode: "4030", gemeente: "Grivegnée" },
  { postCode: "4031", gemeente: "Angleur" },
  { postCode: "4032", gemeente: "Chênée" },
  { postCode: "4040", gemeente: "Herstal" },
  { postCode: "4041", gemeente: "Milmort" },
  { postCode: "4041", gemeente: "Vottem" },
  { postCode: "4042", gemeente: "Liers" },
  { postCode: "4050", gemeente: "Chaudfontaine" },
  { postCode: "4051", gemeente: "Vaux-sous-Chèvremont" },
  { postCode: "4052", gemeente: "Beaufays" },
  { postCode: "4053", gemeente: "Embourg" },
  { postCode: "4099", gemeente: "Liège X" },
  { postCode: "4100", gemeente: "Boncelles" },
  { postCode: "4100", gemeente: "Seraing" },
  { postCode: "4101", gemeente: "Jemeppe-sur-Meuse" },
  { postCode: "4102", gemeente: "Ougrée" },
  { postCode: "4120", gemeente: "Ehein" },
  { postCode: "4120", gemeente: "Rotheux-Rimière" },
  { postCode: "4121", gemeente: "Neuville-en-Condroz" },
  { postCode: "4122", gemeente: "Plainevaux" },
  { postCode: "4130", gemeente: "Esneux" },
  { postCode: "4130", gemeente: "Tilff" },
  { postCode: "4140", gemeente: "Dolembreux" },
  { postCode: "4140", gemeente: "Gomzé-Andoumont" },
  { postCode: "4140", gemeente: "Rouvreux" },
  { postCode: "4140", gemeente: "Sprimont" },
  { postCode: "4141", gemeente: "Louveigné" },
  { postCode: "4160", gemeente: "Anthisnes" },
  { postCode: "4161", gemeente: "Villers-aux-Tours" },
  { postCode: "4162", gemeente: "Hody" },
  { postCode: "4163", gemeente: "Tavier" },
  { postCode: "4170", gemeente: "Comblain-au-Pont" },
  { postCode: "4171", gemeente: "Poulseur" },
  { postCode: "4180", gemeente: "Comblain-Fairon" },
  { postCode: "4180", gemeente: "Comblain-la-Tour" },
  { postCode: "4180", gemeente: "Hamoir" },
  { postCode: "4181", gemeente: "Filot" },
  { postCode: "4190", gemeente: "Ferrières" },
  { postCode: "4190", gemeente: "My" },
  { postCode: "4190", gemeente: "Vieuxville" },
  { postCode: "4190", gemeente: "Werbomont" },
  { postCode: "4190", gemeente: "Xhoris" },
  { postCode: "4210", gemeente: "Burdinne" },
  { postCode: "4210", gemeente: "Hannêche" },
  { postCode: "4210", gemeente: "Lamontzée" },
  { postCode: "4210", gemeente: "Marneffe" },
  { postCode: "4210", gemeente: "Oteppe" },
  { postCode: "4217", gemeente: "Héron" },
  { postCode: "4217", gemeente: "Lavoir" },
  { postCode: "4217", gemeente: "Waret-l'Évêque" },
  { postCode: "4218", gemeente: "Couthuin" },
  { postCode: "4219", gemeente: "Acosse" },
  { postCode: "4219", gemeente: "Ambresin" },
  { postCode: "4219", gemeente: "Meeffe" },
  { postCode: "4219", gemeente: "Wasseiges" },
  { postCode: "4250", gemeente: "Boëlhe" },
  { postCode: "4250", gemeente: "Geer" },
  { postCode: "4250", gemeente: "Hollogne-sur-Geer" },
  { postCode: "4250", gemeente: "Lens-Saint-Servais" },
  { postCode: "4252", gemeente: "Omal" },
  { postCode: "4253", gemeente: "Darion" },
  { postCode: "4254", gemeente: "Ligney" },
  { postCode: "4257", gemeente: "Berloz" },
  { postCode: "4257", gemeente: "Corswarem" },
  { postCode: "4257", gemeente: "Rosoux-Crenwick" },
  { postCode: "4260", gemeente: "Avennes" },
  { postCode: "4260", gemeente: "Braives" },
  { postCode: "4260", gemeente: "Ciplet" },
  { postCode: "4260", gemeente: "Fallais" },
  { postCode: "4260", gemeente: "Fumal" },
  { postCode: "4260", gemeente: "Ville-en-Hesbaye" },
  { postCode: "4261", gemeente: "Latinne" },
  { postCode: "4263", gemeente: "Tourinne" },
  { postCode: "4280", gemeente: "Abolens" },
  { postCode: "4280", gemeente: "Avernas-le-Bauduin" },
  { postCode: "4280", gemeente: "Avin" },
  { postCode: "4280", gemeente: "Bertrée" },
  { postCode: "4280", gemeente: "Blehen" },
  { postCode: "4280", gemeente: "Cras-Avernas" },
  { postCode: "4280", gemeente: "Crehen" },
  { postCode: "4280", gemeente: "Grand-Hallet" },
  { postCode: "4280", gemeente: "Hannut" },
  { postCode: "4280", gemeente: "Lens-Saint-Remy" },
  { postCode: "4280", gemeente: "Merdorp" },
  { postCode: "4280", gemeente: "Moxhe" },
  { postCode: "4280", gemeente: "Petit-Hallet" },
  { postCode: "4280", gemeente: "Poucet" },
  { postCode: "4280", gemeente: "Thisnes" },
  { postCode: "4280", gemeente: "Trognée" },
  { postCode: "4280", gemeente: "Villers-le-Peuplier" },
  { postCode: "4280", gemeente: "Wansin" },
  { postCode: "4287", gemeente: "Lincent" },
  { postCode: "4287", gemeente: "Pellaines" },
  { postCode: "4287", gemeente: "Racour" },
  { postCode: "4300", gemeente: "Bettincourt" },
  { postCode: "4300", gemeente: "Bleret" },
  { postCode: "4300", gemeente: "Bovenistier" },
  { postCode: "4300", gemeente: "Grand-Axhe" },
  { postCode: "4300", gemeente: "Lantremange" },
  { postCode: "4300", gemeente: "Oleye" },
  { postCode: "4300", gemeente: "Waremme" },
  { postCode: "4317", gemeente: "Aineffe" },
  { postCode: "4317", gemeente: "Borlez" },
  { postCode: "4317", gemeente: "Celles" },
  { postCode: "4317", gemeente: "Faimes" },
  { postCode: "4317", gemeente: "Les Waleffes" },
  { postCode: "4317", gemeente: "Viemme" },
  { postCode: "4340", gemeente: "Awans" },
  { postCode: "4340", gemeente: "Fooz" },
  { postCode: "4340", gemeente: "Othée" },
  { postCode: "4340", gemeente: "Villers-l'Evêque" },
  { postCode: "4342", gemeente: "Hognoul" },
  { postCode: "4347", gemeente: "Fexhe-le-Haut-Clocher" },
  { postCode: "4347", gemeente: "Freloux" },
  { postCode: "4347", gemeente: "Noville" },
  { postCode: "4347", gemeente: "Roloux" },
  { postCode: "4347", gemeente: "Voroux-Goreux" },
  { postCode: "4350", gemeente: "Lamine" },
  { postCode: "4350", gemeente: "Momalle" },
  { postCode: "4350", gemeente: "Pousset" },
  { postCode: "4350", gemeente: "Remicourt" },
  { postCode: "4351", gemeente: "Hodeige" },
  { postCode: "4357", gemeente: "Donceel" },
  { postCode: "4357", gemeente: "Haneffe" },
  { postCode: "4357", gemeente: "Jeneffe" },
  { postCode: "4357", gemeente: "Limont" },
  { postCode: "4360", gemeente: "Bergilers" },
  { postCode: "4360", gemeente: "Grandville" },
  { postCode: "4360", gemeente: "Lens-sur-Geer" },
  { postCode: "4360", gemeente: "Oreye" },
  { postCode: "4360", gemeente: "Otrange" },
  { postCode: "4367", gemeente: "Crisnée" },
  { postCode: "4367", gemeente: "Fize-Le-Marsal" },
  { postCode: "4367", gemeente: "Kemexhe" },
  { postCode: "4367", gemeente: "Odeur" },
  { postCode: "4367", gemeente: "Thys" },
  { postCode: "4400", gemeente: "Awirs" },
  { postCode: "4400", gemeente: "Chokier" },
  { postCode: "4400", gemeente: "Flémalle-Grande" },
  { postCode: "4400", gemeente: "Flémalle-Haute" },
  { postCode: "4400", gemeente: "Gleixhe" },
  { postCode: "4400", gemeente: "Ivoz-Ramet" },
  { postCode: "4400", gemeente: "Mons-lez-Liège" },
  { postCode: "4420", gemeente: "Montegnée" },
  { postCode: "4420", gemeente: "Saint-Nicolas" },
  { postCode: "4420", gemeente: "Tilleur" },
  { postCode: "4430", gemeente: "Ans" },
  { postCode: "4431", gemeente: "Loncin" },
  { postCode: "4432", gemeente: "Alleur" },
  { postCode: "4432", gemeente: "Xhendremael" },
  { postCode: "4450", gemeente: "Juprelle" },
  { postCode: "4450", gemeente: "Lantin" },
  { postCode: "4450", gemeente: "Slins" },
  { postCode: "4451", gemeente: "Voroux-lez-Liers" },
  { postCode: "4452", gemeente: "Paifve" },
  { postCode: "4452", gemeente: "Wihogne" },
  { postCode: "4453", gemeente: "Villers-Saint-Siméon" },
  { postCode: "4458", gemeente: "Fexhe-Slins" },
  { postCode: "4460", gemeente: "Bierset" },
  { postCode: "4460", gemeente: "Grâce-Berleur" },
  { postCode: "4460", gemeente: "Grâce-Hollogne" },
  { postCode: "4460", gemeente: "Hollogne-aux-Pierres" },
  { postCode: "4460", gemeente: "Horion-Hozémont" },
  { postCode: "4460", gemeente: "Velroux" },
  { postCode: "4470", gemeente: "Saint-Georges-sur-Meuse" },
  { postCode: "4480", gemeente: "Clermont-sous-Huy" },
  { postCode: "4480", gemeente: "Ehein" },
  { postCode: "4480", gemeente: "Engis" },
  { postCode: "4480", gemeente: "Hermalle-sous-Huy" },
  { postCode: "4500", gemeente: "Ben-Ahin" },
  { postCode: "4500", gemeente: "Huy" },
  { postCode: "4500", gemeente: "Tihange" },
  { postCode: "4520", gemeente: "Antheit" },
  { postCode: "4520", gemeente: "Bas-Oha" },
  { postCode: "4520", gemeente: "Huccorgne" },
  { postCode: "4520", gemeente: "Moha" },
  { postCode: "4520", gemeente: "Vinalmont" },
  { postCode: "4520", gemeente: "Wanze" },
  { postCode: "4530", gemeente: "Fize-Fontaine" },
  { postCode: "4530", gemeente: "Vaux-et-Borset" },
  { postCode: "4530", gemeente: "Vieux-Waleffe" },
  { postCode: "4530", gemeente: "Villers-le-Bouillet" },
  { postCode: "4530", gemeente: "Warnant-Dreye" },
  { postCode: "4537", gemeente: "Bodegnée" },
  { postCode: "4537", gemeente: "Chapon-Seraing" },
  { postCode: "4537", gemeente: "Seraing-le-Château" },
  { postCode: "4537", gemeente: "Verlaine" },
  { postCode: "4540", gemeente: "Amay" },
  { postCode: "4540", gemeente: "Ampsin" },
  { postCode: "4540", gemeente: "Flône" },
  { postCode: "4540", gemeente: "Jehay" },
  { postCode: "4540", gemeente: "Ombret" },
  { postCode: "4550", gemeente: "Nandrin" },
  { postCode: "4550", gemeente: "Saint-Séverin" },
  { postCode: "4550", gemeente: "Villers-le-Temple" },
  { postCode: "4550", gemeente: "Yernée-Fraineux" },
  { postCode: "4557", gemeente: "Abée" },
  { postCode: "4557", gemeente: "Fraiture" },
  { postCode: "4557", gemeente: "Ramelot" },
  { postCode: "4557", gemeente: "Seny" },
  { postCode: "4557", gemeente: "Soheit-Tinlot" },
  { postCode: "4557", gemeente: "Tinlot" },
  { postCode: "4560", gemeente: "Bois-et-Borsu" },
  { postCode: "4560", gemeente: "Clavier" },
  { postCode: "4560", gemeente: "Les Avins" },
  { postCode: "4560", gemeente: "Ocquier" },
  { postCode: "4560", gemeente: "Pailhe" },
  { postCode: "4560", gemeente: "Terwagne" },
  { postCode: "4570", gemeente: "Marchin" },
  { postCode: "4570", gemeente: "Vyle-et-Tharoul" },
  { postCode: "4577", gemeente: "Modave" },
  { postCode: "4577", gemeente: "Outrelouxhe" },
  { postCode: "4577", gemeente: "Strée-lez-Huy" },
  { postCode: "4577", gemeente: "Vierset-Barse" },
  { postCode: "4590", gemeente: "Ellemelle" },
  { postCode: "4590", gemeente: "Ouffet" },
  { postCode: "4590", gemeente: "Warzée" },
  { postCode: "4600", gemeente: "Lanaye" },
  { postCode: "4600", gemeente: "Lixhe" },
  { postCode: "4600", gemeente: "Richelle" },
  { postCode: "4600", gemeente: "Visé" },
  { postCode: "4601", gemeente: "Argenteau" },
  { postCode: "4602", gemeente: "Cheratte" },
  { postCode: "4606", gemeente: "Saint-André" },
  { postCode: "4607", gemeente: "Berneau" },
  { postCode: "4607", gemeente: "Bombaye" },
  { postCode: "4607", gemeente: "Dalhem" },
  { postCode: "4607", gemeente: "Feneur" },
  { postCode: "4607", gemeente: "Mortroux" },
  { postCode: "4608", gemeente: "Neufchâteau" },
  { postCode: "4608", gemeente: "Warsage" },
  { postCode: "4610", gemeente: "Bellaire" },
  { postCode: "4610", gemeente: "Beyne-Heusay" },
  { postCode: "4610", gemeente: "Queue-du-Bois" },
  { postCode: "4620", gemeente: "Fléron" },
  { postCode: "4621", gemeente: "Retinne" },
  { postCode: "4623", gemeente: "Magnée" },
  { postCode: "4624", gemeente: "Romsée" },
  { postCode: "4630", gemeente: "Ayeneux" },
  { postCode: "4630", gemeente: "Micheroux" },
  { postCode: "4630", gemeente: "Soumagne" },
  { postCode: "4630", gemeente: "Tignée" },
  { postCode: "4631", gemeente: "Evegnée" },
  { postCode: "4632", gemeente: "Cérexhe-Heuseux" },
  { postCode: "4633", gemeente: "Melen" },
  { postCode: "4650", gemeente: "Chaineux" },
  { postCode: "4650", gemeente: "Grand-Rechain" },
  { postCode: "4650", gemeente: "Herve" },
  { postCode: "4650", gemeente: "Julémont" },
  { postCode: "4651", gemeente: "Battice" },
  { postCode: "4652", gemeente: "Xhendelesse" },
  { postCode: "4653", gemeente: "Bolland" },
  { postCode: "4654", gemeente: "Charneux" },
  { postCode: "4670", gemeente: "Blégny" },
  { postCode: "4670", gemeente: "Mortier" },
  { postCode: "4670", gemeente: "Trembleur" },
  { postCode: "4671", gemeente: "Barchon" },
  { postCode: "4671", gemeente: "Housse" },
  { postCode: "4671", gemeente: "Saive" },
  { postCode: "4672", gemeente: "Saint-Remy" },
  { postCode: "4680", gemeente: "Hermée" },
  { postCode: "4680", gemeente: "Oupeye" },
  { postCode: "4681", gemeente: "Hermalle-sous-Argenteau" },
  { postCode: "4682", gemeente: "Heure-le-Romain" },
  { postCode: "4682", gemeente: "Houtain-Saint-Siméon" },
  { postCode: "4683", gemeente: "Vivegnis" },
  { postCode: "4684", gemeente: "Haccourt" },
  { postCode: "4690", gemeente: "Bassenge" },
  { postCode: "4690", gemeente: "Boirs" },
  { postCode: "4690", gemeente: "Eben-Emael" },
  { postCode: "4690", gemeente: "Glons" },
  { postCode: "4690", gemeente: "Roclenge-sur-Geer" },
  { postCode: "4690", gemeente: "Wonck" },
  { postCode: "4700", gemeente: "Eupen" },
  { postCode: "4701", gemeente: "Kettenis" },
  { postCode: "4710", gemeente: "Lontzen" },
  { postCode: "4711", gemeente: "Walhorn" },
  { postCode: "4720", gemeente: "Kelmis" },
  { postCode: "4721", gemeente: "Neu-Moresnet" },
  { postCode: "4728", gemeente: "Hergenrath" },
  { postCode: "4730", gemeente: "Hauset" },
  { postCode: "4730", gemeente: "Raeren" },
  { postCode: "4731", gemeente: "Eynatten" },
  { postCode: "4750", gemeente: "Butgenbach" },
  { postCode: "4750", gemeente: "Elsenborn" },
  { postCode: "4760", gemeente: "Büllingen" },
  { postCode: "4760", gemeente: "Manderfeld" },
  { postCode: "4761", gemeente: "Rocherath" },
  { postCode: "4770", gemeente: "Amel" },
  { postCode: "4770", gemeente: "Meyerode" },
  { postCode: "4771", gemeente: "Heppenbach" },
  { postCode: "4780", gemeente: "Recht" },
  { postCode: "4780", gemeente: "Sankt-Vith" },
  { postCode: "4782", gemeente: "Schönberg" },
  { postCode: "4783", gemeente: "Lommersweiler" },
  { postCode: "4784", gemeente: "Crombach" },
  { postCode: "4790", gemeente: "Reuland" },
  { postCode: "4791", gemeente: "Thommen" },
  { postCode: "4800", gemeente: "Ensival" },
  { postCode: "4800", gemeente: "Lambermont" },
  { postCode: "4800", gemeente: "Petit-Rechain" },
  { postCode: "4800", gemeente: "Polleur" },
  { postCode: "4800", gemeente: "Verviers" },
  { postCode: "4801", gemeente: "Stembert" },
  { postCode: "4802", gemeente: "Heusy" },
  { postCode: "4820", gemeente: "Dison" },
  { postCode: "4821", gemeente: "Andrimont" },
  { postCode: "4830", gemeente: "Limbourg" },
  { postCode: "4831", gemeente: "Bilstain" },
  { postCode: "4834", gemeente: "Goé" },
  { postCode: "4837", gemeente: "Baelen" },
  { postCode: "4837", gemeente: "Membach" },
  { postCode: "4840", gemeente: "Welkenraedt" },
  { postCode: "4841", gemeente: "Henri-Chapelle" },
  { postCode: "4845", gemeente: "Jalhay" },
  { postCode: "4845", gemeente: "Sart-lez-Spa" },
  { postCode: "4850", gemeente: "Montzen" },
  { postCode: "4850", gemeente: "Moresnet" },
  { postCode: "4851", gemeente: "Gemmenich" },
  { postCode: "4851", gemeente: "Sippenaeken" },
  { postCode: "4852", gemeente: "Hombourg" },
  { postCode: "4860", gemeente: "Cornesse" },
  { postCode: "4860", gemeente: "Pepinster" },
  { postCode: "4860", gemeente: "Wegnez" },
  { postCode: "4861", gemeente: "Soiron" },
  { postCode: "4870", gemeente: "Forêt" },
  { postCode: "4870", gemeente: "Fraipont" },
  { postCode: "4870", gemeente: "Nessonvaux" },
  { postCode: "4877", gemeente: "Olne" },
  { postCode: "4880", gemeente: "Aubel" },
  { postCode: "4890", gemeente: "Clermont" },
  { postCode: "4890", gemeente: "Thimister" },
  { postCode: "4900", gemeente: "Spa" },
  { postCode: "4910", gemeente: "La Reid" },
  { postCode: "4910", gemeente: "Polleur" },
  { postCode: "4910", gemeente: "Theux" },
  { postCode: "4920", gemeente: "Aywaille" },
  { postCode: "4920", gemeente: "Ernonheid" },
  { postCode: "4920", gemeente: "Harzé" },
  { postCode: "4920", gemeente: "Louveigné" },
  { postCode: "4920", gemeente: "Sougné-Remouchamps" },
  { postCode: "4950", gemeente: "Faymonville" },
  { postCode: "4950", gemeente: "Robertville" },
  { postCode: "4950", gemeente: "Sourbrodt" },
  { postCode: "4950", gemeente: "Weismes" },
  { postCode: "4960", gemeente: "Bellevaux-Ligneuville" },
  { postCode: "4960", gemeente: "Bevercé" },
  { postCode: "4960", gemeente: "Malmedy" },
  { postCode: "4970", gemeente: "Francorchamps" },
  { postCode: "4970", gemeente: "Stavelot" },
  { postCode: "4980", gemeente: "Fosse" },
  { postCode: "4980", gemeente: "Trois-Ponts" },
  { postCode: "4980", gemeente: "Wanne" },
  { postCode: "4983", gemeente: "Basse-Bodeux" },
  { postCode: "4987", gemeente: "Chevron" },
  { postCode: "4987", gemeente: "La Gleize" },
  { postCode: "4987", gemeente: "Lorcé" },
  { postCode: "4987", gemeente: "Rahier" },
  { postCode: "4987", gemeente: "Stoumont" },
  { postCode: "4990", gemeente: "Arbrefontaine" },
  { postCode: "4990", gemeente: "Bra" },
  { postCode: "4990", gemeente: "Lierneux" },
  { postCode: "5000", gemeente: "Beez" },
  { postCode: "5000", gemeente: "Namur" },
  { postCode: "5001", gemeente: "Belgrade" },
  { postCode: "5002", gemeente: "Saint-Servais" },
  { postCode: "5003", gemeente: "Saint-Marc" },
  { postCode: "5004", gemeente: "Bouge" },
  { postCode: "5010", gemeente: "SA SudPresse" },
  { postCode: "5012", gemeente: "Parlement Wallon" },
  { postCode: "5020", gemeente: "Champion" },
  { postCode: "5020", gemeente: "Daussoulx" },
  { postCode: "5020", gemeente: "Flawinne" },
  { postCode: "5020", gemeente: "Malonne" },
  { postCode: "5020", gemeente: "Suarlée" },
  { postCode: "5020", gemeente: "Temploux" },
  { postCode: "5020", gemeente: "Vedrin" },
  { postCode: "5021", gemeente: "Boninne" },
  { postCode: "5022", gemeente: "Cognelée" },
  { postCode: "5024", gemeente: "Gelbressée" },
  { postCode: "5024", gemeente: "Marche-les-Dames" },
  { postCode: "5030", gemeente: "Beuzet" },
  { postCode: "5030", gemeente: "Ernage" },
  { postCode: "5030", gemeente: "Gembloux" },
  { postCode: "5030", gemeente: "Grand-Manil" },
  { postCode: "5030", gemeente: "Lonzée" },
  { postCode: "5030", gemeente: "Sauvenière" },
  { postCode: "5031", gemeente: "Grand-Leez" },
  { postCode: "5032", gemeente: "Bossière" },
  { postCode: "5032", gemeente: "Bothey" },
  { postCode: "5032", gemeente: "Corroy-le-Château" },
  { postCode: "5032", gemeente: "Isnes" },
  { postCode: "5032", gemeente: "Mazy" },
  { postCode: "5060", gemeente: "Arsimont" },
  { postCode: "5060", gemeente: "Auvelais" },
  { postCode: "5060", gemeente: "Falisolle" },
  { postCode: "5060", gemeente: "Keumiée" },
  { postCode: "5060", gemeente: "Moignelée" },
  { postCode: "5060", gemeente: "Tamines" },
  { postCode: "5060", gemeente: "Velaine-sur-Sambre" },
  { postCode: "5070", gemeente: "Aisemont" },
  { postCode: "5070", gemeente: "Fosses-la-Ville" },
  { postCode: "5070", gemeente: "Le Roux" },
  { postCode: "5070", gemeente: "Sart-Eustache" },
  { postCode: "5070", gemeente: "Sart-Saint-Laurent" },
  { postCode: "5070", gemeente: "Vitrival" },
  { postCode: "5080", gemeente: "Emines" },
  { postCode: "5080", gemeente: "Rhisnes" },
  { postCode: "5080", gemeente: "Villers-lez-Heest" },
  { postCode: "5080", gemeente: "Warisoulx" },
  { postCode: "5081", gemeente: "Bovesse" },
  { postCode: "5081", gemeente: "Meux" },
  { postCode: "5081", gemeente: "Saint-Denis-Bovesse" },
  { postCode: "5100", gemeente: "Dave" },
  { postCode: "5100", gemeente: "Jambes" },
  { postCode: "5100", gemeente: "Naninne" },
  { postCode: "5100", gemeente: "Wépion" },
  { postCode: "5100", gemeente: "Wierde" },
  { postCode: "5101", gemeente: "Erpent" },
  { postCode: "5101", gemeente: "Lives-sur-Meuse" },
  { postCode: "5101", gemeente: "Loyers" },
  { postCode: "5140", gemeente: "Boignée" },
  { postCode: "5140", gemeente: "Ligny" },
  { postCode: "5140", gemeente: "Sombreffe" },
  { postCode: "5140", gemeente: "Tongrinne" },
  { postCode: "5150", gemeente: "Floreffe" },
  { postCode: "5150", gemeente: "Floriffoux" },
  { postCode: "5150", gemeente: "Franière" },
  { postCode: "5150", gemeente: "Soye" },
  { postCode: "5170", gemeente: "Arbre" },
  { postCode: "5170", gemeente: "Bois-de-Villers" },
  { postCode: "5170", gemeente: "Lesve" },
  { postCode: "5170", gemeente: "Lustin" },
  { postCode: "5170", gemeente: "Profondeville" },
  { postCode: "5170", gemeente: "Rivière" },
  { postCode: "5190", gemeente: "Balâtre" },
  { postCode: "5190", gemeente: "Ham-sur-Sambre" },
  { postCode: "5190", gemeente: "Jemeppe-sur-Sambre" },
  { postCode: "5190", gemeente: "Mornimont" },
  { postCode: "5190", gemeente: "Moustier-sur-Sambre" },
  { postCode: "5190", gemeente: "Onoz" },
  { postCode: "5190", gemeente: "Saint-Martin" },
  { postCode: "5190", gemeente: "Spy" },
  { postCode: "5300", gemeente: "Andenne" },
  { postCode: "5300", gemeente: "Bonneville" },
  { postCode: "5300", gemeente: "Coutisse" },
  { postCode: "5300", gemeente: "Landenne" },
  { postCode: "5300", gemeente: "Maizeret" },
  { postCode: "5300", gemeente: "Namêche" },
  { postCode: "5300", gemeente: "Sclayn" },
  { postCode: "5300", gemeente: "Seilles" },
  { postCode: "5300", gemeente: "Thon" },
  { postCode: "5300", gemeente: "Vezin" },
  { postCode: "5310", gemeente: "Aische-en-Refail" },
  { postCode: "5310", gemeente: "Bolinne" },
  { postCode: "5310", gemeente: "Boneffe" },
  { postCode: "5310", gemeente: "Branchon" },
  { postCode: "5310", gemeente: "Dhuy" },
  { postCode: "5310", gemeente: "Eghezée" },
  { postCode: "5310", gemeente: "Hanret" },
  { postCode: "5310", gemeente: "Leuze" },
  { postCode: "5310", gemeente: "Liernu" },
  { postCode: "5310", gemeente: "Longchamps" },
  { postCode: "5310", gemeente: "Mehaigne" },
  { postCode: "5310", gemeente: "Noville-sur-Mehaigne" },
  { postCode: "5310", gemeente: "Saint-Germain" },
  { postCode: "5310", gemeente: "Taviers" },
  { postCode: "5310", gemeente: "Upigny" },
  { postCode: "5310", gemeente: "Waret-la-Chaussée" },
  { postCode: "5330", gemeente: "Assesse" },
  { postCode: "5330", gemeente: "Maillen" },
  { postCode: "5330", gemeente: "Sart-Bernard" },
  { postCode: "5332", gemeente: "Crupet" },
  { postCode: "5333", gemeente: "Sorinne-la-Longue" },
  { postCode: "5334", gemeente: "Florée" },
  { postCode: "5336", gemeente: "Courrière" },
  { postCode: "5340", gemeente: "Faulx-les-Tombes" },
  { postCode: "5340", gemeente: "Gesves" },
  { postCode: "5340", gemeente: "Haltinne" },
  { postCode: "5340", gemeente: "Mozet" },
  { postCode: "5340", gemeente: "Sorée" },
  { postCode: "5350", gemeente: "Evelette" },
  { postCode: "5350", gemeente: "Ohey" },
  { postCode: "5351", gemeente: "Haillot" },
  { postCode: "5352", gemeente: "Perwez-Haillot" },
  { postCode: "5353", gemeente: "Goesnes" },
  { postCode: "5354", gemeente: "Jallet" },
  { postCode: "5360", gemeente: "Hamois" },
  { postCode: "5360", gemeente: "Natoye" },
  { postCode: "5361", gemeente: "Mohiville" },
  { postCode: "5361", gemeente: "Scy" },
  { postCode: "5362", gemeente: "Achet" },
  { postCode: "5363", gemeente: "Emptinne" },
  { postCode: "5364", gemeente: "Schaltin" },
  { postCode: "5370", gemeente: "Barvaux-Condroz" },
  { postCode: "5370", gemeente: "Flostoy" },
  { postCode: "5370", gemeente: "Havelange" },
  { postCode: "5370", gemeente: "Jeneffe" },
  { postCode: "5370", gemeente: "Porcheresse" },
  { postCode: "5370", gemeente: "Verlée" },
  { postCode: "5372", gemeente: "Méan" },
  { postCode: "5374", gemeente: "Maffe" },
  { postCode: "5376", gemeente: "Miécret" },
  { postCode: "5377", gemeente: "Baillonville" },
  { postCode: "5377", gemeente: "Bonsin" },
  { postCode: "5377", gemeente: "Heure" },
  { postCode: "5377", gemeente: "Hogne" },
  { postCode: "5377", gemeente: "Nettinne" },
  { postCode: "5377", gemeente: "Noiseux" },
  { postCode: "5377", gemeente: "Sinsin" },
  { postCode: "5377", gemeente: "Somme-Leuze" },
  { postCode: "5377", gemeente: "Waillet" },
  { postCode: "5380", gemeente: "Bierwart" },
  { postCode: "5380", gemeente: "Cortil-Wodon" },
  { postCode: "5380", gemeente: "Forville" },
  { postCode: "5380", gemeente: "Franc-Waret" },
  { postCode: "5380", gemeente: "Hemptinne" },
  { postCode: "5380", gemeente: "Hingeon" },
  { postCode: "5380", gemeente: "Marchovelette" },
  { postCode: "5380", gemeente: "Noville-les-Bois" },
  { postCode: "5380", gemeente: "Pontillas" },
  { postCode: "5380", gemeente: "Tillier" },
  { postCode: "5500", gemeente: "Anseremme" },
  { postCode: "5500", gemeente: "Bouvignes-sur-Meuse" },
  { postCode: "5500", gemeente: "Dinant" },
  { postCode: "5500", gemeente: "Dréhance" },
  { postCode: "5500", gemeente: "Falmagne" },
  { postCode: "5500", gemeente: "Falmignoul" },
  { postCode: "5500", gemeente: "Furfooz" },
  { postCode: "5501", gemeente: "Lisogne" },
  { postCode: "5502", gemeente: "Thynes" },
  { postCode: "5503", gemeente: "Sorinnes" },
  { postCode: "5504", gemeente: "Foy-Notre-Dame" },
  { postCode: "5520", gemeente: "Anthée" },
  { postCode: "5520", gemeente: "Onhaye" },
  { postCode: "5521", gemeente: "Serville" },
  { postCode: "5522", gemeente: "Falaën" },
  { postCode: "5523", gemeente: "Sommière" },
  { postCode: "5523", gemeente: "Weillen" },
  { postCode: "5524", gemeente: "Gérin" },
  { postCode: "5530", gemeente: "Dorinne" },
  { postCode: "5530", gemeente: "Durnal" },
  { postCode: "5530", gemeente: "Evrehailles" },
  { postCode: "5530", gemeente: "Godinne" },
  { postCode: "5530", gemeente: "Houx" },
  { postCode: "5530", gemeente: "Mont" },
  { postCode: "5530", gemeente: "Purnode" },
  { postCode: "5530", gemeente: "Spontin" },
  { postCode: "5530", gemeente: "Yvoir" },
  { postCode: "5537", gemeente: "Anhée" },
  { postCode: "5537", gemeente: "Annevoie-Rouillon" },
  { postCode: "5537", gemeente: "Bioul" },
  { postCode: "5537", gemeente: "Denée" },
  { postCode: "5537", gemeente: "Haut-le-Wastia" },
  { postCode: "5537", gemeente: "Sosoye" },
  { postCode: "5537", gemeente: "Warnant" },
  { postCode: "5540", gemeente: "Hastière-Lavaux" },
  { postCode: "5540", gemeente: "Hermeton-sur-Meuse" },
  { postCode: "5540", gemeente: "Waulsort" },
  { postCode: "5541", gemeente: "Hastière-par-Delà" },
  { postCode: "5542", gemeente: "Blaimont" },
  { postCode: "5543", gemeente: "Heer" },
  { postCode: "5544", gemeente: "Agimont" },
  { postCode: "5550", gemeente: "Alle" },
  { postCode: "5550", gemeente: "Bagimont" },
  { postCode: "5550", gemeente: "Bohan" },
  { postCode: "5550", gemeente: "Chairière" },
  { postCode: "5550", gemeente: "Laforêt" },
  { postCode: "5550", gemeente: "Membre" },
  { postCode: "5550", gemeente: "Mouzaive" },
  { postCode: "5550", gemeente: "Nafraiture" },
  { postCode: "5550", gemeente: "Orchimont" },
  { postCode: "5550", gemeente: "Pussemange" },
  { postCode: "5550", gemeente: "Sugny" },
  { postCode: "5550", gemeente: "Vresse-sur-Semois" },
  { postCode: "5555", gemeente: "Baillamont" },
  { postCode: "5555", gemeente: "Bellefontaine" },
  { postCode: "5555", gemeente: "Bièvre" },
  { postCode: "5555", gemeente: "Cornimont" },
  { postCode: "5555", gemeente: "Graide" },
  { postCode: "5555", gemeente: "Gros-Fays" },
  { postCode: "5555", gemeente: "Monceau-en-Ardenne" },
  { postCode: "5555", gemeente: "Naomé" },
  { postCode: "5555", gemeente: "Oizy" },
  { postCode: "5555", gemeente: "Petit-Fays" },
  { postCode: "5560", gemeente: "Ciergnon" },
  { postCode: "5560", gemeente: "Finnevaux" },
  { postCode: "5560", gemeente: "Houyet" },
  { postCode: "5560", gemeente: "Hulsonniaux" },
  { postCode: "5560", gemeente: "Mesnil-Eglise" },
  { postCode: "5560", gemeente: "Mesnil-Saint-Blaise" },
  { postCode: "5561", gemeente: "Celles" },
  { postCode: "5562", gemeente: "Custinne" },
  { postCode: "5563", gemeente: "Hour" },
  { postCode: "5564", gemeente: "Wanlin" },
  { postCode: "5570", gemeente: "Baronville" },
  { postCode: "5570", gemeente: "Beauraing" },
  { postCode: "5570", gemeente: "Dion" },
  { postCode: "5570", gemeente: "Felenne" },
  { postCode: "5570", gemeente: "Feschaux" },
  { postCode: "5570", gemeente: "Honnay" },
  { postCode: "5570", gemeente: "Javingue" },
  { postCode: "5570", gemeente: "Vonêche" },
  { postCode: "5570", gemeente: "Wancennes" },
  { postCode: "5570", gemeente: "Winenne" },
  { postCode: "5571", gemeente: "Wiesme" },
  { postCode: "5572", gemeente: "Focant" },
  { postCode: "5573", gemeente: "Martouzin-Neuville" },
  { postCode: "5574", gemeente: "Pondrôme" },
  { postCode: "5575", gemeente: "Bourseigne-Neuve" },
  { postCode: "5575", gemeente: "Bourseigne-Vieille" },
  { postCode: "5575", gemeente: "Gedinne" },
  { postCode: "5575", gemeente: "Houdremont" },
  { postCode: "5575", gemeente: "Louette-Saint-Denis" },
  { postCode: "5575", gemeente: "Louette-Saint-Pierre" },
  { postCode: "5575", gemeente: "Malvoisin" },
  { postCode: "5575", gemeente: "Patignies" },
  { postCode: "5575", gemeente: "Rienne" },
  { postCode: "5575", gemeente: "Sart-Custinne" },
  { postCode: "5575", gemeente: "Vencimont" },
  { postCode: "5575", gemeente: "Willerzie" },
  { postCode: "5576", gemeente: "Froidfontaine" },
  { postCode: "5580", gemeente: "Ave-et-Auffe" },
  { postCode: "5580", gemeente: "Buissonville" },
  { postCode: "5580", gemeente: "Eprave" },
  { postCode: "5580", gemeente: "Han-sur-Lesse" },
  { postCode: "5580", gemeente: "Jemelle" },
  { postCode: "5580", gemeente: "Lavaux-Sainte-Anne" },
  { postCode: "5580", gemeente: "Lessive" },
  { postCode: "5580", gemeente: "Mont-Gauthier" },
  { postCode: "5580", gemeente: "Rochefort" },
  { postCode: "5580", gemeente: "Villers-sur-Lesse" },
  { postCode: "5580", gemeente: "Wavreille" },
  { postCode: "5589", gemeente: "Jemelle" },
  { postCode: "5590", gemeente: "Achêne" },
  { postCode: "5590", gemeente: "Braibant" },
  { postCode: "5590", gemeente: "Chevetogne" },
  { postCode: "5590", gemeente: "Ciney" },
  { postCode: "5590", gemeente: "Conneux" },
  { postCode: "5590", gemeente: "Haversin" },
  { postCode: "5590", gemeente: "Leignon" },
  { postCode: "5590", gemeente: "Pessoux" },
  { postCode: "5590", gemeente: "Serinchamps" },
  { postCode: "5590", gemeente: "Sovet" },
  { postCode: "5600", gemeente: "Fagnolle" },
  { postCode: "5600", gemeente: "Franchimont" },
  { postCode: "5600", gemeente: "Jamagne" },
  { postCode: "5600", gemeente: "Jamiolle" },
  { postCode: "5600", gemeente: "Merlemont" },
  { postCode: "5600", gemeente: "Neuville" },
  { postCode: "5600", gemeente: "Omezée" },
  { postCode: "5600", gemeente: "Philippeville" },
  { postCode: "5600", gemeente: "Roly" },
  { postCode: "5600", gemeente: "Romedenne" },
  { postCode: "5600", gemeente: "Samart" },
  { postCode: "5600", gemeente: "Sart-en-Fagne" },
  { postCode: "5600", gemeente: "Sautour" },
  { postCode: "5600", gemeente: "Surice" },
  { postCode: "5600", gemeente: "Villers-en-Fagne" },
  { postCode: "5600", gemeente: "Villers-le-Gambon" },
  { postCode: "5600", gemeente: "Vodecée" },
  { postCode: "5620", gemeente: "Corenne" },
  { postCode: "5620", gemeente: "Flavion" },
  { postCode: "5620", gemeente: "Florennes" },
  { postCode: "5620", gemeente: "Hemptinne-lez-Florennes" },
  { postCode: "5620", gemeente: "Morville" },
  { postCode: "5620", gemeente: "Rosée" },
  { postCode: "5620", gemeente: "Saint-Aubin" },
  { postCode: "5621", gemeente: "Hanzinelle" },
  { postCode: "5621", gemeente: "Hanzinne" },
  { postCode: "5621", gemeente: "Morialmé" },
  { postCode: "5621", gemeente: "Thy-le-Bauduin" },
  { postCode: "5630", gemeente: "Cerfontaine" },
  { postCode: "5630", gemeente: "Daussois" },
  { postCode: "5630", gemeente: "Senzeilles" },
  { postCode: "5630", gemeente: "Silenrieux" },
  { postCode: "5630", gemeente: "Soumoy" },
  { postCode: "5630", gemeente: "Villers-Deux-Eglises" },
  { postCode: "5640", gemeente: "Biesme" },
  { postCode: "5640", gemeente: "Biesmerée" },
  { postCode: "5640", gemeente: "Graux" },
  { postCode: "5640", gemeente: "Mettet" },
  { postCode: "5640", gemeente: "Oret" },
  { postCode: "5640", gemeente: "Saint-Gérard" },
  { postCode: "5641", gemeente: "Furnaux" },
  { postCode: "5644", gemeente: "Ermeton-sur-Biert" },
  { postCode: "5646", gemeente: "Stave" },
  { postCode: "5650", gemeente: "Castillon" },
  { postCode: "5650", gemeente: "Chastrès" },
  { postCode: "5650", gemeente: "Clermont" },
  { postCode: "5650", gemeente: "Fontenelle" },
  { postCode: "5650", gemeente: "Fraire" },
  { postCode: "5650", gemeente: "Pry" },
  { postCode: "5650", gemeente: "Vogenée" },
  { postCode: "5650", gemeente: "Walcourt" },
  { postCode: "5650", gemeente: "Yves-Gomezée" },
  { postCode: "5651", gemeente: "Berzée" },
  { postCode: "5651", gemeente: "Gourdinne" },
  { postCode: "5651", gemeente: "Laneffe" },
  { postCode: "5651", gemeente: "Rognée" },
  { postCode: "5651", gemeente: "Somzée" },
  { postCode: "5651", gemeente: "Tarcienne" },
  { postCode: "5651", gemeente: "Thy-le-Château" },
  { postCode: "5660", gemeente: "Aublain" },
  { postCode: "5660", gemeente: "Boussu-en-Fagne" },
  { postCode: "5660", gemeente: "Brûly" },
  { postCode: "5660", gemeente: "Brûly-de-Pesche" },
  { postCode: "5660", gemeente: "Couvin" },
  { postCode: "5660", gemeente: "Cul-des-Sarts" },
  { postCode: "5660", gemeente: "Dailly" },
  { postCode: "5660", gemeente: "Frasnes" },
  { postCode: "5660", gemeente: "Gonrieux" },
  { postCode: "5660", gemeente: "Mariembourg" },
  { postCode: "5660", gemeente: "Pesche" },
  { postCode: "5660", gemeente: "Pétigny" },
  { postCode: "5660", gemeente: "Petite-Chapelle" },
  { postCode: "5660", gemeente: "Presgaux" },
  { postCode: "5670", gemeente: "Dourbes" },
  { postCode: "5670", gemeente: "Le Mesnil" },
  { postCode: "5670", gemeente: "Mazée" },
  { postCode: "5670", gemeente: "Nismes" },
  { postCode: "5670", gemeente: "Oignies-en-Thiérache" },
  { postCode: "5670", gemeente: "Olloy-Sur-Viroin" },
  { postCode: "5670", gemeente: "Treignes" },
  { postCode: "5670", gemeente: "Vierves-sur-Viroin" },
  { postCode: "5680", gemeente: "Doische" },
  { postCode: "5680", gemeente: "Gimnée" },
  { postCode: "5680", gemeente: "Gochenée" },
  { postCode: "5680", gemeente: "Matagne-la-Grande" },
  { postCode: "5680", gemeente: "Matagne-la-Petite" },
  { postCode: "5680", gemeente: "Niverlée" },
  { postCode: "5680", gemeente: "Romerée" },
  { postCode: "5680", gemeente: "Soulme" },
  { postCode: "5680", gemeente: "Vaucelles" },
  { postCode: "5680", gemeente: "Vodelée" },
  { postCode: "6000", gemeente: "Charleroi" },
  { postCode: "6001", gemeente: "Marcinelle" },
  { postCode: "6010", gemeente: "Couillet" },
  { postCode: "6020", gemeente: "Dampremy" },
  { postCode: "6030", gemeente: "Goutroux" },
  { postCode: "6030", gemeente: "Marchienne-au-Pont" },
  { postCode: "6031", gemeente: "Monceau-sur-Sambre" },
  { postCode: "6032", gemeente: "Mont-sur-Marchienne" },
  { postCode: "6040", gemeente: "Jumet" },
  { postCode: "6041", gemeente: "Gosselies" },
  { postCode: "6042", gemeente: "Lodelinsart" },
  { postCode: "6043", gemeente: "Ransart" },
  { postCode: "6044", gemeente: "Roux" },
  { postCode: "6060", gemeente: "Gilly" },
  { postCode: "6061", gemeente: "Montignies-sur-Sambre" },
  { postCode: "6075", gemeente: "CSM Charleroi X" },
  { postCode: "6099", gemeente: "Charleroi X" },
  { postCode: "6110", gemeente: "Montigny-le-Tilleul" },
  { postCode: "6111", gemeente: "Landelies" },
  { postCode: "6120", gemeente: "Cour-sur-Heure" },
  { postCode: "6120", gemeente: "Ham-sur-Heure" },
  { postCode: "6120", gemeente: "Jamioulx" },
  { postCode: "6120", gemeente: "Marbaix" },
  { postCode: "6120", gemeente: "Nalinnes" },
  { postCode: "6140", gemeente: "Fontaine-l'Évêque" },
  { postCode: "6141", gemeente: "Forchies-La-Marche" },
  { postCode: "6142", gemeente: "Leernes" },
  { postCode: "6150", gemeente: "Anderlues" },
  { postCode: "6180", gemeente: "Courcelles" },
  { postCode: "6181", gemeente: "Gouy-lez-Piéton" },
  { postCode: "6182", gemeente: "Souvret" },
  { postCode: "6183", gemeente: "Trazegnies" },
  { postCode: "6200", gemeente: "Bouffioulx" },
  { postCode: "6200", gemeente: "Châtelet" },
  { postCode: "6200", gemeente: "Châtelineau" },
  { postCode: "6210", gemeente: "Frasnes-lez-Gosselies" },
  { postCode: "6210", gemeente: "Rèves" },
  { postCode: "6210", gemeente: "Villers-Perwin" },
  { postCode: "6210", gemeente: "Wayaux" },
  { postCode: "6211", gemeente: "Mellet" },
  { postCode: "6220", gemeente: "Fleurus" },
  { postCode: "6220", gemeente: "Heppignies" },
  { postCode: "6220", gemeente: "Lambusart" },
  { postCode: "6220", gemeente: "Wangenies" },
  { postCode: "6221", gemeente: "Saint-Amand" },
  { postCode: "6222", gemeente: "Brye" },
  { postCode: "6223", gemeente: "Wagnelée" },
  { postCode: "6224", gemeente: "Wanfercée-Baulet" },
  { postCode: "6230", gemeente: "Buzet" },
  { postCode: "6230", gemeente: "Obaix" },
  { postCode: "6230", gemeente: "Pont-à-Celles" },
  { postCode: "6230", gemeente: "Thiméon" },
  { postCode: "6230", gemeente: "Viesville" },
  { postCode: "6238", gemeente: "Liberchies" },
  { postCode: "6238", gemeente: "Luttre" },
  { postCode: "6240", gemeente: "Farciennes" },
  { postCode: "6240", gemeente: "Pironchamps" },
  { postCode: "6250", gemeente: "Aiseau" },
  { postCode: "6250", gemeente: "Pont-de-Loup" },
  { postCode: "6250", gemeente: "Presles" },
  { postCode: "6250", gemeente: "Roselies" },
  { postCode: "6280", gemeente: "Acoz" },
  { postCode: "6280", gemeente: "Gerpinnes" },
  { postCode: "6280", gemeente: "Gougnies" },
  { postCode: "6280", gemeente: "Joncret" },
  { postCode: "6280", gemeente: "Loverval" },
  { postCode: "6280", gemeente: "Villers-Poterie" },
  { postCode: "6440", gemeente: "Boussu-lez-Walcourt" },
  { postCode: "6440", gemeente: "Fourbechies" },
  { postCode: "6440", gemeente: "Froidchapelle" },
  { postCode: "6440", gemeente: "Vergnies" },
  { postCode: "6441", gemeente: "Erpion" },
  { postCode: "6460", gemeente: "Bailièvre" },
  { postCode: "6460", gemeente: "Chimay" },
  { postCode: "6460", gemeente: "Robechies" },
  { postCode: "6460", gemeente: "Saint-Remy" },
  { postCode: "6460", gemeente: "Salles" },
  { postCode: "6460", gemeente: "Villers-la-Tour" },
  { postCode: "6461", gemeente: "Virelles" },
  { postCode: "6462", gemeente: "Vaulx-lez-Chimay" },
  { postCode: "6463", gemeente: "Lompret" },
  { postCode: "6464", gemeente: "Baileux" },
  { postCode: "6464", gemeente: "Bourlers" },
  { postCode: "6464", gemeente: "Forges" },
  { postCode: "6464", gemeente: "l'Escaillère" },
  { postCode: "6464", gemeente: "Rièzes" },
  { postCode: "6470", gemeente: "Grandrieu" },
  { postCode: "6470", gemeente: "Montbliart" },
  { postCode: "6470", gemeente: "Rance" },
  { postCode: "6470", gemeente: "Sautin" },
  { postCode: "6470", gemeente: "Sivry" },
  { postCode: "6500", gemeente: "Barbençon" },
  { postCode: "6500", gemeente: "Beaumont" },
  { postCode: "6500", gemeente: "Leugnies" },
  { postCode: "6500", gemeente: "Leval-Chaudeville" },
  { postCode: "6500", gemeente: "Renlies" },
  { postCode: "6500", gemeente: "Solre-Saint-Géry" },
  { postCode: "6500", gemeente: "Thirimont" },
  { postCode: "6511", gemeente: "Strée" },
  { postCode: "6530", gemeente: "Leers-et-Fosteau" },
  { postCode: "6530", gemeente: "Thuin" },
  { postCode: "6531", gemeente: "Biesme-sous-Thuin" },
  { postCode: "6532", gemeente: "Ragnies" },
  { postCode: "6533", gemeente: "Biercée" },
  { postCode: "6534", gemeente: "Gozée" },
  { postCode: "6536", gemeente: "Donstiennes" },
  { postCode: "6536", gemeente: "Thuillies" },
  { postCode: "6540", gemeente: "Lobbes" },
  { postCode: "6540", gemeente: "Mont-Sainte-Geneviève" },
  { postCode: "6542", gemeente: "Sars-la-Buissière" },
  { postCode: "6543", gemeente: "Bienne-lez-Happart" },
  { postCode: "6560", gemeente: "Bersillies-l'Abbaye" },
  { postCode: "6560", gemeente: "Erquelinnes" },
  { postCode: "6560", gemeente: "Grand-Reng" },
  { postCode: "6560", gemeente: "Hantes-Wihéries" },
  { postCode: "6560", gemeente: "Montignies-Saint-Christophe" },
  { postCode: "6560", gemeente: "Solre-sur-Sambre" },
  { postCode: "6567", gemeente: "Fontaine-Valmont" },
  { postCode: "6567", gemeente: "Labuissière" },
  { postCode: "6567", gemeente: "Merbes-le-Château" },
  { postCode: "6567", gemeente: "Merbes-Sainte-Marie" },
  { postCode: "6590", gemeente: "Momignies" },
  { postCode: "6591", gemeente: "Macon" },
  { postCode: "6592", gemeente: "Monceau-Imbrechies" },
  { postCode: "6593", gemeente: "Macquenoise" },
  { postCode: "6594", gemeente: "Beauwelz" },
  { postCode: "6596", gemeente: "Forge-Philippe" },
  { postCode: "6596", gemeente: "Seloignes" },
  { postCode: "6600", gemeente: "Bastogne" },
  { postCode: "6600", gemeente: "Longvilly" },
  { postCode: "6600", gemeente: "Noville" },
  { postCode: "6600", gemeente: "Villers-la-Bonne-Eau" },
  { postCode: "6600", gemeente: "Wardin" },
  { postCode: "6630", gemeente: "Martelange" },
  { postCode: "6637", gemeente: "Fauvillers" },
  { postCode: "6637", gemeente: "Hollange" },
  { postCode: "6637", gemeente: "Tintange" },
  { postCode: "6640", gemeente: "Hompré" },
  { postCode: "6640", gemeente: "Morhet" },
  { postCode: "6640", gemeente: "Nives" },
  { postCode: "6640", gemeente: "Sibret" },
  { postCode: "6640", gemeente: "Vaux-lez-Rosières" },
  { postCode: "6640", gemeente: "Vaux-sur-Sûre" },
  { postCode: "6642", gemeente: "Juseret" },
  { postCode: "6660", gemeente: "Houffalize" },
  { postCode: "6660", gemeente: "Nadrin" },
  { postCode: "6661", gemeente: "Mont" },
  { postCode: "6661", gemeente: "Tailles" },
  { postCode: "6662", gemeente: "Tavigny" },
  { postCode: "6663", gemeente: "Mabompré" },
  { postCode: "6666", gemeente: "Wibrin" },
  { postCode: "6670", gemeente: "Gouvy" },
  { postCode: "6670", gemeente: "Limerlé" },
  { postCode: "6671", gemeente: "Bovigny" },
  { postCode: "6672", gemeente: "Beho" },
  { postCode: "6673", gemeente: "Cherain" },
  { postCode: "6674", gemeente: "Montleban" },
  { postCode: "6680", gemeente: "Amberloup" },
  { postCode: "6680", gemeente: "Tillet" },
  { postCode: "6681", gemeente: "Lavacherie" },
  { postCode: "6686", gemeente: "Flamierge" },
  { postCode: "6687", gemeente: "Bertogne" },
  { postCode: "6688", gemeente: "Longchamps" },
  { postCode: "6690", gemeente: "Bihain" },
  { postCode: "6690", gemeente: "Vielsalm" },
  { postCode: "6692", gemeente: "Petit-Thier" },
  { postCode: "6698", gemeente: "Grand-Halleux" },
  { postCode: "6700", gemeente: "Arlon" },
  { postCode: "6700", gemeente: "Bonnert" },
  { postCode: "6700", gemeente: "Heinsch" },
  { postCode: "6700", gemeente: "Toernich" },
  { postCode: "6704", gemeente: "Guirsch" },
  { postCode: "6706", gemeente: "Autelbas" },
  { postCode: "6717", gemeente: "Attert" },
  { postCode: "6717", gemeente: "Nobressart" },
  { postCode: "6717", gemeente: "Nothomb" },
  { postCode: "6717", gemeente: "Thiaumont" },
  { postCode: "6717", gemeente: "Tontelange" },
  { postCode: "6720", gemeente: "Habay-la-Neuve" },
  { postCode: "6720", gemeente: "Hachy" },
  { postCode: "6721", gemeente: "Anlier" },
  { postCode: "6723", gemeente: "Habay-la-Vieille" },
  { postCode: "6724", gemeente: "Houdemont" },
  { postCode: "6724", gemeente: "Rulles" },
  { postCode: "6730", gemeente: "Bellefontaine" },
  { postCode: "6730", gemeente: "Rossignol" },
  { postCode: "6730", gemeente: "Saint-Vincent" },
  { postCode: "6730", gemeente: "Tintigny" },
  { postCode: "6740", gemeente: "Etalle" },
  { postCode: "6740", gemeente: "Sainte-Marie-sur-Semois" },
  { postCode: "6740", gemeente: "Villers-sur-Semois" },
  { postCode: "6741", gemeente: "Vance" },
  { postCode: "6742", gemeente: "Chantemelle" },
  { postCode: "6743", gemeente: "Buzenol" },
  { postCode: "6747", gemeente: "Châtillon" },
  { postCode: "6747", gemeente: "Meix-le-Tige" },
  { postCode: "6747", gemeente: "Saint-Léger" },
  { postCode: "6750", gemeente: "Musson" },
  { postCode: "6750", gemeente: "Mussy-la-Ville" },
  { postCode: "6750", gemeente: "Signeulx" },
  { postCode: "6760", gemeente: "Bleid" },
  { postCode: "6760", gemeente: "Ethe" },
  { postCode: "6760", gemeente: "Ruette" },
  { postCode: "6760", gemeente: "Virton" },
  { postCode: "6761", gemeente: "Latour" },
  { postCode: "6762", gemeente: "Saint-Mard" },
  { postCode: "6767", gemeente: "Dampicourt" },
  { postCode: "6767", gemeente: "Harnoncourt" },
  { postCode: "6767", gemeente: "Lamorteau" },
  { postCode: "6767", gemeente: "Torgny" },
  { postCode: "6769", gemeente: "Gérouville" },
  { postCode: "6769", gemeente: "Meix-devant-Virton" },
  { postCode: "6769", gemeente: "Robelmont" },
  { postCode: "6769", gemeente: "Sommethonne" },
  { postCode: "6769", gemeente: "Villers-la-Loue" },
  { postCode: "6780", gemeente: "Hondelange" },
  { postCode: "6780", gemeente: "Messancy" },
  { postCode: "6780", gemeente: "Wolkrange" },
  { postCode: "6781", gemeente: "Sélange" },
  { postCode: "6782", gemeente: "Habergy" },
  { postCode: "6790", gemeente: "Aubange" },
  { postCode: "6791", gemeente: "Athus" },
  { postCode: "6792", gemeente: "Halanzy" },
  { postCode: "6792", gemeente: "Rachecourt" },
  { postCode: "6800", gemeente: "Bras" },
  { postCode: "6800", gemeente: "Freux" },
  { postCode: "6800", gemeente: "Libramont-Chevigny" },
  { postCode: "6800", gemeente: "Moircy" },
  { postCode: "6800", gemeente: "Recogne" },
  { postCode: "6800", gemeente: "Remagne" },
  { postCode: "6800", gemeente: "Saint-Pierre" },
  { postCode: "6800", gemeente: "Sainte-Marie-Chevigny" },
  { postCode: "6810", gemeente: "Chiny" },
  { postCode: "6810", gemeente: "Izel" },
  { postCode: "6810", gemeente: "Jamoigne" },
  { postCode: "6811", gemeente: "Les Bulles" },
  { postCode: "6812", gemeente: "Suxy" },
  { postCode: "6813", gemeente: "Termes" },
  { postCode: "6820", gemeente: "Florenville" },
  { postCode: "6820", gemeente: "Fontenoille" },
  { postCode: "6820", gemeente: "Muno" },
  { postCode: "6820", gemeente: "Sainte-Cécile" },
  { postCode: "6821", gemeente: "Lacuisine" },
  { postCode: "6823", gemeente: "Villers-devant-Orval" },
  { postCode: "6824", gemeente: "Chassepierre" },
  { postCode: "6830", gemeente: "Bouillon" },
  { postCode: "6830", gemeente: "Les Hayons" },
  { postCode: "6830", gemeente: "Poupehan" },
  { postCode: "6830", gemeente: "Rochehaut" },
  { postCode: "6831", gemeente: "Noirfontaine" },
  { postCode: "6832", gemeente: "Sensenruth" },
  { postCode: "6833", gemeente: "Ucimont" },
  { postCode: "6833", gemeente: "Vivy" },
  { postCode: "6834", gemeente: "Bellevaux" },
  { postCode: "6836", gemeente: "Dohan" },
  { postCode: "6838", gemeente: "Corbion" },
  { postCode: "6840", gemeente: "Grandvoir" },
  { postCode: "6840", gemeente: "Grapfontaine" },
  { postCode: "6840", gemeente: "Hamipré" },
  { postCode: "6840", gemeente: "Longlier" },
  { postCode: "6840", gemeente: "Neufchâteau" },
  { postCode: "6840", gemeente: "Tournay" },
  { postCode: "6850", gemeente: "Carlsbourg" },
  { postCode: "6850", gemeente: "Offagne" },
  { postCode: "6850", gemeente: "Paliseul" },
  { postCode: "6851", gemeente: "Nollevaux" },
  { postCode: "6852", gemeente: "Maissin" },
  { postCode: "6852", gemeente: "Opont" },
  { postCode: "6853", gemeente: "Framont" },
  { postCode: "6856", gemeente: "Fays-les-Veneurs" },
  { postCode: "6860", gemeente: "Assenois" },
  { postCode: "6860", gemeente: "Ebly" },
  { postCode: "6860", gemeente: "Léglise" },
  { postCode: "6860", gemeente: "Mellier" },
  { postCode: "6860", gemeente: "Witry" },
  { postCode: "6870", gemeente: "Arville" },
  { postCode: "6870", gemeente: "Awenne" },
  { postCode: "6870", gemeente: "Hatrival" },
  { postCode: "6870", gemeente: "Mirwart" },
  { postCode: "6870", gemeente: "Saint-Hubert" },
  { postCode: "6870", gemeente: "Vesqueville" },
  { postCode: "6880", gemeente: "Auby-sur-Semois" },
  { postCode: "6880", gemeente: "Bertrix" },
  { postCode: "6880", gemeente: "Cugnon" },
  { postCode: "6880", gemeente: "Jehonville" },
  { postCode: "6880", gemeente: "Orgeo" },
  { postCode: "6887", gemeente: "Herbeumont" },
  { postCode: "6887", gemeente: "Saint-Médard" },
  { postCode: "6887", gemeente: "Straimont" },
  { postCode: "6890", gemeente: "Anloy" },
  { postCode: "6890", gemeente: "Libin" },
  { postCode: "6890", gemeente: "Ochamps" },
  { postCode: "6890", gemeente: "Redu" },
  { postCode: "6890", gemeente: "Smuid" },
  { postCode: "6890", gemeente: "Transinne" },
  { postCode: "6890", gemeente: "Villance" },
  { postCode: "6900", gemeente: "Aye" },
  { postCode: "6900", gemeente: "Hargimont" },
  { postCode: "6900", gemeente: "Humain" },
  { postCode: "6900", gemeente: "Marche-en-Famenne" },
  { postCode: "6900", gemeente: "On" },
  { postCode: "6900", gemeente: "Roy" },
  { postCode: "6900", gemeente: "Waha" },
  { postCode: "6920", gemeente: "Sohier" },
  { postCode: "6920", gemeente: "Wellin" },
  { postCode: "6921", gemeente: "Chanly" },
  { postCode: "6922", gemeente: "Halma" },
  { postCode: "6924", gemeente: "Lomprez" },
  { postCode: "6927", gemeente: "Bure" },
  { postCode: "6927", gemeente: "Grupont" },
  { postCode: "6927", gemeente: "Resteigne" },
  { postCode: "6927", gemeente: "Tellin" },
  { postCode: "6929", gemeente: "Daverdisse" },
  { postCode: "6929", gemeente: "Gembes" },
  { postCode: "6929", gemeente: "Haut-Fays" },
  { postCode: "6929", gemeente: "Porcheresse" },
  { postCode: "6940", gemeente: "Barvaux-sur-Ourthe" },
  { postCode: "6940", gemeente: "Durbuy" },
  { postCode: "6940", gemeente: "Grandhan" },
  { postCode: "6940", gemeente: "Septon" },
  { postCode: "6940", gemeente: "Wéris" },
  { postCode: "6941", gemeente: "Bende" },
  { postCode: "6941", gemeente: "Bomal-sur-Ourthe" },
  { postCode: "6941", gemeente: "Borlon" },
  { postCode: "6941", gemeente: "Heyd" },
  { postCode: "6941", gemeente: "Izier" },
  { postCode: "6941", gemeente: "Tohogne" },
  { postCode: "6941", gemeente: "Villers-Sainte-Gertrude" },
  { postCode: "6950", gemeente: "Harsin" },
  { postCode: "6950", gemeente: "Nassogne" },
  { postCode: "6951", gemeente: "Bande" },
  { postCode: "6952", gemeente: "Grune" },
  { postCode: "6953", gemeente: "Ambly" },
  { postCode: "6953", gemeente: "Forrières" },
  { postCode: "6953", gemeente: "Lesterny" },
  { postCode: "6953", gemeente: "Masbourg" },
  { postCode: "6960", gemeente: "Dochamps" },
  { postCode: "6960", gemeente: "Grandménil" },
  { postCode: "6960", gemeente: "Harre" },
  { postCode: "6960", gemeente: "Malempré" },
  { postCode: "6960", gemeente: "Odeigne" },
  { postCode: "6960", gemeente: "Vaux-Chavanne" },
  { postCode: "6970", gemeente: "Tenneville" },
  { postCode: "6971", gemeente: "Champlon" },
  { postCode: "6972", gemeente: "Erneuville" },
  { postCode: "6980", gemeente: "Beausaint" },
  { postCode: "6980", gemeente: "La Roche-en-Ardenne" },
  { postCode: "6982", gemeente: "Samrée" },
  { postCode: "6983", gemeente: "Ortho" },
  { postCode: "6984", gemeente: "Hives" },
  { postCode: "6986", gemeente: "Halleux" },
  { postCode: "6987", gemeente: "Beffe" },
  { postCode: "6987", gemeente: "Hodister" },
  { postCode: "6987", gemeente: "Marcourt" },
  { postCode: "6987", gemeente: "Rendeux" },
  { postCode: "6990", gemeente: "Fronville" },
  { postCode: "6990", gemeente: "Hampteau" },
  { postCode: "6990", gemeente: "Hotton" },
  { postCode: "6990", gemeente: "Marenne" },
  { postCode: "6997", gemeente: "Amonines" },
  { postCode: "6997", gemeente: "Erezée" },
  { postCode: "6997", gemeente: "Mormont" },
  { postCode: "6997", gemeente: "Soy" },
  { postCode: "7000", gemeente: "Mons" },
  { postCode: "7010", gemeente: "SHAPE" },
  { postCode: "7011", gemeente: "Ghlin" },
  { postCode: "7012", gemeente: "Flénu" },
  { postCode: "7012", gemeente: "Jemappes" },
  { postCode: "7020", gemeente: "Maisières" },
  { postCode: "7020", gemeente: "Nimy" },
  { postCode: "7021", gemeente: "Havré" },
  { postCode: "7022", gemeente: "Harmignies" },
  { postCode: "7022", gemeente: "Harveng" },
  { postCode: "7022", gemeente: "Hyon" },
  { postCode: "7022", gemeente: "Mesvin" },
  { postCode: "7022", gemeente: "Nouvelles" },
  { postCode: "7024", gemeente: "Ciply" },
  { postCode: "7030", gemeente: "Saint-Symphorien" },
  { postCode: "7031", gemeente: "Villers-Saint-Ghislain" },
  { postCode: "7032", gemeente: "Spiennes" },
  { postCode: "7033", gemeente: "Cuesmes" },
  { postCode: "7034", gemeente: "Obourg" },
  { postCode: "7034", gemeente: "Saint-Denis" },
  { postCode: "7040", gemeente: "Asquillies" },
  { postCode: "7040", gemeente: "Aulnois" },
  { postCode: "7040", gemeente: "Blaregnies" },
  { postCode: "7040", gemeente: "Bougnies" },
  { postCode: "7040", gemeente: "Genly" },
  { postCode: "7040", gemeente: "Goegnies-Chaussée" },
  { postCode: "7040", gemeente: "Quévy-le-Grand" },
  { postCode: "7040", gemeente: "Quévy-le-Petit" },
  { postCode: "7041", gemeente: "Givry" },
  { postCode: "7041", gemeente: "Havay" },
  { postCode: "7050", gemeente: "Erbaut" },
  { postCode: "7050", gemeente: "Erbisoeul" },
  { postCode: "7050", gemeente: "Herchies" },
  { postCode: "7050", gemeente: "Jurbise" },
  { postCode: "7050", gemeente: "Masnuy-Saint-Jean" },
  { postCode: "7050", gemeente: "Masnuy-Saint-Pierre" },
  { postCode: "7060", gemeente: "Horrues" },
  { postCode: "7060", gemeente: "Soignies" },
  { postCode: "7061", gemeente: "Casteau" },
  { postCode: "7061", gemeente: "Thieusies" },
  { postCode: "7062", gemeente: "Naast" },
  { postCode: "7063", gemeente: "Chaussée-Notre-Dame-Louvignies" },
  { postCode: "7063", gemeente: "Neufvilles" },
  { postCode: "7070", gemeente: "Gottignies" },
  { postCode: "7070", gemeente: "Le Roeulx" },
  { postCode: "7070", gemeente: "Mignault" },
  { postCode: "7070", gemeente: "Thieu" },
  { postCode: "7070", gemeente: "Ville-sur-Haine" },
  { postCode: "7080", gemeente: "Eugies" },
  { postCode: "7080", gemeente: "Frameries" },
  { postCode: "7080", gemeente: "La Bouverie" },
  { postCode: "7080", gemeente: "Noirchain" },
  { postCode: "7080", gemeente: "Sars-la-Bruyère" },
  { postCode: "7090", gemeente: "Braine-le-Comte" },
  { postCode: "7090", gemeente: "Hennuyères" },
  { postCode: "7090", gemeente: "Henripont" },
  { postCode: "7090", gemeente: "Petit-Rulx-lez-Braine" },
  { postCode: "7090", gemeente: "Ronquières" },
  { postCode: "7090", gemeente: "Steenkerque" },
  { postCode: "7100", gemeente: "Haine-Saint-Paul" },
  { postCode: "7100", gemeente: "Haine-Saint-Pierre" },
  { postCode: "7100", gemeente: "La Louvière" },
  { postCode: "7100", gemeente: "Saint-Vaast" },
  { postCode: "7100", gemeente: "Trivières" },
  { postCode: "7110", gemeente: "Boussoit" },
  { postCode: "7110", gemeente: "Houdeng-Aimeries" },
  { postCode: "7110", gemeente: "Houdeng-Goegnies" },
  { postCode: "7110", gemeente: "Maurage" },
  { postCode: "7110", gemeente: "Strépy-Bracquegnies" },
  { postCode: "7120", gemeente: "Croix-lez-Rouveroy" },
  { postCode: "7120", gemeente: "Estinnes-au-Mont" },
  { postCode: "7120", gemeente: "Estinnes-au-Val" },
  { postCode: "7120", gemeente: "Fauroeulx" },
  { postCode: "7120", gemeente: "Haulchin" },
  { postCode: "7120", gemeente: "Peissant" },
  { postCode: "7120", gemeente: "Rouveroy" },
  { postCode: "7120", gemeente: "Vellereille-le-Sec" },
  { postCode: "7120", gemeente: "Vellereille-les-Brayeux" },
  { postCode: "7130", gemeente: "Battignies" },
  { postCode: "7130", gemeente: "Binche" },
  { postCode: "7130", gemeente: "Bray" },
  { postCode: "7131", gemeente: "Waudrez" },
  { postCode: "7133", gemeente: "Buvrinnes" },
  { postCode: "7134", gemeente: "Epinois" },
  { postCode: "7134", gemeente: "Leval-Trahegnies" },
  { postCode: "7134", gemeente: "Péronnes-lez-Binche" },
  { postCode: "7134", gemeente: "Ressaix" },
  { postCode: "7140", gemeente: "Morlanwelz-Mariemont" },
  { postCode: "7141", gemeente: "Carnières" },
  { postCode: "7141", gemeente: "Mont-Sainte-Aldegonde" },
  { postCode: "7160", gemeente: "Chapelle-lez-Herlaimont" },
  { postCode: "7160", gemeente: "Godarville" },
  { postCode: "7160", gemeente: "Piéton" },
  { postCode: "7170", gemeente: "Bellecourt" },
  { postCode: "7170", gemeente: "Bois-d'Haine" },
  { postCode: "7170", gemeente: "Fayt-lez-Manage" },
  { postCode: "7170", gemeente: "La Hestre" },
  { postCode: "7170", gemeente: "Manage" },
  { postCode: "7180", gemeente: "Seneffe" },
  { postCode: "7181", gemeente: "Arquennes" },
  { postCode: "7181", gemeente: "Familleureux" },
  { postCode: "7181", gemeente: "Feluy" },
  { postCode: "7181", gemeente: "Petit-Rulx-lez-Nivelles" },
  { postCode: "7190", gemeente: "Écaussinnes-d'Enghien" },
  { postCode: "7190", gemeente: "Marche-lez-Écaussinnes" },
  { postCode: "7191", gemeente: "Ecaussinnes-Lalaing" },
  { postCode: "7300", gemeente: "Boussu" },
  { postCode: "7301", gemeente: "Hornu" },
  { postCode: "7320", gemeente: "Bernissart" },
  { postCode: "7321", gemeente: "Blaton" },
  { postCode: "7321", gemeente: "Harchies" },
  { postCode: "7322", gemeente: "Pommeroeul" },
  { postCode: "7322", gemeente: "Ville-Pommeroeul" },
  { postCode: "7330", gemeente: "Saint-Ghislain" },
  { postCode: "7331", gemeente: "Baudour" },
  { postCode: "7332", gemeente: "Neufmaison" },
  { postCode: "7332", gemeente: "Sirault" },
  { postCode: "7333", gemeente: "Tertre" },
  { postCode: "7334", gemeente: "Hautrage" },
  { postCode: "7334", gemeente: "Villerot" },
  { postCode: "7340", gemeente: "Colfontaine" },
  { postCode: "7340", gemeente: "Pâturages" },
  { postCode: "7340", gemeente: "Warquignies" },
  { postCode: "7340", gemeente: "Wasmes" },
  { postCode: "7350", gemeente: "Hainin" },
  { postCode: "7350", gemeente: "Hensies" },
  { postCode: "7350", gemeente: "Montrul-sur-Haine" },
  { postCode: "7350", gemeente: "Thulin" },
  { postCode: "7370", gemeente: "Blaugies" },
  { postCode: "7370", gemeente: "Dour" },
  { postCode: "7370", gemeente: "Elouges" },
  { postCode: "7370", gemeente: "Wihéries" },
  { postCode: "7380", gemeente: "Baisieux" },
  { postCode: "7380", gemeente: "Quiévrain" },
  { postCode: "7382", gemeente: "Audregnies" },
  { postCode: "7387", gemeente: "Angre" },
  { postCode: "7387", gemeente: "Angreau" },
  { postCode: "7387", gemeente: "Athis" },
  { postCode: "7387", gemeente: "Autreppe" },
  { postCode: "7387", gemeente: "Erquennes" },
  { postCode: "7387", gemeente: "Fayt-le-Franc" },
  { postCode: "7387", gemeente: "Marchipont" },
  { postCode: "7387", gemeente: "Montignies-sur-Roc" },
  { postCode: "7387", gemeente: "Onnezies" },
  { postCode: "7387", gemeente: "Roisin" },
  { postCode: "7390", gemeente: "Quaregnon" },
  { postCode: "7390", gemeente: "Wasmuel" },
  { postCode: "7500", gemeente: "Ere" },
  { postCode: "7500", gemeente: "Saint-Maur" },
  { postCode: "7500", gemeente: "Tournai" },
  { postCode: "7501", gemeente: "Orcq" },
  { postCode: "7502", gemeente: "Esplechin" },
  { postCode: "7503", gemeente: "Froyennes" },
  { postCode: "7504", gemeente: "Froidmont" },
  { postCode: "7506", gemeente: "Willemeau" },
  { postCode: "7510", gemeente: "3 Suisses" },
  { postCode: "7511", gemeente: "Vitrine Magique" },
  { postCode: "7512", gemeente: "DANIEL JOUVANCE" },
  { postCode: "7513", gemeente: "Yves Rocher" },
  { postCode: "7520", gemeente: "Ramegnies-Chin" },
  { postCode: "7520", gemeente: "Templeuve" },
  { postCode: "7521", gemeente: "Chercq" },
  { postCode: "7522", gemeente: "Blandain" },
  { postCode: "7522", gemeente: "Hertain" },
  { postCode: "7522", gemeente: "Lamain" },
  { postCode: "7522", gemeente: "Marquain" },
  { postCode: "7530", gemeente: "Gaurain-Ramecroix" },
  { postCode: "7531", gemeente: "Havinnes" },
  { postCode: "7532", gemeente: "Béclers" },
  { postCode: "7533", gemeente: "Thimougies" },
  { postCode: "7534", gemeente: "Barry" },
  { postCode: "7534", gemeente: "Maulde" },
  { postCode: "7536", gemeente: "Vaulx" },
  { postCode: "7538", gemeente: "Vezon" },
  { postCode: "7540", gemeente: "Kain" },
  { postCode: "7540", gemeente: "Melles" },
  { postCode: "7540", gemeente: "Quartes" },
  { postCode: "7540", gemeente: "Rumillies" },
  { postCode: "7542", gemeente: "Mont-Saint-Aubert" },
  { postCode: "7543", gemeente: "Mourcourt" },
  { postCode: "7548", gemeente: "Warchin" },
  { postCode: "7600", gemeente: "Péruwelz" },
  { postCode: "7601", gemeente: "Roucourt" },
  { postCode: "7602", gemeente: "Bury" },
  { postCode: "7603", gemeente: "Bon-Secours" },
  { postCode: "7604", gemeente: "Baugnies" },
  { postCode: "7604", gemeente: "Braffe" },
  { postCode: "7604", gemeente: "Brasménil" },
  { postCode: "7604", gemeente: "Callenelle" },
  { postCode: "7604", gemeente: "Wasmes-Audemez-Briffoeil" },
  { postCode: "7608", gemeente: "Wiers" },
  { postCode: "7610", gemeente: "Rumes" },
  { postCode: "7611", gemeente: "La Glanerie" },
  { postCode: "7618", gemeente: "Taintignies" },
  { postCode: "7620", gemeente: "Bléharies" },
  { postCode: "7620", gemeente: "Guignies" },
  { postCode: "7620", gemeente: "Hollain" },
  { postCode: "7620", gemeente: "Jollain-Merlin" },
  { postCode: "7620", gemeente: "Wez-Velvain" },
  { postCode: "7621", gemeente: "Lesdain" },
  { postCode: "7622", gemeente: "Laplaigne" },
  { postCode: "7623", gemeente: "Rongy" },
  { postCode: "7624", gemeente: "Howardries" },
  { postCode: "7640", gemeente: "Antoing" },
  { postCode: "7640", gemeente: "Maubray" },
  { postCode: "7640", gemeente: "Péronnes-lez-Antoing" },
  { postCode: "7641", gemeente: "Bruyelle" },
  { postCode: "7642", gemeente: "Calonne" },
  { postCode: "7643", gemeente: "Fontenoy" },
  { postCode: "7700", gemeente: "Luingne" },
  { postCode: "7700", gemeente: "Moeskroen" },
  { postCode: "7711", gemeente: "Dottenijs" },
  { postCode: "7712", gemeente: "Herseaux" },
  { postCode: "7730", gemeente: "Bailleul" },
  { postCode: "7730", gemeente: "Estaimbourg" },
  { postCode: "7730", gemeente: "Estaimpuis" },
  { postCode: "7730", gemeente: "Evregnies" },
  { postCode: "7730", gemeente: "Leers-Nord" },
  { postCode: "7730", gemeente: "Néchin" },
  { postCode: "7730", gemeente: "Saint-Léger" },
  { postCode: "7740", gemeente: "Pecq" },
  { postCode: "7740", gemeente: "Warcoing" },
  { postCode: "7742", gemeente: "Hérinnes-lez-Pecq" },
  { postCode: "7743", gemeente: "Esquelmes" },
  { postCode: "7743", gemeente: "Obigies" },
  { postCode: "7750", gemeente: "Amougies" },
  { postCode: "7750", gemeente: "Anseroeul" },
  { postCode: "7750", gemeente: "Orroir" },
  { postCode: "7750", gemeente: "Russeignies" },
  { postCode: "7760", gemeente: "Celles" },
  { postCode: "7760", gemeente: "Escanaffles" },
  { postCode: "7760", gemeente: "Molenbaix" },
  { postCode: "7760", gemeente: "Popuelles" },
  { postCode: "7760", gemeente: "Pottes" },
  { postCode: "7760", gemeente: "Velaines" },
  { postCode: "7780", gemeente: "Komen" },
  { postCode: "7780", gemeente: "Komen-Waasten" },
  { postCode: "7781", gemeente: "Houthem" },
  { postCode: "7782", gemeente: "Ploegsteert" },
  { postCode: "7783", gemeente: "Bizet" },
  { postCode: "7784", gemeente: "Neerwaasten" },
  { postCode: "7784", gemeente: "Waasten" },
  { postCode: "7800", gemeente: "Ath" },
  { postCode: "7800", gemeente: "Lanquesaint" },
  { postCode: "7801", gemeente: "Irchonwelz" },
  { postCode: "7802", gemeente: "Ormeignies" },
  { postCode: "7803", gemeente: "Bouvignies" },
  { postCode: "7804", gemeente: "Ostiches" },
  { postCode: "7804", gemeente: "Rebaix" },
  { postCode: "7810", gemeente: "Maffle" },
  { postCode: "7811", gemeente: "Arbre" },
  { postCode: "7812", gemeente: "Houtaing" },
  { postCode: "7812", gemeente: "Ligne" },
  { postCode: "7812", gemeente: "Mainvault" },
  { postCode: "7812", gemeente: "Moulbaix" },
  { postCode: "7812", gemeente: "Villers-Notre-Dame" },
  { postCode: "7812", gemeente: "Villers-Saint-Amand" },
  { postCode: "7822", gemeente: "Ghislenghien" },
  { postCode: "7822", gemeente: "Isières" },
  { postCode: "7822", gemeente: "Meslin-l'Évêque" },
  { postCode: "7823", gemeente: "Gibecq" },
  { postCode: "7830", gemeente: "Bassilly" },
  { postCode: "7830", gemeente: "Fouleng" },
  { postCode: "7830", gemeente: "Gondregnies" },
  { postCode: "7830", gemeente: "Graty" },
  { postCode: "7830", gemeente: "Hellebecq" },
  { postCode: "7830", gemeente: "Hoves" },
  { postCode: "7830", gemeente: "Silly" },
  { postCode: "7830", gemeente: "Thoricourt" },
  { postCode: "7850", gemeente: "Edingen" },
  { postCode: "7850", gemeente: "Lettelingen" },
  { postCode: "7850", gemeente: "Mark" },
  { postCode: "7860", gemeente: "Lessines" },
  { postCode: "7861", gemeente: "Papignies" },
  { postCode: "7861", gemeente: "Wannebecq" },
  { postCode: "7862", gemeente: "Ogy" },
  { postCode: "7863", gemeente: "Ghoy" },
  { postCode: "7864", gemeente: "Deux-Acren" },
  { postCode: "7866", gemeente: "Bois-de-Lessines" },
  { postCode: "7866", gemeente: "Ollignies" },
  { postCode: "7870", gemeente: "Bauffe" },
  { postCode: "7870", gemeente: "Cambron-Saint-Vincent" },
  { postCode: "7870", gemeente: "Lens" },
  { postCode: "7870", gemeente: "Lombise" },
  { postCode: "7870", gemeente: "Montignies-lez-Lens" },
  { postCode: "7880", gemeente: "Vloesberg" },
  { postCode: "7890", gemeente: "Ellezelles" },
  { postCode: "7890", gemeente: "Lahamaide" },
  { postCode: "7890", gemeente: "Wodecq" },
  { postCode: "7900", gemeente: "Grandmetz" },
  { postCode: "7900", gemeente: "Leuze-en-Hainaut" },
  { postCode: "7901", gemeente: "Thieulain" },
  { postCode: "7903", gemeente: "Blicquy" },
  { postCode: "7903", gemeente: "Chapelle-à-Oie" },
  { postCode: "7903", gemeente: "Chapelle-à-Wattines" },
  { postCode: "7904", gemeente: "Pipaix" },
  { postCode: "7904", gemeente: "Tourpes" },
  { postCode: "7904", gemeente: "Willaupuis" },
  { postCode: "7906", gemeente: "Gallaix" },
  { postCode: "7910", gemeente: "Anvaing" },
  { postCode: "7910", gemeente: "Arc-Ainières" },
  { postCode: "7910", gemeente: "Arc-Wattripont" },
  { postCode: "7910", gemeente: "Cordes" },
  { postCode: "7910", gemeente: "Ellignies-lez-Frasnes" },
  { postCode: "7910", gemeente: "Forest" },
  { postCode: "7910", gemeente: "Wattripont" },
  { postCode: "7911", gemeente: "Buissenal" },
  { postCode: "7911", gemeente: "Frasnes-lez-Buissenal" },
  { postCode: "7911", gemeente: "Hacquegnies" },
  { postCode: "7911", gemeente: "Herquegies" },
  { postCode: "7911", gemeente: "Montrul-au-Bois" },
  { postCode: "7911", gemeente: "Moustier" },
  { postCode: "7911", gemeente: "Oeudeghien" },
  { postCode: "7912", gemeente: "Dergneau" },
  { postCode: "7912", gemeente: "Saint-Sauveur" },
  { postCode: "7940", gemeente: "Brugelette" },
  { postCode: "7940", gemeente: "Cambron-Casteau" },
  { postCode: "7941", gemeente: "Attre" },
  { postCode: "7942", gemeente: "Mévergnies-lez-Lens" },
  { postCode: "7943", gemeente: "Gages" },
  { postCode: "7950", gemeente: "Chièvres" },
  { postCode: "7950", gemeente: "Grosage" },
  { postCode: "7950", gemeente: "Huissignies" },
  { postCode: "7950", gemeente: "Ladeuze" },
  { postCode: "7950", gemeente: "Tongre-Saint-Martin" },
  { postCode: "7951", gemeente: "Tongre-Notre-Dame" },
  { postCode: "7970", gemeente: "Beloeil" },
  { postCode: "7971", gemeente: "Basècles" },
  { postCode: "7971", gemeente: "Ramegnies" },
  { postCode: "7971", gemeente: "Thumaide" },
  { postCode: "7971", gemeente: "Wadelincourt" },
  { postCode: "7972", gemeente: "Aubechies" },
  { postCode: "7972", gemeente: "Ellignies-Sainte-Anne" },
  { postCode: "7972", gemeente: "Quevaucamps" },
  { postCode: "7973", gemeente: "Grandglise" },
  { postCode: "7973", gemeente: "Stambruges" },
  { postCode: "8000", gemeente: "Brugge" },
  { postCode: "8000", gemeente: "Koolkerke" },
  { postCode: "8020", gemeente: "Hertsberge" },
  { postCode: "8020", gemeente: "Oostkamp" },
  { postCode: "8020", gemeente: "Ruddervoorde" },
  { postCode: "8020", gemeente: "Waardamme" },
  { postCode: "8200", gemeente: "Sint-Andries" },
  { postCode: "8200", gemeente: "Sint-Michiels" },
  { postCode: "8210", gemeente: "Loppem" },
  { postCode: "8210", gemeente: "Veldegem" },
  { postCode: "8210", gemeente: "Zedelgem" },
  { postCode: "8211", gemeente: "Aartrijke" },
  { postCode: "8300", gemeente: "Knokke" },
  { postCode: "8300", gemeente: "Westkapelle" },
  { postCode: "8301", gemeente: "Heist-aan-Zee" },
  { postCode: "8301", gemeente: "Ramskapelle" },
  { postCode: "8310", gemeente: "Assebroek" },
  { postCode: "8310", gemeente: "Sint-Kruis" },
  { postCode: "8340", gemeente: "Damme" },
  { postCode: "8340", gemeente: "Hoeke" },
  { postCode: "8340", gemeente: "Lapscheure" },
  { postCode: "8340", gemeente: "Moerkerke" },
  { postCode: "8340", gemeente: "Oostkerke" },
  { postCode: "8340", gemeente: "Sijsele" },
  { postCode: "8370", gemeente: "Blankenberge" },
  { postCode: "8370", gemeente: "Uitkerke" },
  { postCode: "8377", gemeente: "Houtave" },
  { postCode: "8377", gemeente: "Meetkerke" },
  { postCode: "8377", gemeente: "Nieuwmunster" },
  { postCode: "8377", gemeente: "Zuienkerke" },
  { postCode: "8380", gemeente: "Dudzele" },
  { postCode: "8380", gemeente: "Lissewege" },
  { postCode: "8380", gemeente: "Zeebrugge" },
  { postCode: "8400", gemeente: "Oostende" },
  { postCode: "8400", gemeente: "Stene" },
  { postCode: "8400", gemeente: "Zandvoorde" },
  { postCode: "8420", gemeente: "De Haan" },
  { postCode: "8420", gemeente: "Klemskerke" },
  { postCode: "8420", gemeente: "Wenduine" },
  { postCode: "8421", gemeente: "Vlissegem" },
  { postCode: "8430", gemeente: "Middelkerke" },
  { postCode: "8431", gemeente: "Wilskerke" },
  { postCode: "8432", gemeente: "Leffinge" },
  { postCode: "8433", gemeente: "Mannekensvere" },
  { postCode: "8433", gemeente: "Schore" },
  { postCode: "8433", gemeente: "Sint-Pieters-Kapelle" },
  { postCode: "8433", gemeente: "Slijpe" },
  { postCode: "8434", gemeente: "Lombardsijde" },
  { postCode: "8434", gemeente: "Westende" },
  { postCode: "8450", gemeente: "Bredene" },
  { postCode: "8460", gemeente: "Ettelgem" },
  { postCode: "8460", gemeente: "Oudenburg" },
  { postCode: "8460", gemeente: "Roksem" },
  { postCode: "8460", gemeente: "Westkerke" },
  { postCode: "8470", gemeente: "Gistel" },
  { postCode: "8470", gemeente: "Moere" },
  { postCode: "8470", gemeente: "Snaaskerke" },
  { postCode: "8470", gemeente: "Zevekote" },
  { postCode: "8480", gemeente: "Bekegem" },
  { postCode: "8480", gemeente: "Eernegem" },
  { postCode: "8480", gemeente: "Ichtegem" },
  { postCode: "8490", gemeente: "Jabbeke" },
  { postCode: "8490", gemeente: "Snellegem" },
  { postCode: "8490", gemeente: "Stalhille" },
  { postCode: "8490", gemeente: "Varsenare" },
  { postCode: "8490", gemeente: "Zerkegem" },
  { postCode: "8500", gemeente: "Kortrijk" },
  { postCode: "8501", gemeente: "Bissegem" },
  { postCode: "8501", gemeente: "Heule" },
  { postCode: "8510", gemeente: "Bellegem" },
  { postCode: "8510", gemeente: "Kooigem" },
  { postCode: "8510", gemeente: "Marke" },
  { postCode: "8510", gemeente: "Rollegem" },
  { postCode: "8511", gemeente: "Aalbeke" },
  { postCode: "8520", gemeente: "Kuurne" },
  { postCode: "8530", gemeente: "Harelbeke" },
  { postCode: "8531", gemeente: "Bavikhove" },
  { postCode: "8531", gemeente: "Hulste" },
  { postCode: "8540", gemeente: "Deerlijk" },
  { postCode: "8550", gemeente: "Zwevegem" },
  { postCode: "8551", gemeente: "Heestert" },
  { postCode: "8552", gemeente: "Moen" },
  { postCode: "8553", gemeente: "Otegem" },
  { postCode: "8554", gemeente: "Sint-Denijs" },
  { postCode: "8560", gemeente: "Gullegem" },
  { postCode: "8560", gemeente: "Moorsele" },
  { postCode: "8560", gemeente: "Wevelgem" },
  { postCode: "8570", gemeente: "Anzegem" },
  { postCode: "8570", gemeente: "Gijzelbrechtegem" },
  { postCode: "8570", gemeente: "Ingooigem" },
  { postCode: "8570", gemeente: "Vichte" },
  { postCode: "8572", gemeente: "Kaster" },
  { postCode: "8573", gemeente: "Tiegem" },
  { postCode: "8580", gemeente: "Avelgem" },
  { postCode: "8581", gemeente: "Kerkhove" },
  { postCode: "8581", gemeente: "Waarmaarde" },
  { postCode: "8582", gemeente: "Outrijve" },
  { postCode: "8583", gemeente: "Bossuit" },
  { postCode: "8587", gemeente: "Helkijn" },
  { postCode: "8587", gemeente: "Spiere" },
  { postCode: "8600", gemeente: "Beerst" },
  { postCode: "8600", gemeente: "Diksmuide" },
  { postCode: "8600", gemeente: "Driekapellen" },
  { postCode: "8600", gemeente: "Esen" },
  { postCode: "8600", gemeente: "Kaaskerke" },
  { postCode: "8600", gemeente: "Keiem" },
  { postCode: "8600", gemeente: "Lampernisse" },
  { postCode: "8600", gemeente: "Leke" },
  { postCode: "8600", gemeente: "Nieuwkapelle" },
  { postCode: "8600", gemeente: "Oostkerke" },
  { postCode: "8600", gemeente: "Oudekapelle" },
  { postCode: "8600", gemeente: "Pervijze" },
  { postCode: "8600", gemeente: "Sint-Jacobs-Kapelle" },
  { postCode: "8600", gemeente: "Stuivekenskerke" },
  { postCode: "8600", gemeente: "Vladslo" },
  { postCode: "8600", gemeente: "Woumen" },
  { postCode: "8610", gemeente: "Handzame" },
  { postCode: "8610", gemeente: "Kortemark" },
  { postCode: "8610", gemeente: "Werken" },
  { postCode: "8610", gemeente: "Zarren" },
  { postCode: "8620", gemeente: "Nieuwpoort" },
  { postCode: "8620", gemeente: "Ramskapelle" },
  { postCode: "8620", gemeente: "Sint-Joris" },
  { postCode: "8630", gemeente: "Avekapelle" },
  { postCode: "8630", gemeente: "Booitshoeke" },
  { postCode: "8630", gemeente: "Bulskamp" },
  { postCode: "8630", gemeente: "De Moeren" },
  { postCode: "8630", gemeente: "Eggewaartskapelle" },
  { postCode: "8630", gemeente: "Houtem" },
  { postCode: "8630", gemeente: "Steenkerke" },
  { postCode: "8630", gemeente: "Veurne" },
  { postCode: "8630", gemeente: "Vinkem" },
  { postCode: "8630", gemeente: "Wulveringem" },
  { postCode: "8630", gemeente: "Zoutenaaie" },
  { postCode: "8640", gemeente: "Oostvleteren" },
  { postCode: "8640", gemeente: "Westvleteren" },
  { postCode: "8640", gemeente: "Woesten" },
  { postCode: "8647", gemeente: "Lo" },
  { postCode: "8647", gemeente: "Noordschote" },
  { postCode: "8647", gemeente: "Pollinkhove" },
  { postCode: "8647", gemeente: "Reninge" },
  { postCode: "8650", gemeente: "Houthulst" },
  { postCode: "8650", gemeente: "Klerken" },
  { postCode: "8650", gemeente: "Merkem" },
  { postCode: "8660", gemeente: "Adinkerke" },
  { postCode: "8660", gemeente: "De Panne" },
  { postCode: "8670", gemeente: "Koksijde" },
  { postCode: "8670", gemeente: "Oostduinkerke" },
  { postCode: "8670", gemeente: "Wulpen" },
  { postCode: "8680", gemeente: "Bovekerke" },
  { postCode: "8680", gemeente: "Koekelare" },
  { postCode: "8680", gemeente: "Zande" },
  { postCode: "8690", gemeente: "Alveringem" },
  { postCode: "8690", gemeente: "Hoogstade" },
  { postCode: "8690", gemeente: "Oeren" },
  { postCode: "8690", gemeente: "Sint-Rijkers" },
  { postCode: "8691", gemeente: "Beveren-aan-de-IJzer" },
  { postCode: "8691", gemeente: "Gijverinkhove" },
  { postCode: "8691", gemeente: "Izenberge" },
  { postCode: "8691", gemeente: "Leisele" },
  { postCode: "8691", gemeente: "Stavele" },
  { postCode: "8700", gemeente: "Aarsele" },
  { postCode: "8700", gemeente: "Kanegem" },
  { postCode: "8700", gemeente: "Schuiferskapelle" },
  { postCode: "8700", gemeente: "Tielt" },
  { postCode: "8710", gemeente: "Ooigem" },
  { postCode: "8710", gemeente: "Sint-Baafs-Vijve" },
  { postCode: "8710", gemeente: "Wielsbeke" },
  { postCode: "8720", gemeente: "Dentergem" },
  { postCode: "8720", gemeente: "Markegem" },
  { postCode: "8720", gemeente: "Oeselgem" },
  { postCode: "8720", gemeente: "Wakken" },
  { postCode: "8730", gemeente: "Beernem" },
  { postCode: "8730", gemeente: "Oedelem" },
  { postCode: "8730", gemeente: "Sint-Joris" },
  { postCode: "8740", gemeente: "Egem" },
  { postCode: "8740", gemeente: "Pittem" },
  { postCode: "8750", gemeente: "Wingene" },
  { postCode: "8750", gemeente: "Zwevezele" },
  { postCode: "8755", gemeente: "Ruiselede" },
  { postCode: "8760", gemeente: "Meulebeke" },
  { postCode: "8770", gemeente: "Ingelmunster" },
  { postCode: "8780", gemeente: "Oostrozebeke" },
  { postCode: "8790", gemeente: "Waregem" },
  { postCode: "8791", gemeente: "Beveren" },
  { postCode: "8792", gemeente: "Desselgem" },
  { postCode: "8793", gemeente: "Sint-Eloois-Vijve" },
  { postCode: "8800", gemeente: "Beveren" },
  { postCode: "8800", gemeente: "Oekene" },
  { postCode: "8800", gemeente: "Roeselare" },
  { postCode: "8800", gemeente: "Rumbeke" },
  { postCode: "8810", gemeente: "Lichtervelde" },
  { postCode: "8820", gemeente: "Torhout" },
  { postCode: "8830", gemeente: "Gits" },
  { postCode: "8830", gemeente: "Hooglede" },
  { postCode: "8840", gemeente: "Oostnieuwkerke" },
  { postCode: "8840", gemeente: "Staden" },
  { postCode: "8840", gemeente: "Westrozebeke" },
  { postCode: "8850", gemeente: "Ardooie" },
  { postCode: "8851", gemeente: "Koolskamp" },
  { postCode: "8860", gemeente: "Lendelede" },
  { postCode: "8870", gemeente: "Emelgem" },
  { postCode: "8870", gemeente: "Izegem" },
  { postCode: "8870", gemeente: "Kachtem" },
  { postCode: "8880", gemeente: "Ledegem" },
  { postCode: "8880", gemeente: "Rollegem-Kapelle" },
  { postCode: "8880", gemeente: "Sint-Eloois-Winkel" },
  { postCode: "8890", gemeente: "Dadizele" },
  { postCode: "8890", gemeente: "Moorslede" },
  { postCode: "8900", gemeente: "Brielen" },
  { postCode: "8900", gemeente: "Dikkebus" },
  { postCode: "8900", gemeente: "Ieper" },
  { postCode: "8900", gemeente: "Sint-Jan" },
  { postCode: "8902", gemeente: "Hollebeke" },
  { postCode: "8902", gemeente: "Voormezele" },
  { postCode: "8902", gemeente: "Zillebeke" },
  { postCode: "8904", gemeente: "Boezinge" },
  { postCode: "8904", gemeente: "Zuidschote" },
  { postCode: "8906", gemeente: "Elverdinge" },
  { postCode: "8908", gemeente: "Vlamertinge" },
  { postCode: "8920", gemeente: "Bikschote" },
  { postCode: "8920", gemeente: "Langemark" },
  { postCode: "8920", gemeente: "Poelkapelle" },
  { postCode: "8930", gemeente: "Lauwe" },
  { postCode: "8930", gemeente: "Menen" },
  { postCode: "8930", gemeente: "Rekkem" },
  { postCode: "8940", gemeente: "Geluwe" },
  { postCode: "8940", gemeente: "Wervik" },
  { postCode: "8950", gemeente: "Nieuwkerke" },
  { postCode: "8951", gemeente: "Dranouter" },
  { postCode: "8952", gemeente: "Wulvergem" },
  { postCode: "8953", gemeente: "Wijtschate" },
  { postCode: "8954", gemeente: "Westouter" },
  { postCode: "8956", gemeente: "Kemmel" },
  { postCode: "8957", gemeente: "Mesen" },
  { postCode: "8958", gemeente: "Loker" },
  { postCode: "8970", gemeente: "Poperinge" },
  { postCode: "8970", gemeente: "Reningelst" },
  { postCode: "8972", gemeente: "Krombeke" },
  { postCode: "8972", gemeente: "Proven" },
  { postCode: "8972", gemeente: "Roesbrugge-Haringe" },
  { postCode: "8978", gemeente: "Watou" },
  { postCode: "8980", gemeente: "Beselare" },
  { postCode: "8980", gemeente: "Geluveld" },
  { postCode: "8980", gemeente: "Passendale" },
  { postCode: "8980", gemeente: "Zandvoorde" },
  { postCode: "8980", gemeente: "Zonnebeke" },
  { postCode: "9000", gemeente: "Gent" },
  { postCode: "9030", gemeente: "Mariakerke" },
  { postCode: "9031", gemeente: "Drongen" },
  { postCode: "9032", gemeente: "Wondelgem" },
  { postCode: "9040", gemeente: "Sint-Amandsberg" },
  { postCode: "9041", gemeente: "Oostakker" },
  { postCode: "9042", gemeente: "Desteldonk" },
  { postCode: "9042", gemeente: "Mendonk" },
  { postCode: "9042", gemeente: "Sint-Kruis-Winkel" },
  { postCode: "9050", gemeente: "Gentbrugge" },
  { postCode: "9050", gemeente: "Ledeberg" },
  { postCode: "9051", gemeente: "Afsnee" },
  { postCode: "9051", gemeente: "Sint-Denijs-Westrem" },
  { postCode: "9052", gemeente: "Zwijnaarde" },
  { postCode: "9060", gemeente: "Zelzate" },
  { postCode: "9070", gemeente: "Destelbergen" },
  { postCode: "9070", gemeente: "Heusden" },
  { postCode: "9075", gemeente: "CSM Gent X" },
  { postCode: "9080", gemeente: "Beervelde" },
  { postCode: "9080", gemeente: "Lochristi" },
  { postCode: "9080", gemeente: "Zaffelare" },
  { postCode: "9080", gemeente: "Zeveneken" },
  { postCode: "9090", gemeente: "Gontrode" },
  { postCode: "9090", gemeente: "Melle" },
  { postCode: "9099", gemeente: "Gent X" },
  { postCode: "9100", gemeente: "Nieuwkerken-Waas" },
  { postCode: "9100", gemeente: "Sint-Niklaas" },
  { postCode: "9111", gemeente: "Belsele" },
  { postCode: "9112", gemeente: "Sinaai-Waas" },
  { postCode: "9120", gemeente: "Beveren-Waas" },
  { postCode: "9120", gemeente: "Haasdonk" },
  { postCode: "9120", gemeente: "Kallo" },
  { postCode: "9120", gemeente: "Melsele" },
  { postCode: "9120", gemeente: "Vrasene" },
  { postCode: "9130", gemeente: "Doel" },
  { postCode: "9130", gemeente: "Kallo" },
  { postCode: "9130", gemeente: "Kieldrecht" },
  { postCode: "9130", gemeente: "Verrebroek" },
  { postCode: "9140", gemeente: "Elversele" },
  { postCode: "9140", gemeente: "Steendorp" },
  { postCode: "9140", gemeente: "Temse" },
  { postCode: "9140", gemeente: "Tielrode" },
  { postCode: "9150", gemeente: "Bazel" },
  { postCode: "9150", gemeente: "Kruibeke" },
  { postCode: "9150", gemeente: "Rupelmonde" },
  { postCode: "9160", gemeente: "Daknam" },
  { postCode: "9160", gemeente: "Eksaarde" },
  { postCode: "9160", gemeente: "Lokeren" },
  { postCode: "9170", gemeente: "De Klinge" },
  { postCode: "9170", gemeente: "Meerdonk" },
  { postCode: "9170", gemeente: "Sint-Gillis-Waas" },
  { postCode: "9170", gemeente: "Sint-Pauwels" },
  { postCode: "9180", gemeente: "Moerbeke-Waas" },
  { postCode: "9185", gemeente: "Wachtebeke" },
  { postCode: "9190", gemeente: "Kemzeke" },
  { postCode: "9190", gemeente: "Stekene" },
  { postCode: "9200", gemeente: "Appels" },
  { postCode: "9200", gemeente: "Baasrode" },
  { postCode: "9200", gemeente: "Dendermonde" },
  { postCode: "9200", gemeente: "Grembergen" },
  { postCode: "9200", gemeente: "Mespelare" },
  { postCode: "9200", gemeente: "Oudegem" },
  { postCode: "9200", gemeente: "Schoonaarde" },
  { postCode: "9200", gemeente: "Sint-Gillis-Dendermonde" },
  { postCode: "9220", gemeente: "Hamme" },
  { postCode: "9220", gemeente: "Moerzeke" },
  { postCode: "9230", gemeente: "Massemen" },
  { postCode: "9230", gemeente: "Westrem" },
  { postCode: "9230", gemeente: "Wetteren" },
  { postCode: "9240", gemeente: "Zele" },
  { postCode: "9250", gemeente: "Waasmunster" },
  { postCode: "9255", gemeente: "Buggenhout" },
  { postCode: "9255", gemeente: "Opdorp" },
  { postCode: "9260", gemeente: "Schellebelle" },
  { postCode: "9260", gemeente: "Serskamp" },
  { postCode: "9260", gemeente: "Wichelen" },
  { postCode: "9270", gemeente: "Kalken" },
  { postCode: "9270", gemeente: "Laarne" },
  { postCode: "9280", gemeente: "Denderbelle" },
  { postCode: "9280", gemeente: "Lebbeke" },
  { postCode: "9280", gemeente: "Wieze" },
  { postCode: "9290", gemeente: "Berlare" },
  { postCode: "9290", gemeente: "Overmere" },
  { postCode: "9290", gemeente: "Uitbergen" },
  { postCode: "9300", gemeente: "Aalst" },
  { postCode: "9308", gemeente: "Gijzegem" },
  { postCode: "9308", gemeente: "Hofstade" },
  { postCode: "9310", gemeente: "Baardegem" },
  { postCode: "9310", gemeente: "Herdersem" },
  { postCode: "9310", gemeente: "Meldert" },
  { postCode: "9310", gemeente: "Moorsel" },
  { postCode: "9320", gemeente: "Erembodegem" },
  { postCode: "9320", gemeente: "Nieuwerkerken" },
  { postCode: "9340", gemeente: "Impe" },
  { postCode: "9340", gemeente: "Lede" },
  { postCode: "9340", gemeente: "Oordegem" },
  { postCode: "9340", gemeente: "Smetlede" },
  { postCode: "9340", gemeente: "Wanzele" },
  { postCode: "9400", gemeente: "Appelterre-Eichem" },
  { postCode: "9400", gemeente: "Denderwindeke" },
  { postCode: "9400", gemeente: "Lieferinge" },
  { postCode: "9400", gemeente: "Nederhasselt" },
  { postCode: "9400", gemeente: "Ninove" },
  { postCode: "9400", gemeente: "Okegem" },
  { postCode: "9400", gemeente: "Voorde" },
  { postCode: "9401", gemeente: "Pollare" },
  { postCode: "9402", gemeente: "Meerbeke" },
  { postCode: "9403", gemeente: "Neigem" },
  { postCode: "9404", gemeente: "Aspelare" },
  { postCode: "9406", gemeente: "Outer" },
  { postCode: "9420", gemeente: "Aaigem" },
  { postCode: "9420", gemeente: "Bambrugge" },
  { postCode: "9420", gemeente: "Burst" },
  { postCode: "9420", gemeente: "Erondegem" },
  { postCode: "9420", gemeente: "Erpe" },
  { postCode: "9420", gemeente: "Mere" },
  { postCode: "9420", gemeente: "Ottergem" },
  { postCode: "9420", gemeente: "Vlekkem" },
  { postCode: "9450", gemeente: "Denderhoutem" },
  { postCode: "9450", gemeente: "Haaltert" },
  { postCode: "9450", gemeente: "Heldergem" },
  { postCode: "9451", gemeente: "Kerksken" },
  { postCode: "9470", gemeente: "Denderleeuw" },
  { postCode: "9472", gemeente: "Iddergem" },
  { postCode: "9473", gemeente: "Welle" },
  { postCode: "9500", gemeente: "Geraardsbergen" },
  { postCode: "9500", gemeente: "Goeferdinge" },
  { postCode: "9500", gemeente: "Moerbeke" },
  { postCode: "9500", gemeente: "Nederboelare" },
  { postCode: "9500", gemeente: "Onkerzele" },
  { postCode: "9500", gemeente: "Ophasselt" },
  { postCode: "9500", gemeente: "Overboelare" },
  { postCode: "9500", gemeente: "Viane" },
  { postCode: "9500", gemeente: "Zarlardinge" },
  { postCode: "9506", gemeente: "Grimminge" },
  { postCode: "9506", gemeente: "Idegem" },
  { postCode: "9506", gemeente: "Nieuwenhove" },
  { postCode: "9506", gemeente: "Schendelbeke" },
  { postCode: "9506", gemeente: "Smeerebbe-Vloerzegem" },
  { postCode: "9506", gemeente: "Waarbeke" },
  { postCode: "9506", gemeente: "Zandbergen" },
  { postCode: "9520", gemeente: "Bavegem" },
  { postCode: "9520", gemeente: "Oombergen" },
  { postCode: "9520", gemeente: "Sint-Lievens-Houtem" },
  { postCode: "9520", gemeente: "Vlierzele" },
  { postCode: "9520", gemeente: "Zonnegem" },
  { postCode: "9521", gemeente: "Letterhoutem" },
  { postCode: "9550", gemeente: "Herzele" },
  { postCode: "9550", gemeente: "Hillegem" },
  { postCode: "9550", gemeente: "Sint-Antelinks" },
  { postCode: "9550", gemeente: "Sint-Lievens-Esse" },
  { postCode: "9550", gemeente: "Steenhuize-Wijnhuize" },
  { postCode: "9550", gemeente: "Woubrechtegem" },
  { postCode: "9551", gemeente: "Ressegem" },
  { postCode: "9552", gemeente: "Borsbeke" },
  { postCode: "9570", gemeente: "Deftinge" },
  { postCode: "9570", gemeente: "Sint-Maria-Lierde" },
  { postCode: "9571", gemeente: "Hemelveerdegem" },
  { postCode: "9572", gemeente: "Sint-Martens-Lierde" },
  { postCode: "9600", gemeente: "Ronse" },
  { postCode: "9620", gemeente: "Elene" },
  { postCode: "9620", gemeente: "Erwetegem" },
  { postCode: "9620", gemeente: "Godveerdegem" },
  { postCode: "9620", gemeente: "Grotenberge" },
  { postCode: "9620", gemeente: "Leeuwergem" },
  { postCode: "9620", gemeente: "Oombergen" },
  { postCode: "9620", gemeente: "Sint-Goriks-Oudenhove" },
  { postCode: "9620", gemeente: "Sint-Maria-Oudenhove" },
  { postCode: "9620", gemeente: "Strijpen" },
  { postCode: "9620", gemeente: "Velzeke-Ruddershove" },
  { postCode: "9620", gemeente: "Zottegem" },
  { postCode: "9630", gemeente: "Beerlegem" },
  { postCode: "9630", gemeente: "Dikkele" },
  { postCode: "9630", gemeente: "Hundelgem" },
  { postCode: "9630", gemeente: "Meilegem" },
  { postCode: "9630", gemeente: "Munkzwalm" },
  { postCode: "9630", gemeente: "Paulatem" },
  { postCode: "9630", gemeente: "Roborst" },
  { postCode: "9630", gemeente: "Rozebeke" },
  { postCode: "9630", gemeente: "Sint-Blasius-Boekel" },
  { postCode: "9630", gemeente: "Sint-Denijs-Boekel" },
  { postCode: "9630", gemeente: "Sint-Maria-Latem" },
  { postCode: "9636", gemeente: "Nederzwalm-Hermelgem" },
  { postCode: "9660", gemeente: "Brakel" },
  { postCode: "9660", gemeente: "Elst" },
  { postCode: "9660", gemeente: "Everbeek" },
  { postCode: "9660", gemeente: "Michelbeke" },
  { postCode: "9660", gemeente: "Nederbrakel" },
  { postCode: "9660", gemeente: "Opbrakel" },
  { postCode: "9660", gemeente: "Sint-Maria-Oudenhove" },
  { postCode: "9660", gemeente: "Zegelsem" },
  { postCode: "9661", gemeente: "Parike" },
  { postCode: "9667", gemeente: "Sint-Kornelis-Horebeke" },
  { postCode: "9667", gemeente: "Sint-Maria-Horebeke" },
  { postCode: "9680", gemeente: "Etikhove" },
  { postCode: "9680", gemeente: "Maarke-Kerkem" },
  { postCode: "9681", gemeente: "Nukerke" },
  { postCode: "9688", gemeente: "Schorisse" },
  { postCode: "9690", gemeente: "Berchem" },
  { postCode: "9690", gemeente: "Kwaremont" },
  { postCode: "9690", gemeente: "Ruien" },
  { postCode: "9690", gemeente: "Zulzeke" },
  { postCode: "9700", gemeente: "Bevere" },
  { postCode: "9700", gemeente: "Edelare" },
  { postCode: "9700", gemeente: "Eine" },
  { postCode: "9700", gemeente: "Ename" },
  { postCode: "9700", gemeente: "Heurne" },
  { postCode: "9700", gemeente: "Leupegem" },
  { postCode: "9700", gemeente: "Mater" },
  { postCode: "9700", gemeente: "Melden" },
  { postCode: "9700", gemeente: "Mullem" },
  { postCode: "9700", gemeente: "Nederename" },
  { postCode: "9700", gemeente: "Ooike" },
  { postCode: "9700", gemeente: "Oudenaarde" },
  { postCode: "9700", gemeente: "Volkegem" },
  { postCode: "9700", gemeente: "Welden" },
  { postCode: "9750", gemeente: "Huise" },
  { postCode: "9750", gemeente: "Ouwegem" },
  { postCode: "9750", gemeente: "Zingem" },
  { postCode: "9770", gemeente: "Kruishoutem" },
  { postCode: "9771", gemeente: "Nokere" },
  { postCode: "9772", gemeente: "Wannegem-Lede" },
  { postCode: "9790", gemeente: "Elsegem" },
  { postCode: "9790", gemeente: "Moregem" },
  { postCode: "9790", gemeente: "Ooike" },
  { postCode: "9790", gemeente: "Petegem-aan-de-Schelde" },
  { postCode: "9790", gemeente: "Wortegem" },
  { postCode: "9800", gemeente: "Astene" },
  { postCode: "9800", gemeente: "Bachte-Maria-Leerne" },
  { postCode: "9800", gemeente: "Deinze" },
  { postCode: "9800", gemeente: "Gottem" },
  { postCode: "9800", gemeente: "Grammene" },
  { postCode: "9800", gemeente: "Meigem" },
  { postCode: "9800", gemeente: "Petegem-aan-de-Leie" },
  { postCode: "9800", gemeente: "Sint-Martens-Leerne" },
  { postCode: "9800", gemeente: "Vinkt" },
  { postCode: "9800", gemeente: "Wontergem" },
  { postCode: "9800", gemeente: "Zeveren" },
  { postCode: "9810", gemeente: "Eke" },
  { postCode: "9810", gemeente: "Nazareth" },
  { postCode: "9820", gemeente: "Bottelare" },
  { postCode: "9820", gemeente: "Lemberge" },
  { postCode: "9820", gemeente: "Melsen" },
  { postCode: "9820", gemeente: "Merelbeke" },
  { postCode: "9820", gemeente: "Munte" },
  { postCode: "9820", gemeente: "Schelderode" },
  { postCode: "9830", gemeente: "Sint-Martens-Latem" },
  { postCode: "9831", gemeente: "Deurle" },
  { postCode: "9840", gemeente: "De Pinte" },
  { postCode: "9840", gemeente: "Zevergem" },
  { postCode: "9850", gemeente: "Hansbeke" },
  { postCode: "9850", gemeente: "Landegem" },
  { postCode: "9850", gemeente: "Merendree" },
  { postCode: "9850", gemeente: "Nevele" },
  { postCode: "9850", gemeente: "Poesele" },
  { postCode: "9850", gemeente: "Vosselare" },
  { postCode: "9860", gemeente: "Balegem" },
  { postCode: "9860", gemeente: "Gijzenzele" },
  { postCode: "9860", gemeente: "Landskouter" },
  { postCode: "9860", gemeente: "Moortsele" },
  { postCode: "9860", gemeente: "Oosterzele" },
  { postCode: "9860", gemeente: "Scheldewindeke" },
  { postCode: "9870", gemeente: "Machelen" },
  { postCode: "9870", gemeente: "Olsene" },
  { postCode: "9870", gemeente: "Zulte" },
  { postCode: "9880", gemeente: "Aalter" },
  { postCode: "9880", gemeente: "Lotenhulle" },
  { postCode: "9880", gemeente: "Poeke" },
  { postCode: "9881", gemeente: "Bellem" },
  { postCode: "9890", gemeente: "Asper" },
  { postCode: "9890", gemeente: "Baaigem" },
  { postCode: "9890", gemeente: "Dikkelvenne" },
  { postCode: "9890", gemeente: "Gavere" },
  { postCode: "9890", gemeente: "Semmerzake" },
  { postCode: "9890", gemeente: "Vurste" },
  { postCode: "9900", gemeente: "Eeklo" },
  { postCode: "9910", gemeente: "Knesselare" },
  { postCode: "9910", gemeente: "Ursel" },
  { postCode: "9920", gemeente: "Lovendegem" },
  { postCode: "9921", gemeente: "Vinderhoute" },
  { postCode: "9930", gemeente: "Zomergem" },
  { postCode: "9931", gemeente: "Oostwinkel" },
  { postCode: "9932", gemeente: "Ronsele" },
  { postCode: "9940", gemeente: "Ertvelde" },
  { postCode: "9940", gemeente: "Evergem" },
  { postCode: "9940", gemeente: "Kluizen" },
  { postCode: "9940", gemeente: "Sleidinge" },
  { postCode: "9950", gemeente: "Waarschoot" },
  { postCode: "9960", gemeente: "Assenede" },
  { postCode: "9961", gemeente: "Boekhoute" },
  { postCode: "9968", gemeente: "Bassevelde" },
  { postCode: "9968", gemeente: "Oosteeklo" },
  { postCode: "9970", gemeente: "Kaprijke" },
  { postCode: "9971", gemeente: "Lembeke" },
  { postCode: "9980", gemeente: "Sint-Laureins" },
  { postCode: "9981", gemeente: "Sint-Margriete" },
  { postCode: "9982", gemeente: "Sint-Jan-in-Eremo" },
  { postCode: "9988", gemeente: "Waterland-Oudeman" },
  { postCode: "9988", gemeente: "Watervliet" },
  { postCode: "9990", gemeente: "Maldegem" },
  { postCode: "9991", gemeente: "Adegem" },
  { postCode: "9992", gemeente: "Middelburg" },
];
