<template>
  <div class="container mb-4" id="app">
    <div v-if="showModal">
      <danger-zone :hideModal="() => (showModal = false)" :gezinslid="gezinshoofd" :id="id" />
    </div>
    <div class="row" v-if="id != 0 && warnings.length > 0 && isLid">
      <div class=" alert alert-warning d-flex align-items-center" role="alert">
        <i class="fas fa-engine-warning"></i>
        <div class="ms-3">
          Er missen een aantal gegevens, gelieve deze zo snel mogelijk bij te werken:
          <ul class="mb-0">
            <li v-for="warning in warnings" :key="warning">{{ warning }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" v-if="id != 0">
      <div class="col-12">
        <span class="float-end">
          <button type="button" class="btn btn-success btn-lg" v-on:click="save()"
            :disabled="saving || !hasChanges() || !this.service.add">
            <i class="far fa-save" /> Opslaan
          </button>
        </span>
      </div>
    </div>
    <form autocomplete="off">
      <div class="row" v-if="id != 0">
        <div class="col-6" v-if="isLid">
          <label for="exampleInputEmail1" class="form-label">MVM-Nummer</label>
          <div class="input-group">
            <span class="input-group-text" id="mvmnummer">MVM</span>
            <input type="text" v-model="dossier.mvmnummer" class="form-control" aria-label="MVMNummer"
              aria-describedby="mvmnummer" disabled />
          </div>
        </div>
        <div class="col-6" v-else>
          <label for="exampleInputEmail1" class="form-label">E-Nummer</label>
          <div class="input-group">
            <span class="input-group-text" id="enummer">E</span>
            <input type="text" v-model="dossier.enummer" class="form-control" aria-label="ENummer"
              aria-describedby="enummer" disabled />
          </div>
          <div class="form-text">Automatisch aangemaakt bij opslaan</div>
        </div>
        <div class="col-6">
          <label class="form-label">&nbsp;</label>
          <div class="input-group">
            <button type="button" class="btn btn-info" v-on:click="print()">
              <i class="far fa-print" /> Print voorblad
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-if="id != 0">
        <div class="col-6">
          <label class="form-label">Inschrijfdatum</label>
          <p>
            {{
              new Date(gezinshoofd.inschrijfdatum).toLocaleString("nl-BE", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })
            }}
          </p>
        </div>
        <div class="col-6">
          <label class="form-label">Laatst aangepast</label>
          <p>
            {{
              new Date(gezin.UpdatedAt).toLocaleString("nl-BE", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })
            }}
          </p>
        </div>
        <div class="col-12" v-if="dossier.mvmnummer">
          <div class="form-check controle form-switch">
            <label class="form-check-label" for="controle">Controle </label>

            <input class="form-check-input" type="checkbox" id="controle" v-model="dossier.controle"
              v-on:click="toggleControle" />
          </div>
        </div>
        <div class="col-6" v-if="dossier.mvmnummer && (dossier.controle || dossier.redenControle)">
          <label class="form-label">Redene Controle</label>
          <input type="text" class="form-control" id="redenControle" v-model="dossier.redenControle" autocomplete="off" />
        </div>
      </div>
      <hr class="mt-3" />
      <h3>{{ gezinshoofd.geslacht === "man" ? "Vader" : "Moeder" }}</h3>
      <div class="row">
        <div class="col-6">
          <label for="voornaam" class="form-label">Voornaam</label>
          <input type="text" class="form-control" id="voornaam" v-model="gezinshoofd.voornaam" autocomplete="off" />
        </div>
        <div class="col-6">
          <label for="achternaam" class="form-label">Achterachternaam</label>
          <input type="text" class="form-control" id="achternaam" v-model="gezinshoofd.achternaam" autocomplete="off" />
        </div>
        <div class="col-6">
          <label for="rrn" class="form-label">Rijksregisternummer</label>
          <input type="text" class="form-control" id="rrn" v-model="rrn" :class="rrnValid ? 'rrn-good' : 'rrn-bad'"
            @blur="checkForDoubleRRN()" autocomplete="off" />
        </div>
        <div class="col-6">
          <label for="geboortedatum" class="form-label">Geboortedatum</label>
          <datepicker id="geboortedatum" :format="'yyyy-MM-dd'" class="form-control" v-model="gezinshoofd.geboortedatum"
            initial-view="year" :disabled-dates="{ from: new Date() }" autocomplete="off" :language="nl" typeable="true">
          </datepicker>
        </div>
        <div class="col-6">
          <label for="geslacht" class="form-label">Geslacht</label>
          <select class="form-select" id="geslacht" v-model="gezinshoofd.geslacht">
            <option disabled value="">Selecteer een geslacht</option>
            <option value="vrouw">Vrouw</option>
            <option value="man">Man</option>
          </select>
        </div>
        <div class="col-6">
          <label for="telefoon" class="form-label">Telefoon</label>
          <input type="text" class="form-control" id="telefoon" v-model="gezinshoofd.telefoonnummer" autocomplete="off" />
        </div>
        <div class="col-6">
          <label for="geboorteland" class="form-label">Geboorteland</label>
          <multiselect :options="countries" v-model="gezinshoofd.geboorteland" :allow-empty="false">
          </multiselect>
        </div>
        <div class="col-6">
          <label for="geslacht" class="form-label">Nationaliteit</label>
          <multiselect v-model="gezinshoofd.nationaliteit" :multiple="true" :options="countries" :allow-empty="false">
          </multiselect>
        </div>
        <div class="col-6">
          <label for="verblijfsvergunning" class="form-label">Verblijfsvergunning</label>
          <multiselect id="verblijfsvergunning" v-model="gezin.verblijfsvergunning" :options="[
            'Ja',
            'Tijdelijk >1 jaar',
            'Tijdelijk minder dan 1 jaar',
            'Nee'
          ]" :allow-empty="false">
          </multiselect>
        </div>
      </div>
      <hr />
      <h3>Adres</h3>
      <div class="row">
        <div class="col-6">
          <label for="straat" class="form-label">Straat</label>
          <input type="text" class="form-control" id="straat" v-model="gezin.straat" />
        </div>
        <div class="col-6">
          <label for="huisnummer" class="form-label">Huisnummer</label>
          <input type="text" class="form-control" id="huisnummer" v-model="gezin.huisnummer" autocomplete="off" />
        </div>
        <div class="col-6">
          <label for="postcode" class="form-label">Postcode</label>
          <input type="text" class="form-control" id="postcode" v-model="postcode" autocomplete="off" />
        </div>
        <div class="col-6">
          <label for="gemeente" class="form-label">Gemeente</label>
          <multiselect v-model="gezin.gemeente" :options="gemeenten" :allow-empty="false" />
        </div>
      </div>

      <hr />
      <h3>Situatie</h3>
      <div class="row">
        <div class="col-6 mt-2">
          <label for="finnood" class="form-label">Gezinstatus </label>
          <span class="d-inline-block mx-2" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
            data-bs-content="Automatisch berekend door gezinssamenstelling">
            <i class="fad fa-question-circle"></i>
          </span>
          <div v-if="id == 0" class="text-muted">(berekend na opslagen)</div>
          <div v-else>
            <span v-if="gezin.leden.filter((l) => l.type === 'ouder').length == 1">Eenoudergezin
            </span>
            <span v-else>Tweeoudergezin </span>
            <span v-if="kinderenTotEnMet12 == 0">
              zonder kinderen
            </span>
            <span v-else-if="kinderenTotEnMet12 == 1">
              met 1 kind
            </span>
            <span v-else>met
              {{ kinderenTotEnMet12 }}
              kinderen</span>
          </div>
        </div>
        <div class="col-6">
          <label for="doorverwijzendeInstantie" class="form-label">Doorverwijzende Instantie</label>
          <multiselect id="doorverwijzendeInstantie" v-model="gezin.doorverwijzendeInstantie" :options="[
            'OCMW',
            'Kind en Gezin',
            'Schuldbemiddelaar (advocaat)',
            'CAW',
            'Zorg',
            'Politie',
            'Geen verwijzer',
            'Overige sociale dienst',
          ]" :allow-empty="false">
          </multiselect>
        </div>
        <div class="col-6">
          <label for="arbeidsstatus" class="form-label">Arbeidsstatus</label>
          <multiselect id="arbeidsstatus" v-model="gezinshoofd.arbeidsstatus" :options="[
            'Werkend',
            'Werkloos',
            'Gepensioneerd',
            'Huisvrouw',
            'Ziekte',
            'Invalide',
          ]" :allow-empty="false">
          </multiselect>
        </div>
        <div class="col-6 my-4">
          <div class="form-check form-switch">
            <label class="form-check-label" for="doorverwijsBrief">Doorverwijzbrief</label>
            <input class="form-check-input" type="checkbox" id="doorverwijsBrief" v-model="gezin.doorverwijsBrief"
              autocomplete="off" />
          </div>
        </div>

        <div class="col-6">
          <div>
            <label for="nederlands" class="form-label">Kennis Nederlands of bereidheid leren</label>
            <span class="d-inline-block mx-2" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
              data-bs-content="Zal de kandidaat kunnen deelnemen aan de Moederwerking in het
                  Nederlands? voldoende kennis van het Nederlands of toont bereidheid
                  om het te leren o.a. tijdens op de Moederwerking">
              <i class="fad fa-question-circle"></i>
            </span>
            <motivation id="nederlands" :score="gezin.kennisNederlands"
              @changeValue="gezin.kennisNederlands = parseInt($event, 10)" />
          </div>
        </div>
        <div class="col-6 mt-2">
          <label for="finnood" class="form-label">Acute nood aan ondersteuning
          </label>
          <span class="d-inline-block mx-2" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
            data-bs-content="Acute nood aan ondersteuning omwille van sociale situatie en
                    levensomstandigheden">
            <i class="fad fa-question-circle"></i>
          </span>
          <motivation id="acutenood" :score="gezin.acuteNood" @changeValue="gezin.acuteNood = parseInt($event, 10)" />
        </div>
        <div class="col-12 mt-2">
          <label for="finnood" class="form-label">Opmerking over situatie
          </label>
          <textarea class="form-control" v-model="gezin.opmerkingSituatie" rows="2"></textarea>
        </div>
      </div>

      <hr />
      <h3>Financiële informatie</h3>
      <div class="row">
        <div class="col-6 my-4">
          <div class="form-check form-switch">
            <label class="form-check-label" for="schuldbemiddeling">Schuldbemiddeling</label>
            <input class="form-check-input" type="checkbox" id="schuldbemiddeling" v-model="gezin.inSchuldbemiddeling"
              autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="row" v-if="gezin.inSchuldbemiddeling">
        <h4>Schuldbemiddeling</h4>
        <div class="col-6">
          <label for="budget" class="form-label">Maandelijks budget</label>
          <input type="number" min="0" class="form-control" id="budget" :disabled="gezin.maandelijksBudgetNietBekend"
            v-model.number="gezin.maandelijksBudget" autocomplete="off" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="budgetNietBekend"
              v-model="gezin.maandelijksBudgetNietBekend" />
            <label class="form-check-label" for="budgetNietBekend">
              Niet bekend
            </label>
          </div>
        </div>
      </div>

      <div class="row financieleinfo" v-if="!gezin.inSchuldbemiddeling">
        <h4>Inkomen</h4>
        <div class="col-6">
          <label for="inkomen" class="form-label">Maandelijks inkomen</label>
          <input type="number" min="0" class="form-control" id="inkomen" v-model.number="gezin.maandelijksInkomen"
            :disabled="gezin.maandelijksInkomenNietBekend" autocomplete="off" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="inkomenNietBekend"
              v-model="gezin.maandelijksInkomenNietBekend" />
            <label class="form-check-label" for="inkomenNietBekend">
              Niet bekend
            </label>
          </div>
        </div>
        <div class="col-6">
          <label for="kindergeld" class="form-label">Kindergeld</label>
          <input type="number" min="0" class="form-control" id="kindergeld" v-model.number="gezin.kindergeld"
            :disabled="gezin.kindergeldNietBekend" autocomplete="off" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="kindergeldNietBekend"
              v-model="gezin.kindergeldNietBekend" autocomplete="off" />
            <label class="form-check-label" for="kindergeldNietBekend">
              Niet bekend
            </label>
          </div>
        </div>
        <div class="col-6">
          <label for="allimentatie" class="form-label">Allimentatie</label>
          <input type="number" min="0" class="form-control" id="allimentatie" v-model.number="gezin.allimentatie"
            :disabled="gezin.allimentatieNietBekend" autocomplete="off" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="allimentatieNietBekend"
              v-model="gezin.allimentatieNietBekend" />
            <label class="form-check-label" for="allimentatieNietBekend">
              Niet bekend
            </label>
          </div>
        </div>
      </div>
      <div class="row financieleinfo" v-if="!gezin.inSchuldbemiddeling">
        <h4>Uitgaven</h4>
        <div class="col-6">
          <label for="huur" class="form-label">Huur</label>
          <input type="number" min="0" class="form-control" id="huur" v-model.number="gezin.huur"
            :disabled="gezin.huurNietBekend" autocomplete="off" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="huurNietBekend" v-model="gezin.huurNietBekend" />
            <label class="form-check-label" for="huurNietBekend">
              Niet bekend
            </label>
          </div>
        </div>
        <div class="col-6">
          <label for="energie" class="form-label">Energie</label>
          <input type="number" min="0" class="form-control" id="energie" v-model.number="gezin.energie"
            :disabled="gezin.energieNietBekend" autocomplete="off" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="energieNietBekend" v-model="gezin.energieNietBekend"
              autocomplete="off" />
            <label class="form-check-label" for="energieNietBekend">
              Niet bekend
            </label>
          </div>
        </div>
        <div class="col-6">
          <label for="vastekosten" class="form-label">Vaste Kosten</label>
          <input type="number" min="0" class="form-control" id="vastekosten" v-model.number="gezin.vasteKosten"
            :disabled="gezin.vasteKostenNietBekend" autocomplete="off" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="vastekostenNietBekend"
              v-model.number="gezin.vasteKostenNietBekend" />
            <label class="form-check-label" for="vastekostenNietBekend">
              Niet bekend
            </label>
          </div>
        </div>
        <div class="col-6">
          <label for="aflossing" class="form-label">Schuld Aflossing</label>
          <input type="number" min="0" class="form-control" id="aflossing" v-model.number="gezin.schuldAflossing"
            :disabled="gezin.schuldAflossingNietBekend" autocomplete="off" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="aflossingNietBekend"
              v-model="gezin.schuldAflossingNietBekend" />
            <label class="form-check-label" for="aflossingNietBekend">
              Niet bekend
            </label>
          </div>
        </div>
        <div class="col-6 mt-3">
          <label class="form-label">Valt onder armoedegrens?</label>

          <div v-if="silcBerekening.inkomsten == 0">
            Misschien
            <span class="d-inline-block mx-2" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
              data-bs-content="Er zijn geen inkomsten bekend">
              <i class="fad fa-question-circle"></i>
            </span>
          </div>
          <div v-else :class="silcBerekening.valtOnderGrens ? '' : 'text-danger'">
            {{ silcBerekening.valtOnderGrens ? "ja" : "nee" }}
            <button class="btn btn-link btn-question text-black" type="button" data-bs-toggle="collapse"
              data-bs-target="#silcberekening" aria-expanded="false" aria-controls="silcberekening">
              <i class="fad fa-question-circle"></i>
            </button>

            <div id="silcberekening" class="collapse mt-3">
              SILC {{ silcBerekening.SILC }} * factor
              {{ silcBerekening.factor }} ( {{ silcBerekening.volwassenen }} VW
              + {{ silcBerekening.kinderen }} K ) = maximum
              {{
                Math.round(silcBerekening.SILC * silcBerekening.factor * 100) /
                100
              }}
              euro <br />Inkomsten bekend: {{ silcBerekening.inkomsten }} euro
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-6 mt-3">
          <div>
            <label for="financielenood" class="form-label">Acute nood aan ondersteuning omwille van financiële moeilijke
              situatie</label>
            <motivation id="financielenood" :score="gezin.financieleNood"
              @changeValue="gezin.financieleNood = parseInt($event, 10)" />
          </div>
        </div>

        <div class="col-6 mt-3">
          <label for="opmerkingFinancieel" class="form-label">Opmerking over financiële situatie
          </label>
          <textarea class="form-control mt-2" v-model="gezin.opmerkingFinancieel" id="opmerkingFinancieel"
            autocomplete="off" />
        </div>
      </div>
      <hr />
      <h3>Diensten</h3>
      <div class="row">
        <div class="col-6">
          <label for="typevoeding" class="form-label">Type Voeding</label>
          <multiselect id="typevoeding" :options="[
            'Gewoon',
            'Vegetarisch',
            'Dieet',
            'Geen Vlees',
            'Enkel Gevoogelte',
            'Geen Varkensvlees',
          ]" v-model="gezin.typeVoeding" :allow-empty="false">
          </multiselect>
        </div>
        <div class="col-6" v-if="dossier.mvmnummer">
          <div class="form-check form-switch mt-4">
            <label class="form-check-label" for="voorrang">Voorrang </label>

            <input class="form-check-input" type="checkbox" id="voorrang" v-model="dossier.voorrang" />
          </div>
          <div class="form-check form-switch">
            <label class="form-check-label" for="nietbetalen">Niet Betalen
            </label>

            <input class="form-check-input" type="checkbox" id="nietbetalen" v-model="dossier.nietBetalen" />
          </div>
        </div>
        <div class="col-6">
          <label for="dag" class="form-label">Dag</label>
          <multiselect id="dag" :options="['Dinsdag', 'Donderdag', 'Wisselend']" v-model="dossier.dag">
          </multiselect>
        </div>
        <!--<div class="col-6 mt-2">
          <label for="finnood" class="form-label">Speciale voeding </label>
          <span
            class="d-inline-block mx-2"
            tabindex="0"
            data-bs-toggle="popover"
            data-bs-trigger="hover focus"
            data-bs-content="Deze gegevens komen van de afdeling voeding"
          >
            <i class="fad fa-question-circle"></i>
          </span>
          <div>Geen brood aub</div>
        </div>-->
      </div>

      <hr />
      <h3>Voorwaarden lidmaatschap</h3>
      <div class="row">
        <div class="col-6">
          <label for="finnood" class="form-label">Past bij kernwaarden </label>
          <span class="d-inline-block mx-2" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
            data-bs-content="Passen de houding en het gedrag van de kandidaat tijdens het
                  gesprek bij de visie en Kernwaarden van de vereniging?">
            <i class="fad fa-question-circle"></i>
          </span>
          <div>
            <motivation id="kernwaarden" :score="gezin.kernwaarden"
              @changeValue="gezin.kernwaarden = parseInt($event, 10)" />
          </div>
        </div>
        <div class="col-6">
          <label for="finnood" class="form-label">Is gemotiveerd</label>
          <span class="d-inline-block mx-2" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
            data-bs-content="Motivatie van de kandidaat voor het lidmaatschap als ingeschreven
                  lid, integratie en actieve deelname aan de Moederwerking?">
            <i class="fad fa-question-circle"></i>
          </span>
          <div>
            <motivation id="isgemotiveerd" :score="gezin.isGemotiveerd"
              @changeValue="gezin.isGemotiveerd = parseInt($event, 10)" />
          </div>
        </div>
        <div class="col-6">
          <label for="finnood" class="form-label">Komt oprecht en waarachtig over?</label>
          <div>
            <motivation id="oprechtheid" :score="gezin.oprechtheid"
              @changeValue="gezin.oprechtheid = parseInt($event, 10)" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-check form-switch my-5">
            <label class="form-check-label" for="ondertekendFormulier">Ondertekend inschrijvingsformulier
            </label>
            <span class="d-inline-block mx-2" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus"
              data-bs-content="Is de kandidaat bereid tot ondertekening van het
                    inschrijvingsformulier waarin hij zich verbindt tot eerbiediging
                    van de visie en de kernwaarden van de vereniging in
                    overeenstemming met het Waardencharter, en tot naleving van de
                    statuten en de toepasselijke interne reglementen, met inbegrip van
                    toekomstige wijzigingen hieraan">
              <i class="fad fa-question-circle"></i>
            </span>

            <input class="form-check-input" type="checkbox" id="ondertekendFormulier"
              v-model="gezin.ondertekendFormulier" />
          </div>
        </div>
        <div class="col-6">
          <label for="opmerking" class="form-label">Opmerking</label>
          <div class="row">
            <div class="col-11">
              <textarea class="form-control" id="opmerking" v-model="gezin.opmerking" />
            </div>
            <div class="col-1">
              <div class="btn-group dropup">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <i class="fas fa-pencil"></i>
                </button>
                <ul class="dropdown-menu">
                  <li v-for="item, key in opmerkingAlgemeen" :key="item.id">
                    <a class="dropdown-item" @click="gezin.opmerking += item + '\n'">
                      {{ key }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>

      <hr />

      <div>
        <hr />
        <span class="float-end">
          <button class="btn btn-danger" type="button" @click="showModal = true" v-if="id != 0">
            <i class="fas fa-biohazard"></i>
            Danger Zone
          </button>
        </span>

        <button type="button" class="btn btn-success btn-lg" v-on:click="save()" :disabled="saving || !hasChanges()"
          v-if="id != 0">
          <i class="far fa-save" /> Opslaan
        </button>

        <button type="button" class="btn btn-warning btn-lg me-3" v-on:click="saveAsPunctueleHulp()"
          :disabled="saving || !hasChanges()" v-if="id == 0">
          <i class="fas fa-life-ring"></i> Eenmalige Hulp
        </button>
        <button type="button" class="btn btn-info btn-lg" v-on:click="saveAsLid()" :disabled="saving || !hasChanges()"
          v-if="id == 0 && !config.zoho">
          <i class="fas fa-id-card"></i> Lid Inschrijven
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { nl } from "@maartje/vuejs-datepicker/dist/locale";
import { jsPDF } from "jspdf";
import Datepicker from "@maartje/vuejs-datepicker";
import Multiselect from "vue-multiselect";
import { CountryList } from "../../../_helpers/countries";
import { Postcodes } from "../../../_helpers/postcodes";
import { formatRRN } from "../../../_helpers/rrn";
import Motivation from "../Motivation.vue";
import { eidService } from "../../../_services/eid.service";
import { enablePopovers } from "../../../_helpers/bootstrap";
import { getExactAge } from "../../../_helpers/dates";
import { opmerkingAlgemeen } from "./opmerkingen";

import config from "config";

import {
  Hoofding,
  PoppinsMedium,
  PoppinsRegluar,
} from "../../../_helpers/pdf-resources";

import DangerZone from "./DangerZone.vue";
import { punctueleService } from "../../../_services/punctuele.service";
import { ledenService } from "../../../_services/leden.service";
import { verlengen } from "../../../_helpers/lidmaatschap";

const getCurrentYearInTwoDigits = () => {
  return parseInt(new Date().getFullYear().toString().substr(2, 2));
};

export default {
  props: ["id", "sendUpdate", "readEID", "service", "isLid"],
  components: {
    Datepicker,
    Multiselect,
    Motivation,
    DangerZone,
  },
  data: function () {
    return {
      nl,
      opmerkingAlgemeen,
      config,
      gezin: {
        straat: "",
        huisnummer: "",
        postcode: "",
        gemeente: "",

        inSchuldbemiddeling: false,
        maandelijksBudget: 0,
        maandelijksBudgetNietBekend: true,
        maandelijksInkomen: 0,
        maandelijksInkomenNietBekend: true,
        kindergeld: 0,
        kindergeldNietBekend: true,
        allimentatie: 0,
        allimentatieNietBekend: true,
        huur: 0,
        huurNietBekend: true,
        energie: 0,
        energieNietBekend: true,
        vasteKosten: 0,
        vasteKostenNietBekend: true,
        schuldAflossing: 0,
        schuldAflossingNietBekend: true,

        leden: [],

        kennisNederlands: 0,
        acuteNood: 0,
        financieleNood: 0,
        kernwaarden: 0,
        isGemotiveerd: 0,
        oprechtheid: 0,

        ondertekendFormulier: false,

        opmerkingSituatie: "",
        opmerkingFinancieel: "",
        opmerking: "",

        typeVoeding: "gewoon",
      },
      dossier: {
        enummer: 0,
        mvmnummer: 0,
      },
      gezinshoofd: {
        type: "ouder",
        gezinsHoofd: true,
        inschrijfdatum: new Date(),
        rijksregisterNummer: "",
        voornaam: "",
        achternaam: "",
        geboortedatum: null,
        geslacht: "",
        nationaliteit: [],
        geboorteland: "",
        telefoonnummer: "",
        arbeidsstatus: "",
      },

      // used for watchers
      rrn: "",
      rrnValid: false,
      postcode: "",

      saving: false,

      countries: CountryList,
      gemeenten: Postcodes.map((pc) => pc.gemeente),
      nationaliteit: [],

      showModal: false,
    };
  },
  computed: {
    warnings: function () {
      const warnings = [];
      if (this.dossier.lidmaatschap && this.dossier.lidmaatschap.filter(l => !l.gezinsSamenstellingInOrde).length > 0) {
        warnings.push("Gezinssamenstelling ontbreekt");
      }
      if (!this.gezin.inSchuldbemiddeling && (this.gezin.maandelijksInkomenNietBekend || this.gezin.kindergeldNietBekend || this.gezin.allimentatieNietBekend || this.gezin.huurNietBekend || this.gezin.energieNietBekend || this.gezin.vasteKostenNietBekend || this.gezin.schuldAflossingNietBekend)) {
        warnings.push("Financiele situatie ontbreekt delen");
      }
      if (!this.gezin.kennisNederlands || !this.gezin.acuteNood || !this.gezin.financieleNood || !this.gezin.kernwaarden || !this.gezin.isGemotiveerd || !this.gezin.oprechtheid) {
        warnings.push("Beoordeling niet compleet");
      }
      if (!this.gezin.verblijfsvergunning) {
        warnings.push("Verblijfsvergunning niet bekend");
      }

      return warnings;
    },
    kinderenTotEnMet12: function () {
      return this.gezin.leden.filter((l) => getExactAge(l.geboortedatum) <= 12 && getExactAge(l.geboortedatum) >= 0).length
    },
    silcBerekening: function () {
      const currentSILC = 1449.83;
      let factor = 1.0;

      const volwassenen = this.gezin.leden.filter(
        (l) =>
          getExactAge(l.geboortedatum) >= 15 ||
          getExactAge(l.geboortedatum) == -1 // no age probably means adult
      ).length;
      const kinderen = this.gezin.leden.filter(
        (l) =>
          getExactAge(l.geboortedatum) < 15 && getExactAge(l.geboortedatum) >= 0
      ).length;

      factor += (volwassenen - 1) * 0.5;
      factor += kinderen * 0.3;

      let inkomsten = 0;
      if (this.gezin.inSchuldbemiddeling) {
        this.maandelijksBudgetNietBekend ||
          (inkomsten += this.gezin.maandelijksBudget);
      } else {
        this.gezin.maandelijksInkomenNietBekend ||
          (inkomsten += this.gezin.maandelijksInkomen);
        this.gezin.allimentatieNietBekend ||
          (inkomsten += this.gezin.allimentatie);
        this.gezin.kindergeldNietBekend || (inkomsten += this.gezin.kindergeld);
      }

      return {
        SILC: currentSILC,
        factor,
        drempel: currentSILC * factor,
        inkomsten,
        valtOnderGrens: inkomsten <= currentSILC * factor,
        volwassenen,
        kinderen,
      };
    },
  },
  watch: {
    postcode: function (val) {
      if (val.length === 4) {
        this.gemeenten = Postcodes.filter((pc) => pc.postCode === val).map(
          (pc) => pc.gemeente
        );
        if (this.gemeenten.length === 1) {
          this.gezin.gemeente = this.gemeenten[0];
        }
      }
      this.gezin.postcode = val;
    },
    rrn: function (val) {
      let newRRN = val.replace(/[^0-9]/g, "");

      if (newRRN.length === 11) {
        let yearPrefix = "19";
        if (
          parseInt(newRRN.substring(0, 2)) <
          getCurrentYearInTwoDigits() - 12
        ) {
          //predicted to crash in 2100, I will be 103 years old so somebody else fix it
          yearPrefix = "20";
        }
        let month = newRRN.substring(2, 4);
        if (month == "00") {
          month = "01";
        }
        let day = newRRN.substring(4, 6);
        if (day == "00") {
          day = "01";
        }
        this.gezinshoofd.geboortedatum = Date.parse(
          `${yearPrefix}${newRRN.substring(0, 2)}-${month}-${day}`
        );

        if (parseInt(newRRN.substring(6, 9)) % 2 === 0) {
          this.gezinshoofd.geslacht = "vrouw";
        } else {
          this.gezinshoofd.geslacht = "man";
        }
      }

      // 02.04.12-364.54
      // 020412364

      if (
        newRRN.length == 11 &&
        (97 - (parseInt(newRRN.substring(0, 9), 10) % 97) ===
          parseInt(newRRN.substring(9, 11), 10) ||
          97 - ((parseInt("2" + newRRN.substring(0, 9), 10) % 97)) === // for people born after 2000
          parseInt(newRRN.substring(9, 11), 10))
      ) {
        this.rrnValid = true;
      } else {
        this.rrnValid = false;
      }

      this.rrn = formatRRN(newRRN);
      this.gezinshoofd.rijksregisterNummer = this.rrn;
    },
  },
  methods: {
    print: function () {
      // Default export is a4 paper, portrait, using millimeters for units
      const doc = new jsPDF();
      doc.addFileToVFS("Poppins-Medium.ttf", PoppinsMedium);
      doc.addFileToVFS("Poppins-Regular.ttf", PoppinsRegluar);
      doc.addFont("Poppins-Medium.ttf", "Poppins Medium", "normal");
      doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");

      doc.addImage(`data:image/jpeg;base64,${Hoofding}`, "PNG", 0, 0, 210, 297);

      doc.setFont("Poppins");
      doc.setFontSize(20);
      doc.setFillColor("#c10b69");
      doc.rect(16, 120, 2, -5, "F");
      if (this.dossier.mvmnummer) {
        doc.text("Lid", 20, 120);
      } else {
        doc.text("Eenmalige Hulp", 20, 120);
      }

      doc.setFontSize(40);
      doc.setFont("Poppins Medium");
      if (this.dossier.mvmnummer) {
        doc.text(`MVM${this.id}`, 20, 140);
      } else {
        doc.text(`E${this.id}`, 20, 140);
      }
      doc.setFontSize(30);
      doc.setFont("Poppins");
      doc.text(
        `${this.gezinshoofd.voornaam} ${this.gezinshoofd.achternaam}`,
        20,
        150
      );
      doc.setFontSize(20);
      doc.text(
        "Inschrijfdatum: " +
        new Date(this.gezinshoofd.inschrijfdatum).toLocaleDateString(
          "nl-BE",
          {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ),
        20,
        160
      );

      doc.autoPrint({ variant: "non-conform" });
      doc.save("autoprint.pdf");
    },
    validate: function () {
      if (!this.gezinshoofd.voornaam) {
        throw new Error("Voornaam niet ingevuld");
      }
      if (!this.gezinshoofd.achternaam) {
        throw new Error("Achternaam niet ingevuld");
      }
    },
    idSafeName: function (cat) {
      return cat.naam.replace(" ", "");
    },

    toggleControle: function (e) {
      if (!e.target.checked) {
        this.dossier.redenControle = "";
      }
    },

    checkForDoubleRRN: async function () {
      try {
        if (!this.rrnValid || this.id != 0) {
          return;
        }
        const { totalEntries } = await this.service.getAllForRRN(this.rrn);
        if (totalEntries > 0) {
          this.$Simplert.open({
            title: "Mogelijk dubbele inschrijving!",
            message:
              "Dit rijksregisternummer komt al voor in de database. Check dat dit geen dubbele inschrijving is!",
            type: "warning",
            customCloseBtnText: "Sluiten",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    toAPIData: function (apidata = null) {
      const data = copy(apidata || this.dossier);
      data.gezin = copy((apidata || {}).gezin || this.gezin);
      const gh = copy(apidata ? apidata.gezin.leden.filter((l) => l.gezinsHoofd)[0] : this.gezinshoofd);
      if (typeof gh.nationaliteit !== "string") {
        gh.nationaliteit = JSON.stringify(gh.nationaliteit);
      }
      if (gh.geboortedatum) {
        gh.geboortedatum = new Date(gh.geboortedatum).toJSON();
      }
      data.gezin.leden = [gh];

      return data;
    },

    validateLid() {
      let canBeLid = true;
      let reasons = [];

      const requiredFieldsGezinshoofd = {
        "voornaam": "Voornaam",
        "achternaam": "Achternaam",
        "geboortedatum": "Geboortedatum",
        "rijksregisterNummer": "Rijksregisternummer",
        "nationaliteit": "Nationaliteit",
        "geboorteland": "Geboorteland",
        "geslacht": "Geslacht",
        "arbeidsstatus": "Arbeidsstatus",
      };

      const requiredFieldsGezin = {
        "straat": "Straat",
        "huisnummer": "Huisnummer",
        "postcode": "Postcode",
        "gemeente": "Gemeente",
        "doorverwijzendeInstantie": "Doorverwijzende instantie",
        "kennisNederlands": "Kennis Nederlands of bereidheid leren",
        "acuteNood": "Acute nood aan ondersteuning",
        "financieleNood": "Acute nood omwille van financiële situatie",
        "kernwaarden": "Past bij kernwaarden",
        "isGemotiveerd": "Is gemotiveerd",
        "oprechtheid": "Komt oprecht en waarachtig over",
        "ondertekendFormulier": "Ondertekend inschrijvingsformulier"
      };

      const requiredFieldsDossier = {
        "dag": "Dag",
      }

      for (const [key, value] of Object.entries(requiredFieldsGezinshoofd)) {
        if (!this.gezinshoofd[key] || (typeof this.gezinshoofd[key] === "object" && this.gezinshoofd[key].length === 0)) {
          canBeLid = false;
          reasons.push(`${value} is niet ingevuld`);
        }
      }

      for (const [key, value] of Object.entries(requiredFieldsGezin)) {
        if (!this.gezin[key]) {
          canBeLid = false;
          reasons.push(`${value} is niet ingevuld`);
        }
      }

      for (const [key, value] of Object.entries(requiredFieldsDossier)) {
        if (!this.dossier[key]) {
          canBeLid = false;
          reasons.push(`${value} is niet ingevuld`);
        }
      }

      if (!canBeLid) {
        throw new Error("<br/>" + reasons.join("<br/><br/>"));
      }
    },

    validatePunctuele() {
      if (this.gezin.opmerking.trim() === "") {
        throw new Error("Opmerking over redene eenmalige hulp is niet ingevuld");
      }
    },

    saveAsLid: async function () {
      try {
        this.validate();
        this.validateLid();
        this.saving = true;

        const data = this.toAPIData();
        data.lidmaatschap = verlengen([]);

        const resp = await ledenService.add(data);

        this.originalData = JSON.stringify(
          this.toAPIData(),
          getCircularReplacer()
        );

        this.$Simplert.open({
          title: "Opgeslagen!",
          message: "",
          type: "success",
          customCloseBtnText: "Sluiten",
          onClose: () => {
            if (this.id == 0) {
              this.$router.push({
                name: "leden-details",
                params: { id: resp.mvmnummer },
              });
            }
          },
        });
        this.saving = false;
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });

        this.saving = false;
      }
    },

    saveAsPunctueleHulp: async function () {
      try {
        this.validate();
        this.validatePunctuele();
        this.saving = true;

        const resp = await punctueleService.add(this.toAPIData());

        this.originalData = JSON.stringify(
          this.toAPIData(),
          getCircularReplacer()
        );

        this.$Simplert.open({
          title: "Opgeslagen!",
          message: "",
          type: "success",
          customCloseBtnText: "Sluiten",
          onClose: async () => {
            if (this.id == 0) {
              /*await this.$router.push({
                name: "punctuele-details",
                params: { id: resp.enummer },
              });
              this.$router.go(0);*/

              // at this point i got fustrated with an error being shown right before the page was reloaded
              window.location = `/punctuele/details/${resp.enummer}`;
            }
          },
        });
        this.saving = false;
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });

        this.saving = false;
      }
    },

    save: async function () {
      try {
        // monique proof it...
        if (!this.gezin.maandelijksBudget) {
          this.gezin.maandelijksBudget = 0;
        }
        if (!this.gezin.maandelijksInkomen) {
          this.gezin.maandelijksInkomen = 0;
        }
        if (!this.gezin.kindergeld) {
          this.gezin.kindergeld = 0;
        }
        if (!this.gezin.allimentatie) {
          this.gezin.allimentatie = 0;
        }
        if (!this.gezin.huur) {
          this.gezin.huur = 0;
        }
        if (!this.gezin.energie) {
          this.gezin.energie = 0;
        }
        if (!this.gezin.vasteKosten) {
          this.gezin.vasteKosten = 0;
        }
        if (!this.gezin.schuldAflossing) {
          this.gezin.schuldAflossing = 0;
        }
        this.validate();
        this.saving = true;

        let resp;
        if (this.id == 0) {
          resp = await this.service.add(this.toAPIData());
        } else {
          resp = await this.service.update(this.id, this.toAPIData());
        }

        this.originalData = JSON.stringify(
          this.toAPIData(),
          getCircularReplacer()
        );

        this.$Simplert.open({
          title: "Opgeslagen!",
          message: "",
          type: "success",
          customCloseBtnText: "Sluiten",
          onClose: () => {
            if (this.id == 0) {
              this.$router.push({
                name: "punctuele-details",
                params: { id: resp.enummer },
                force: true,
              });
            }
          },
        });

        if (this.id != 0) {
          this.sendUpdate();
        }
        await this.load(resp.enummer);
        this.saving = false;
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });

        this.saving = false;
      }
    },
    hasChanges: function () {
      if (!this.service.update) {
        return false;
      }
      if (
        JSON.stringify(this.toAPIData(), getCircularReplacer()) !=
        this.originalData
      ) {
        return true;
      }
      return false;
    },
    load: async function (id) {
      this.loading = true;
      const resp = await this.service.get(id || this.id);

      this.dossier = resp;
      this.gezin = resp.gezin;
      this.gezinshoofd = resp.gezin.leden.filter((l) => l.gezinsHoofd)[0];
      try {
        this.gezinshoofd.nationaliteit = JSON.parse(
          this.gezinshoofd.nationaliteit
        );
      } catch (e) {
        console.log(e);
      }

      if (new Date(this.gezinshoofd.geboortedatum).getFullYear() === 1) {
        this.gezinshoofd.geboortedatum = null;
      }

      this.rrn = this.gezinshoofd.rijksregisterNummer;
      this.postcode = this.gezin.postcode;

      this.originalData = JSON.stringify(
        this.toAPIData(resp),
        getCircularReplacer()
      );

      this.loading = false;
    },
    goBack: function () {
      let vm = this;
      if (this.hasChanges()) {
        this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: function () {
            vm.save().then((ok) => {
              if (ok) {
                vm.$router.push({ name: "materiaal-search" });
              }
            });
          },
          onClose: function () {
            vm.$router.push({ name: "materiaal-search" });
          },
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Teruggaan zonder opslagen",
        });
      } else {
        vm.$router.push({ name: "punctuele-search" });
      }
    },
  },

  mounted: function () {
    enablePopovers();
  },

  created: async function () {
    this.loading = true;

    this.$parent.$on("update", this.load);

    // put empty in original first
    this.originalData = JSON.stringify(this.toAPIData(), getCircularReplacer());

    try {
      if (this.id == 0) {
        this.loading = false;
        if (this.readEID) {
          const data = await eidService.read();
          this.gezinshoofd.voornaam = data.firstNames;
          this.gezinshoofd.achternaam = data.name;
          this.gezinshoofd.geboortedatum = data.birthDate;
          this.gezinshoofd.geslacht = data.gender == "F" ? "Vrouw" : "Man";
          this.rrn = data.nationalNumber;
          this.gezin.gemeente = data.municipality;
          this.gezin.straat = data.address.split(/[\d]/)[0].trim();
          this.gezin.huisnummer = data.address
            .replace(data.address.split(/[\d]/)[0], "")
            .trim();
          this.postcode = data.zipcode;
          if (data.nationality == "Belg") {
            // TODO: figure out if the government has an extensive list...
            this.gezinshoofd.nationaliteit = ["België"];
          }
        }
        return;
      }

      await this.load();

      this.loading = false;
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }
  },
};

const copy = (obj) => JSON.parse(JSON.stringify(obj));

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
</script>

<style
  src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>
<style>
.table .table-dark th .vdp-datepicker {
  color: #000;
}

.form-control>div>input {
  width: 100%;
  border: none;
}

.rrn-bad {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.rrn-bad:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6) !important;
}

.rrn-good:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(36, 156, 5, 0.6) !important;
}

.btn-question[aria-expanded="true"] {
  display: none;
}
</style>
