<template id="sinterklaas-search">
  <div class="container">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row text-end mt-2">
        <div class="col-3 offset-8">
          <select class="form-select" v-model="evenementID" @change="setEvenementID">
            <option value="0" disabled>Selecteer een evenement</option>
            <option v-for="evenement in allEvenementen" :key="evenement.ID" :value="evenement.ID">
              {{ evenement.naam }}
            </option>
          </select>
        </div>
      </div>
      <h1 v-if="evenementID">{{ evenement.naam }}</h1>
      <form m v-on:submit.prevent="search" v-if="evenementID">
        <div class="row">
          <div class="col-8 big-search">
            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <div class="input-group-text">{{ prefix }}</div>
              </div>
              <input
                type="text"
                class="form-control"
                ref="search"
                v-model="searchQuery"
                v-focus
              />
            </div>
          </div>
          <div class="col-4">
            <button
              type="button"
              class="btn btn-lg btn-success"
              v-on:click="search()"
              :disabled="searching"
            >
              <i class="fas fa-search"></i
              ><span class="d-none d-md-inline"> Zoeken</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01"
                  >Type</label
                >
              </div>
              <select class="form-select" v-model="prefix">
                <option value="MVM">Leden</option>
                <option value>Op naam zoeken</option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <div class="container p-0" v-if="result">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Gezin</h3>
            <div class="row">
              <div class="col-4">
                <div class="col-title">MVM Nummer</div>
                MVM{{ lid.mvmnummer }}
              </div>
              <div class="col-4">
                <div class="col-title">Naam</div>
                {{ lid.gezin.leden.find((l) => l.gezinsHoofd).voornaam }}
                {{ lid.gezin.leden.find((l) => l.gezinsHoofd).achternaam }}
              </div>
              <div class="col-3 mb-3 text-end" v-if="result.aanwezig">
                <span class="badge bg-success text-bg-success fs-3"><i class="fas fa-check"></i> Ingeschreven</span>
              </div>
              <div class="col-3 mb-3 text-end" v-else>
                <span class="badge bg-danger text-bg-danger fs-3"><i class="fas fa-times"></i> Niet ingeschreven</span>
              </div>
              <div class="col-4">
                <div class="col-title">Opmerking</div>
                {{ result.opmerking }}
              </div>
            </div>

            <h3 class="card-title mb-1 mt-3">Kinderen</h3>
            <div class="row pakket-header">
              <div class="col-1">
                <div class="col-title">Braaf</div>
              </div>
              <div class="col-1">
                <div class="col-title">Komt</div>
              </div>
              <div class="col-2">
                <div class="col-title">Naam</div>
              </div>
              <div class="col-2">
                <div class="col-title">Leeftijd</div>
              </div>
              <div class="col-2">
                <div class="col-title">Geslacht</div>
              </div>
              <div class="col-4">
                <div class="col-title">Opmerking</div>
              </div>
            </div>
            <div class="pakket-body">
              <div
                class="row pakket-row"
                v-for="pakket in result.kinderen"
                v-bind:key="pakket.naam"
              >
                <div class="col-1">
                  <!-- TODO: do not hard code before the first year all children were not nice -->
                  Ja
                </div>
                <div class="col-1">
                  <i
                    v-if="pakket.aanwezig"
                    class="fas fa-check-square fa-2x"
                    style="color: green"
                  ></i>
                  <i
                    v-else
                    class="fas fa-times-octagon fa-3x"
                    style="color: red"
                  ></i>
                </div>
                <div class="col-2">
                  {{ pakket.naam }}
                </div>
                <div class="col-2">
                  {{ pakket.leeftijd }}
                </div>
                <div class="col-2">
                  {{ pakket.geslacht }}
                </div>
                <div class="col-4">
                  {{ pakket.opmerking }}
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <button
                type="button"
                class="col-5 m-1 btn btn-primary print-num"
                v-bind:disabled="printing"
                v-on:click="print(result)"
              >
                <i class="fad fa-print"></i>
              </button>
              <div class="col-3">
                <i
                  class="fas fa-check-square fa-3x"
                  style="color: green"
                  v-if="hasPrinted(result.mvmNummer)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sinterklaasService } from "../../_services/sinterklaas.service";
import { getExactAge } from "../../_helpers/dates";
import { keyboardHelper } from "@/_helpers/keyboard.helper";
import { evenementenService } from "../../_services/evenementen.service";
import { ledenService } from "../../_services/leden.service";

export default {
  template: "#sinterklaas-search",
  components: {},
  data: function () {
    return {
      loading: false,
      searchQuery: "",
      prefix: "MVM",
      searching: false,
      printing: false,
      result: null,
      lid: null,
      printed: [],
      volgNummer: 0,

      // evenementen selector
      evenementID: 0,
      evenement: {},
      allEvenementen: [],
    };
  },

  methods: {
    hasPrinted: function (number) {
      if (this.printed.indexOf(number) >= 0) {
        return true;
      }

      return false;
    },
    print: async function () {
      try {
        const kinderen = [];
        const newPaketten = [];
        for (let pakket of this.result.kinderen) {
          if (pakket.aanwezig) {
            newPaketten.push(pakket);
            kinderen.push(pakket.naam);
          }
        }

        this.volgNummer++

        const req = {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify(
            {
              snoep: {
                volgNummer: this.volgNummer,
                mvmNummer: `MVM${this.lid.mvmnummer}`,
                naam: this.lid.gezin.leden.find((l) => l.gezinsHoofd).voornaam + " " + this.lid.gezin.leden.find((l) => l.gezinsHoofd).achternaam,
                personen: this.lid.gezin.leden.filter(
                  (l) =>
                    getExactAge(l.geboortedatum) < 16 &&
                    getExactAge(l.geboortedatum) >= 0
                ).length,
              },
              speelgoed: {
                volgNummer: this.volgNummer,
                mvmNummer: `MVM${this.lid.mvmnummer}`,
                naam: this.lid.gezin.leden.find((l) => l.gezinsHoofd).voornaam + " " + this.lid.gezin.leden.find((l) => l.gezinsHoofd).achternaam,
                paketten: newPaketten,
              },
            },
            getCircularReplacer()
          ),
        };
        const responseOnthaal = await fetch(
          "https://onthaal.print.mvm.digital/sinterklaas",
          req
        );
        const resp = await responseOnthaal.json();
        if (resp.status == "error") {
          throw resp;
        }

        const responseVoeding = await fetch(
          "https://voeding.print.mvm.digital/sinterklaas",
          req
        );
        const resp2 = await responseVoeding.json();
        if (resp2.status == "error") {
          throw resp;
        }

        await sinterklaasService.saveKinderenForNumber(
          this.lid.mvmnummer,
          kinderen
        );

        this.$Simplert.open({
          title: "Print verstuurd",
          message: "Print opdracht verstuurd",
          type: "success",
          customCloseBtnText: "Sluiten",
          onClose: () => {
            this.$refs.search.focus();
          },
        });

        this.printed.push(this.lid.mvmnummer);
      } catch (e) {
        this.$Simplert.open({
          title: "Print error!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
          onClose: () => {
            this.$refs.search.focus();
          },
        });
      }
    },
    search: async function () {
      if (this.prefix != "MVM") {
        alert("Nog niet ondersteund, Maartje haar schuld...");
        return;
      }

      this.searchQuery = keyboardHelper.superCrazyAzertyBarcodeFix(
        this.searchQuery
      );

      this.searching = true;
      try {
        this.result = await evenementenService.getInschrijving(
          this.evenementID,
          this.searchQuery,
        );
        this.lid = await ledenService.get(this.searchQuery);

        if (this.result.aanwezig) {
          evenementenService.setAanwezig(
            this.evenementID,
            this.searchQuery,
          ); // launch this async we do not need a reply
        }

        this.searching = false;   
        this.searchQuery = "";
      } catch (error) {
        this.$Simplert.open({
            title: "Geen Resultaten!",
            message: error,
            type: "error",
            customCloseBtnText: "Sluiten",
          })
      }
    },
    loadEvenementen: async function() {
      this.loading = true;
      this.allEvenementen = await evenementenService.getAllEvenementen();

      // fetch evenementID from local storage 
      this.evenementID = parseInt(localStorage.getItem("evenementID"), 10) || 0;
      this.evenement = this.allEvenementen.find(e => e.ID == this.evenementID);
      this.loading = false;
    },
    setEvenementID: function(e) {
      const id = e.target.value;
      localStorage.setItem("evenementID", `${id}`);
      this.evenement = this.allEvenementen.find(e => e.id == id);
    },
  },

  created: function() {
    this.loadEvenementen();
  },
};

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
</script>

<style scoped>
.col-title {
  font-weight: bold;
  display: block;
}

.pakket-row {
  padding: 10px 0px 10px 0px;
  border-bottom: #6c757d solid 1px;
}

.pakket-header {
  border-bottom: #6c757d solid 2px;
  margin-bottom: 10px;
}

.pakket-body {
  margin: 0px;
  padding: 0px;
}
</style>
