import { Popover } from "bootstrap/dist/js/bootstrap.esm.min.js";

export const enablePopovers = () => {
  const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl);
  });
};
