<template id="search">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="float-start my-3">
          <h1>Markten</h1>
        </div>
        <div class="float-end my-3">
          <button type="button" class="btn btn-success btn-lg" v-on:click="newMarkt()">
            <i class="far fa-plus" /> Nieuw
          </button>
        </div>
      </div>
    </div>
    <vue-good-table v-if="results.length > 0" :columns="columns" :rows="results" styleClass="vgt-table striped"
      mode="remote" v-on:sort-change="onSortChange" :pagination-options="pagination" v-on:page-change="onPageChange"
      @on-per-page-change="onPerPageChange" :totalRows="totalRecords">
      <template #table-row="props">
        <span v-if="props.column.field == 'actions'">
          <button class="btn btn-success" v-on:click="foundCallback(props.row)">
            <i class="fas fa-pencil me-1"></i> Bewerken
          </button>
          <button class="btn btn-danger ms-3" v-on:click="deleteRow(props.row)">
            <i class="fas fa-trash"></i>
          </button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { marktenService } from "../../_services/markten.service";

export default {
  template: "#markten-beheer",
  components: {},
  data: function () {
    return {
      loading: false,
      orderBy: "start_datum DESC",
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Eerste',
        lastLabel: 'Laatste',
        nextLabel: 'Volgende',
        prevLabel: 'Vorige',
        rowsPerPageLabel: 'Aantal per pagina',
        ofLabel: 'van',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      },
      fieldsToDB: {
        naam: "naam",
        startDatum: "start_datum",
        eindDatum: "eind_datum",
      },
      columns: [
        {
          label: 'Naam',
          field: 'naam',
        },
        {
          label: 'Voor Kinderen',
          field: 'voorKinderen',
          sortable: false,
        },
        {
          label: 'Startdatum',
          field: 'startDatum',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-start',
          thClass: 'text-start',
        },
        {
          label: 'Einddatum',
          field: 'eindDatum',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-start',
          thClass: 'text-start',
        },
        {
          label: '',
          field: 'actions',
          tdClass: 'text-end',
          sortable: false,
        },
      ],
      results: [],
    };
  },
  created: function () {
    this.loadPage(1);
  },

  methods: {
    foundCallback: function (result) {
      this.$router.push({
        name: "markten-details",
        params: { id: result.ID },
      });
    },
    deleteRow: function (result) {
      this.$Simplert.open({
        title: `Ben je zeker?`,
        message: `Ben je zeker dat je &quot;${result.naam}&quot; wil verwijderen?`,
        type: "warning",
        useConfirmBtn: true,
        onConfirm: () => {
          marktenService.remove(result.ID).then(() => {
            this.loadPage(1);
          });
        },
        customConfirmBtnClass: "btn btn-warning",
        customConfirmBtnText: "Verwijder",
        customCloseBtnText: "Annuleren",
      });
    },
    pageClickCallback: function (page) {
      this.loadPage(page);
    },
    newMarkt: function (readEID) {
      this.$router.push({
        name: "markten-details",
        params: { id: 0, readEID },
      });
    },
    loadPage: async function (num) {
      this.pageNumber = num;

      const { data, totalEntries } = await marktenService.getAll(
        num,
        this.searchQuery,
        this.orderBy,
        this.pagination.perPage
      );

      this.results = data.map(e => {
      
        e.startDatum = new Date(
          e.startDatum
        ).toLocaleDateString("sv-SE")

        e.eindDatum = new Date(
          e.eindDatum
        ).toLocaleDateString("sv-SE")


        e.voorKinderen = e.voorKinderen ? "Ja" : "Nee";

        return e
      });

      this.totalRecords = totalEntries
    },
    onPerPageChange: function (params) {
      this.pagination.perPage = params.currentPerPage;
      this.loadPage(this.pageNumber);
    },
    onPageChange(params) {
      this.pagination.page = params.currentPage;
      this.loadPage(params.currentPage);
    },
    onSortChange: function (params) {
      console.log(params);
      this.orderBy = this.fieldsToDB[params[0].field] + " " + params[0].type;
      this.loadPage(this.pageNumber);
    },
  },
};
</script>

<style scoped>
.col-title {
  font-weight: bold;
  display: block;
}
</style>
