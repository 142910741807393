<template id="search">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="float-start my-3">
          <h1>Leden</h1>
        </div>
        <div class="float-end my-3">
          <!--<button
            type="button"
            class="btn btn-primary btn-lg"
            v-on:click="readEID()"
          >
            <i class="fas fa-id-card" /> Lees eID
          </button>
          &nbsp;-->
          <button type="button" class="btn btn-success btn-lg" v-on:click="newLid()">
            <i class="far fa-plus" /> Nieuw
          </button>
        </div>
      </div>
    </div>
    <form m v-on:submit.prevent="search">
      <div class="row">
        <div class="col-10 big-search">
          <div class="input-group mb-1">
            <div class="input-group-prepend">
              <div class="input-group-text">{{ prefix || "&nbsp;" }}</div>
            </div>
            <input type="text" class="form-control" ref="search" v-model="searchQuery" :disabled="searching" v-focus />
          </div>
        </div>
        <div class="col-2 text-end">
          <button type="button" class="btn btn-lg btn-success" v-on:click="search" :disabled="searching">
            <i class="fas fa-search"></i><span class="d-none d-md-inline"> Zoeken</span>
          </button>
        </div>
      </div>
    </form>
    <vue-good-table v-if="results.length > 0" :columns="columns" :rows="results" styleClass="vgt-table striped"
      mode="remote" v-on:sort-change="onSortChange" :pagination-options="pagination" v-on:page-change="onPageChange"
      @on-per-page-change="onPerPageChange" :totalRows="totalRecords">
      <template #table-row="props">
        <span v-if="props.column.field == 'actions'">
          <button class="btn btn-primary" v-on:click="foundCallback(props.row)">
            Open <i class="fas fa-external-link-alt"></i>
          </button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { ledenService } from "../../_services/leden.service";
import { eidService } from "../../_services/eid.service";
import { formatRRN } from "../../_helpers/rrn";
import { getStatus, getEindDatum } from "../../_helpers/leden";

import config from "config";

export default {
  template: "#leden-search",
  components: {},
  data: function () {
    return {
      config,
      loading: false,
      searchQuery: "",
      prefix: "",
      searching: false,
      totalRecords: 0,
      orderBy: "MVMNummer DESC",
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Eerste',
        lastLabel: 'Laatste',
        nextLabel: 'Volgende',
        prevLabel: 'Vorige',
        rowsPerPageLabel: 'Aantal per pagina',
        ofLabel: 'van',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      },
      fieldsToDB: {
        mvmnummerFormatted: "MVMNummer",
        updated: "UpdatedAt",
        created: "CreatedAt",
      },
      columns: [
        {
          label: 'MVM Nummer',
          field: 'mvmnummerFormatted',
        },
        {
          label: 'Voornaam',
          field: 'voornaam',
          sortable: false,
        },
        {
          label: 'Achternaam',
          field: 'achternaam',
          sortable: false,
        },
        {
          label: 'Inschrijvingsdatum',
          field: 'created',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-start',
          thClass: 'text-start',
        },
        {
          label: 'Laatste update',
          field: 'updated',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-start',
          thClass: 'text-start',
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Einddatum',
          field: 'einddatum',
          sortable: false,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-start',
          thClass: 'text-start',
        },
        {
          label: '',
          field: 'actions',
          tdClass: 'text-end',
          sortable: false,
        },
      ],
      results: [],
    };
  },
  created: function () {
    this.loadPage(1);
  },
  watch: {
    searchQuery: function () {
      // disabled on transitional API cause rate limiting!
      // if (this.searchQuery.length > 3 || this.searchQuery.length === 0) {
      //   this.loadPage(1);
      // }
    },
  },

  methods: {
    pageClickCallback: function (page) {
      this.loadPage(page);
    },
    readEID: async function () {
      this.loading = true;
      try {
        const result = await eidService.read();
        if (!result.nationalNumber) {
          throw new Error("Geen geldig rijksregisternummer gevonden");
        }
        const { data } = await ledenService.getAllForRRN(
          formatRRN(result.nationalNumber)
        );
        if (data.length === 0) {
          this.newPunctuele(true);
        } else {
          this.foundCallback(data[0]);
        }
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij lezen van eID!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }
      this.loading = false;
    },
    search: async function () {
      this.searching = true;
      try {
        await this.loadPage(1);
      } catch (e) {
        console.error(e);
      }
      this.searching = false;
    },

    foundCallback: function (result) {
      this.$router.push({
        name: "leden-details",
        params: { id: result.mvmnummer },
      });
    },
    newLid: function (readEID) {
      this.$router.push({
        name: "punctuele-details", // we reuse this route as they are the same form!
        params: { id: 0, readEID },
      });
    },
    loadPage: async function (num) {
      this.pageNumber = num;

      const { data, totalEntries } = await ledenService.getAll(
        num,
        this.searchQuery,
        this.orderBy,
        this.pagination.perPage
      );

      this.results = data.map(e => {
        e.mvmnummerFormatted = `MVM${e.mvmnummer}`;
        e.voornaam = e.gezin.leden.find((l) => l.gezinsHoofd).voornaam;
        e.achternaam = e.gezin.leden.find((l) => l.gezinsHoofd).achternaam;
        e.created = new Date(
          e.CreatedAt
        ).toLocaleDateString("sv-SE")
        e.updated = new Date(
          e.UpdatedAt
        ).toLocaleDateString("sv-SE")
        e.status = getStatus(e)
        e.einddatum = getEindDatum(e).toLocaleDateString("sv-SE")

        return e
      });

      this.totalRecords = totalEntries
    },
    onPerPageChange: function (params) {
      this.pagination.perPage = params.currentPerPage;
      this.loadPage(this.pageNumber);
    },
    onPageChange(params) {
      this.pagination.page = params.currentPage;
      this.loadPage(params.currentPage);
    },
    onSortChange: function (params) {
      console.log(params);
      this.orderBy = this.fieldsToDB[params[0].field] + " " + params[0].type;
      this.loadPage(this.pageNumber);
    },
  },
};
</script>

<style scoped>
.col-title {
  font-weight: bold;
  display: block;
}
</style>
