<template id="search">
  <Search title="Zeephuisje" :foundCallback="foundCallback" />
</template>

<script>
import Search from "../_components/Search.vue";

export default {
  template: "#search",
  components: { Search },
  data: function () {
    return {
      loading: false,
      searchQuery: "",
      prefix: "MVM",
      searching: false,
      results: [],
    };
  },

  methods: {
    foundCallback: function (result) {
      this.$router.push({
        name: "zeephuisje-details",
        params: { id: result.mvmnummer },
      });
    },
  },
};
</script>

<style scoped>
.col-title {
  font-weight: bold;
  display: block;
}
</style>
