import config from "config";
import { authHeader } from "../_helpers/auth-header";
import { authService } from "../_services/auth.service";
import { getCircularReplacer } from "../_helpers/json";

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

const get = (id) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/markt/${id}`, requestOptions).then(
    handleResponse
  );
};

const getAll = (page, search, orderBy = '', pageSize = 10) => {
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/v1/markten/?page=${page}&page_size=${pageSize}&order=${orderBy}${
      search ? "&search=" + search : ""
    }`,
    requestOptions
  ).then(handlePagedResponse);
};

const handlePagedResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    const totalEntries = parseInt(
      response.headers.get("Num-Total-Entries"),
      10
    );

    return { data, totalEntries };
  });
};

const add = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(`${config.apiUrl}/v1/markt/`, requestOptions).then(handleResponse);
};

const remove = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/markt/${id}`, requestOptions).then(
    handleResponse
  );
};

const update = (id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(`${config.apiUrl}/v1/markt/${id}`, requestOptions).then(
    handleResponse
  );
};

const getCurrent = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/markten/scan/current-markten`, requestOptions).then(
    handleResponse
  );
};

const getBarcodeKey = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/markten/scan/key`, requestOptions).then(
    handleResponse
  );
};


const scanAanwezig = (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(`${config.apiUrl}/v1/markten/scan/${id}`, requestOptions).then(handleResponse);
};

export const marktenService = {
  get,
  getAll,
  add,
  remove,
  update,
  getCurrent,
  scanAanwezig,
  getBarcodeKey,
};
