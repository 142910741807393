<template>
  <div>
    <div class="fade modal" style="display: block" data-bs-backdrop="static" tabindex="1" ref="modal">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Lidmaatschap Bewerken</h5>
            <button type="button" class="btn-close" @click="hide" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form autocomplete="off">
              <div class="row">
                <div class="col-6 mb-3">
                  <label for="startdatum" class="form-label">Startdatum</label>
                  <datepicker id="startdatum" :format="'yyyy-MM-dd'" :language="nl" class="form-control"
                    v-model="lms.start" initial-view="year" autocomplete="off" typeable="true"></datepicker>
                </div>
                <div class="col-6 mb-3">
                  <label for="einddatum" class="form-label">Einddatum</label>
                  <datepicker id="einddatum" :format="'yyyy-MM-dd'" :language="nl" class="form-control"
                    v-model="lms.eind" initial-view="year" autocomplete="off" typeable="true"></datepicker>
                </div>
                <div class="col-6 mt-4">
                  <div class="form-check form-switch">
                    <label class="form-check-label" for="gezinssamenstelling">Attest gezinssamenstelling inorde</label>
                    <input class="form-check-input" type="checkbox" id="gezinssamenstelling"
                      v-model="lms.gezinsSamenstellingInOrde" autocomplete="off" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer justify-content-between" v-if="!vroegtijdigBeindigen">
            <button type="button" class="btn btn-danger" @click="openEnd" :disabled="saving">
              <i class="fas fa-gavel"></i> Vroegtijdig beëindigen
            </button>
            <button type="button" class="btn btn-success" @click="save" :disabled="saving">
              <i class="fas fa-save" /> Opslaan
            </button>
          </div>
          <div class="modal-footer justify-content-between row" v-if="vroegtijdigBeindigen">
            <div class="col-7">
              <label class="form-label" for="redenenBeindiging">Redene: </label>
              <input class="form-control" type="text" id="redenenBeindiging" v-model="lms.redenenBeindiging"
                autocomplete="off" required />
            </div>
            <div class="col-4">
              <button type="button" class="btn btn-danger" @click="end" :disabled="saving">
                <i class="fas fa-gavel"></i> Vroegtijdig beëindigen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "@maartje/vuejs-datepicker";
import { Modal } from "bootstrap";

import { nl } from "@maartje/vuejs-datepicker/dist/locale";

import { ledenService } from "../../_services/leden.service";

export default {
  props: ["hideModal", "mvmnummer", "data", "sendUpdate"],
  components: {
    Datepicker,
  },
  data: function () {
    return {
      nl,
      saving: false,
      modal: null,
      vroegtijdigBeindigen: false,
    };
  },
  methods: {
    hide: function () {
      this.modal.hide();
      this.hideModal();
    },
    save: async function () {
      try {
        this.saving = true;
        await ledenService.updateLidmaatschap(
          this.mvmnummer,
          this.lms.ID,
          this.lms
        );
        this.saving = false;
        this.hide();
      } catch (error) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: error,
          type: "error",
          customCloseBtnText: "Sluiten",
        });

        this.saving = false;
      }
    },
    openEnd: function () {
      this.vroegtijdigBeindigen = true;
    },
    end: async function () {
      try {
        if (!this.lms.redenenBeindiging) {
          this.$Simplert.open({
            title: "Error bij opslaan!",
            message: "Redenen beindiging is verplicht!",
            type: "error",
            customCloseBtnText: "Sluiten",
          });
          return;
        }
        this.saving = true;
        this.lms.vroegtijdigBeindigd = true;
        this.lms.vroegtijdigBeindigdOp = new Date();
        await ledenService.updateLidmaatschap(
          this.mvmnummer,
          this.lms.ID,
          this.lms
        );
        this.saving = false;
        this.sendUpdate();
        this.hide();
      } catch (error) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: error,
          type: "error",
          customCloseBtnText: "Sluiten",
        });

        this.saving = false;
      }
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal);
    this.modal.show();
  },

  unmounted() {
    this.modal.hide();
    this.modal = null;
  },

  created: async function () {
    if (this.data) {
      this.lms = this.data;
    }
  },
};
</script>


<style>
/* this is a pain */
.vdp-datepicker {
  width: 150px;
}

.vdp-datepicker input {
  width: 150px;
}

.table .table-dark th .vdp-datepicker {
  color: #000;
}
</style>
