export const getStatus = (lid) => {
  let status = "Niet Actief";

  if (!lid.gezin.ondertekendFormulier) {
    return status;
  }
  for (let lms of lid.lidmaatschap) {
    if (lms.vroegtijdigBeindigd) {
      continue;
    }
    // if current date is after lms.start && current date is before lms.eind
    if (
      new Date() >= new Date(lms.start).setHours(0, 0, 0, 0) &&
      new Date() <= new Date(lms.eind).setHours(23, 59, 59, 999)
    ) {
      status = "Actief";
      break;
    }
  }

  // if getEindDatum is in less than 2 months status is "Te Verlengen"
  if (
    new Date(getEindDatum(lid)) <
      new Date(new Date().setMonth(new Date().getMonth() + 2)) &&
    status === "Actief"
  ) {
    status = "Te Verlengen";
  }

  return status;
};

export const getEindDatum = (lid) => {
  // find the biggest eind
  let eindDatum = new Date(0);
  for (let lms of lid.lidmaatschap) {
    if (lms.vroegtijdigBeindigd) {
      continue;
    }
    if (new Date(lms.eind) > eindDatum) {
      eindDatum = new Date(new Date(lms.eind).setUTCHours(0, 0, 0, 0));
    }
  }
  return eindDatum;
};
