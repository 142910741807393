<template id="search">
  <div class="container">
    <h1>{{ title }}</h1>
    <form m v-on:submit.prevent="search">
      <div class="row">
        <div class="col-8 big-search">
          <div class="input-group mb-1">
            <div class="input-group-prepend">
              <div class="input-group-text">{{ prefix || "&nbsp;" }}</div>
            </div>
            <input type="text" class="form-control" ref="search" v-model="searchQuery" :disabled="searching" v-focus />
          </div>
        </div>
        <div class="col-4">
          <button type="button" class="btn btn-lg btn-success" v-on:click="search()" :disabled="searching">
            <i class="fas fa-search"></i><span class="d-none d-md-inline"> Zoeken</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="prefix">Type</label>
            </div>
            <select class="form-select" id="prefix" v-model="prefix">
              <option value="MVM">Leden</option>
              <option value="">Op naam zoeken</option>
            </select>
          </div>
        </div>
      </div>
    </form>
    <table class="table table-striped" v-if="results.length > 0">
      <thead>
        <tr>
          <th scope="col">Nummer</th>
          <th scope="col">Voornaam</th>
          <th scope="col">Famillienaam</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in results" v-bind:key="result.id">
          <td>MVM{{ result.mvmnummer }}</td>
          <td>{{ result.gezin.leden.find((l) => l.gezinsHoofd).voornaam }}</td>
          <td>{{ result.gezin.leden.find((l) => l.gezinsHoofd).achternaam }}</td>
          <td>
            <button class="btn btn-primary" v-on:click="foundCallback(result)">
              Open <i class="fas fa-external-link-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ledenService } from "../../_services/leden.service";
import { keyboardHelper } from "@/_helpers/keyboard.helper";

export default {
  template: "#search",
  components: {},
  props: ["title", "foundCallback"],
  data: function () {
    return {
      loading: false,
      searchQuery: "",
      prefix: "MVM",
      searching: false,
      printing: false,
      ticketCount: 1,
      results: [],
      customPrintText: "",
    };
  },

  methods: {
    hasPrinted: function (number) {
      const today = new Date().toLocaleDateString();

      if (!this.printed[today]) {
        return false;
      }

      return (this.printed[new Date().toLocaleDateString()] || []).includes(
        number
      );
    },
    search: async function () {
      try {
        this.searchQuery = keyboardHelper.superCrazyAzertyBarcodeFix(
          this.searchQuery
        );

        let searchPrefix = this.prefix;
        let seachTerm = this.searchQuery;

        this.results = [];

        if (seachTerm.length >= 11 && !isNaN(parseInt(seachTerm, 10))) {
          // we have a rijksregisternummer!
          seachTerm = seachTerm.substring(0, 11);

          const results = await ledenService.getAllForRRN(
            parseInt(seachTerm, 10)
          );
          results.length == 1
            ? this.foundCallback(results[0])
            : (this.results = results);

          this.searchQuery = ""; // reset for barcode scanners
        } else if (searchPrefix == "MVM") {
          const result = await ledenService.get(seachTerm);

          this.foundCallback(result);

          this.searchQuery = ""; // reset for barcode scanners
        } else {
          this.results = (await ledenService.getAll(
            0,
            `${searchPrefix}${seachTerm}`
          )).data;
        }
      } catch (e) {
        this.$Simplert.open({
          title: "Geen Resultaten!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }

      this.searching = false;
    },
  },
};
</script>

<style scoped>
.col-title {
  font-weight: bold;
  display: block;
}
</style>
