export  const opmerkingAlgemeen = {
    "Geen kinderen": "Geen kinderen tem 12 jaar",
    "Geen verblijfsvergunning": "Geen (of tijdelijke) verblijfsvergunning",
    "Geen verwijsbrief": "Geen verwijsbrief (of met onvoldoende informatie)",
    "Onduidelijke sociale situatie": "Onduidelijke sociale situatie",
    "Onduidelijke financiële situatie": "Onduidelijke financiële situatie (of geen gegevens beschikbaar)",
    "Geen gezinssamenstelling": "Geen gezinssamenstelling",
    "Negatieve score": "Negatieve (--) score bij één van extra voorwaarden",
    "Weigert inschrijvingsformulier": "Weigert inschrijvingsformulier te ondertekenen",
    "Crisishulp": "Crisishulp",
}