<template>
  <div class="container mb-4" id="app">
    <div v-if="showModal">
      <LidmaatschapEdit :hideModal="hideModal" :mvmnummer="id" :sendUpdate="sendUpdate" :data="modalData" />
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <span class="float-end">
          <button type="button" class="btn btn-success" v-on:click="verlengen()" :disabled="!kanVerlengen()">
            <i class="fas fa-plus-square" /> Verlengen
          </button>
        </span>
      </div>
    </div>
    <div class="row">
      <div :class="'card col-12 ' + getLMSState(lms)" v-for="(lms, i) in lidmaatschappen"
        v-bind:key="lms.start + lms.eind">
        <div class="card-body">
          <div class="row">
            <div class="col-10">
              <h5 class="card-title mb-3">
                {{
    new Date(lms.start).toLocaleDateString("nl-BE", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
}}
                tot
                {{ new Date(lms.eind).toLocaleDateString("nl-BE", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}}
              </h5>
              <p class="card-text">
                <strong>Vanaf: </strong>
                {{ new Date(lms.start).toLocaleDateString("nl-BE") }}
                <br />
                <strong>Tot: </strong>
                {{ new Date(lms.eind).toLocaleDateString("nl-BE") }}
                <br />
                <strong>Attest gezinssamenstelling inorde: </strong>
                <span v-bind:class="
  lms.gezinsSamenstellingInOrde ? '' : 'text-danger'
">{{ lms.gezinsSamenstellingInOrde ? "ja" : "nee" }}</span>
                <br />
                <strong>Vroegtijdig Beindigd: </strong>
                <span v-bind:class="lms.vroegtijdigBeindigd ? 'text-danger' : ''">{{ lms.vroegtijdigBeindigd ? "ja" :
    "nee"
}}</span>
                <br />
                <span v-if="lms.vroegtijdigBeindigd">
                  <strong>Vroegtijdig Beëindigen door: </strong>
                  {{ lms.redenenBeindiging }}
                </span>
              </p>
            </div>
            <div class="col-2 text-end">
              <button class="btn btn-primary mb-2" v-if="i == 0 && !lms.vroegtijdigBeindigd" :disabled="printing"
                @click="print(lms)">
                <i class="fas fa-print"></i> Print Lidkaart
              </button>
              <button class="btn btn-light" v-on:click="editLMS(lms)" v-if="!lms.vroegtijdigBeindigd">
                <i class="fas fa-edit"></i> Bewerken
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ledenService } from "../../_services/leden.service";
import { kaartPrint } from "../../_helpers/printer";
import { verlengen } from "../../_helpers/lidmaatschap.js";
import LidmaatschapEdit from "./LidmaatschapEdit.vue";

export default {
  props: ["id", "sendUpdate", "service"],
  components: {
    LidmaatschapEdit,
  },
  data: function () {
    return {
      lidmaatschappen: [],
      lid: {},
      printing: false,
      showModal: false,
    };
  },

  methods: {
    load: async function () {
      const data = await ledenService.get(this.id);
      this.lidmaatschappen = data.lidmaatschap;
      this.lid = data;
    },
    kanVerlengen: function () {
      let out = true;
      for (let lms of this.lidmaatschappen) {
        if (lms.vroegtijdigBeindigd) {
          continue;
        }

        if (
          new Date(lms.start) >
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        ) {
          out = false;
        }
      }

      return out;
    },
    verlengen: async function () {
      try {
        this.lidmaatschappen = verlengen(this.lidmaatschappen);
        await ledenService.addLidmaatachap(this.id, this.lidmaatschappen[0]);
      } catch (error) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: error,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }

      this.sendUpdate();
    },
    hideModal: function () {
      this.showModal = false;
      this.modalData = null;
      this.sendUpdate();
    },
    editLMS: function (d) {
      this.showModal = true;
      this.modalData = d;
    },
    getLMSState: function (lms) {
      if (
        lms.vroegtijdigBeindigd ||
        new Date() > new Date(lms.eind).setHours(23, 59, 59, 999)
      ) {
        return "nietactief";
      }

      // if start date is in the future set a seperate color state
      if (new Date() < new Date(lms.start).setHours(0, 0, 0, 0)) {
        return "toekomst";
      }

      if (
        new Date(lms.eind) <
        new Date(new Date().setMonth(new Date().getMonth() + 2))
      ) {
        return "verlengen";
      }

      if (
        new Date() >= new Date(lms.start).setHours(0, 0, 0, 0) &&
        new Date() <= new Date(lms.eind).setHours(23, 59, 59, 999)
      ) {
        return "actief";
      }

      return "nietactief";
    },
    print: async function (lms) {
      this.printing = true;

      try {
        await kaartPrint({
          mvmNummer: `MVM${this.lid.mvmnummer}`,
          naam: `${this.lid.gezin.leden.find((l) => l.gezinsHoofd).voornaam} ${this.lid.gezin.leden.find((l) => l.gezinsHoofd).achternaam
            }`,
          einddatum: new Date(lms.eind).toLocaleDateString("nl-BE"),
        });

        this.$Simplert.open({
          title: "Print verstuurd",
          message: "Print opdracht verstuurd voor printnen lidkaart.",
          type: "success",
          customCloseBtnText: "Sluiten",
          onClose: () => {
            this.$refs.search.focus();
          },
        });
      } catch (e) {
        this.$Simplert.open({
          title: "Error!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }

      this.printing = false;
    },
  },

  created: async function () {
    this.$parent.$on("update", this.load);

    this.loading = true;

    try {
      this.load();
      this.loading = false;
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }
  },
};
</script>
