export const getExactAge = (birthDate) => {
  const today = new Date();
  birthDate = new Date(birthDate);

  if (birthDate.getFullYear() < 1900) {
    return -1;
  }

  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};
