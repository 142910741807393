<template id="login">
  <div class="login bg">
    <div class="container vertical-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5 col-10 login-box">
            <div class="row justify-content-center">
              <img
                src="/images/logo.png"
                alt="Moeders voor Moeders"
                id="logo"
                class="col-12"
              />
            </div>
            <form m v-on:submit.prevent="login">
              <div class="form-group my-1">
                <label class="visually-hidden" for="username"
                  >Gebruikersnaam</label
                >
                <div class="input-group">
                  <div class="input-group-text">
                    <i class="fas fa-user"></i>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    name="username"
                    id="username"
                    v-model="username"
                    placeholder="Gebruikersnaam"
                  />
                </div>
              </div>
              <div class="form-group my-1">
                <label class="visually-hidden" for="password">Wachtwoord</label>
                <div class="input-group">
                  <div class="input-group-text">
                    <i class="fas fa-key"></i>
                  </div>
                  <input
                    type="password"
                    class="form-control"
                    name="password"
                    id="password"
                    v-model="password"
                    placeholder="Wachtwoord"
                  />
                </div>
              </div>
              <div class="form-group my-1 text-center">
                <button
                  class="btn btn-primary col-12"
                  :disabled="submitted"
                  type="submit"
                >
                  <i class="fas fa-sign-in-alt"></i> Login
                </button>
              </div>
            </form>
            <hr />
            <div class="row" v-if="window.PublicKeyCredential">
              <div class="col-12 text-center">
                <button
                  class="btn btn-info"
                  :disabled="submitted"
                  type="button"
                  @click="usbLogin"
                >
                  <i class="fas fa-key"></i> Gebruik USB Sleutel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authService } from "../_services/auth.service";
import { bufferDecode, bufferEncode } from "../_helpers/webauthn";

export default {
  template: "#login",
  components: {},
  data: function () {
    return {
      username: "",
      password: "",
      submitted: false,
      window,
    };
  },

  methods: {
    login: async function () {
      this.submitted = true;
      if (this.username && this.password) {
        try {
          await authService.login(this.username, this.password);
          this.$router.push("/home");
          this.submitted = false;
        } catch (e) {
          this.submitted = false;
          this.$Simplert.open({
            title: "Login error!",
            message: e,
            type: "error",
            customCloseBtnText: "Sluiten",
          });
        }
      }
    },
    usbLogin: async function () {
      this.submitted = true;
      try {
        if (!window.PublicKeyCredential) {
          throw "Deze browser ondersteunt geen USB-sleutels";
        }

        const challenge = await authService.fetchWebauthLoginChallenge();

        challenge.publicKey.challenge = bufferDecode(
          challenge.publicKey.challenge
        );
        challenge.publicKey.allowCredentials.forEach(function (listItem) {
          listItem.id = bufferDecode(listItem.id);
        });

        const assertion = await navigator.credentials.get({
          publicKey: challenge.publicKey,
        });

        let authData = assertion.response.authenticatorData;
        let clientDataJSON = assertion.response.clientDataJSON;
        let rawId = assertion.rawId;
        let sig = assertion.response.signature;
        let userHandle = assertion.response.userHandle;

        await authService.fetchWebauthLoginFinish({
          id: assertion.id,
          rawId: bufferEncode(rawId),
          type: assertion.type,
          response: {
            authenticatorData: bufferEncode(authData),
            clientDataJSON: bufferEncode(clientDataJSON),
            signature: bufferEncode(sig),
            userHandle: bufferEncode(userHandle),
          },
        });

        this.$router.push("/home");
      } catch (e) {
        this.$Simplert.open({
          title: "Login error!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }
      this.submitted = false;
    },
  },

  created: function () {},
};
</script>
