<template>
  <div class="container" id="app">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row">
        <div class="col-12 mb-2 mt-2">
          <span class="float-start">
            <button type="button" class="btn btn-light btn-lg" :disabled="saving" v-on:click="goBack()">
              <i class="fad fa-long-arrow-left" />Terug
            </button>
          </span>
        </div>
      </div>
      <h3 class="mb-3" v-if="id">Eenmalige Hulp</h3>
      <h3 class="mb-3" v-else>Inschrijving</h3>
      <div class="d-flex align-items-start">
        <div class="nav flex-column nav-pills pe-3 border-end" id="v-pills-tab" role="tablist"
          aria-orientation="vertical">
          <button class="nav-link active" id="v-pills-informatie-tab" data-bs-toggle="pill"
            data-bs-target="#v-pills-informatie" type="button" role="tab" aria-controls="v-pills-informatie"
            aria-selected="true">
            Informatie
          </button>
          <button class="nav-link" id="v-pills-gezin-tab" data-bs-toggle="pill" data-bs-target="#v-pills-gezin"
            type="button" role="tab" aria-controls="v-pills-gezin" aria-selected="false" :disabled="id <= 0">
            Gezin
          </button>
          <button class="nav-link" id="v-pills-goederen-tab" data-bs-toggle="pill" data-bs-target="#v-pills-goederen"
            type="button" role="tab" aria-controls="v-pills-goederen" aria-selected="false" v-if="id > 0">
            Goederen
          </button>
        </div>
        <div class="tab-content ms-3" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-informatie" role="tabpanel"
            aria-labelledby="v-pills-informatie-tab">
            <h3>Informatie</h3>
            <Informatie :id="id" :readEID="readEID" :sendUpdate="sendUpdate" :service="punctueleService"
              ref="informatie" />
          </div>
          <div class="tab-pane fade" id="v-pills-gezin" role="tabpanel" aria-labelledby="v-pills-gezin-tab"
            v-if="id > 0">
            <h3>Gezin</h3>
            <Gezin :id="id" :sendUpdate="sendUpdate" ref="gezin" :service="punctueleService" />
          </div>

          <div class="tab-pane fade" id="v-pills-goederen" role="tabpanel" aria-labelledby="v-pills-goederen-tab"
            v-if="id > 0">
            <h3>Goederen</h3>
            <Goederen :id="id" ref="goederen" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Informatie from "../_components/leden/Informatie.vue";
import Goederen from "./Goederen.vue";
import Gezin from "../_components/leden/Gezin.vue";

import { punctueleService } from "../../_services/punctuele.service";

export default {
  props: ["id", "readEID"],
  components: { Informatie, Goederen, Gezin },
  data: function () {
    return {
      filter: {},
      showFilter: false,
      klant: {},
      loading: true,
      originalData: "", //JSON stored here
      saving: false,
      gekregen: [],
      opmerking: "",
      info: {},
      materiaalTypes: {},
      contacten: [],
      newItemCount: 0,
      categoryForID: {},
      punctueleService,
    };
  },
  methods: {
    sendUpdate: function () {
      this.$emit("update");
    },
    hasChanges: function () {
      if (this.$refs.informatie.hasChanges()) {
        return true;
      }
      if (this.$refs.goederen && this.$refs.goederen.hasChanges()) {
        return true;
      }

      return false;
    },

    save: async function () {
      await this.$refs.informatie.save();
      await this.$refs.goederen.save();
    },
    goBack: function () {
      let vm = this;
      if (this.hasChanges()) {
        this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: function () {
            vm.save().then((ok) => {
              if (ok) {
                vm.$router.push({ name: "punctuele-search" });
              }
            });
          },
          onClose: function () {
            vm.$router.push({ name: "punctuele-search" });
          },
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Teruggaan zonder opslagen",
        });
      } else {
        vm.$router.push({ name: "punctuele-search" });
      }
    },
  },

  mounted: async function () {
    this.loading = true;

    try {
      if (this.id === 0) {
        // new

        this.loading = false;
        return;
      }

      this.loading = false;
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }
  },
};
</script>

<style
  src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
>

</style>
<style>
ul {
  padding-left: 20px;
}

.multiselect__element {
  display: block;
  background-color: #e4f1e4;
}

.materiaal-info h5 {
  font-size: 1.1rem;
}

.info {
  font-weight: bolder;
  font-size: 1.4rem;
}

/* this is a pain */
.vdp-datepicker {
  width: 150px;
}

.vdp-datepicker input {
  width: 150px;
}

.table .table-dark th .vdp-datepicker {
  color: #000;
}

#v-pills-tabContent {
  width: 100%;
}

.nav-link[disabled] {
  color: rgb(190, 190, 190);
}

.nav-link[disabled]:hover {
  color: rgb(190, 190, 190);
}
</style>
