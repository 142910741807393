<template>
  <div>
    <div class="fade modal" style="display: block" data-bs-backdrop="static" tabindex="1" ref="modal">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="!gezinslid || !gezinslid.ID">
              Gezinslid Toevoegen
            </h5>
            <h5 class="modal-title" v-else>
              {{ gezinslid.voornaam }} {{ gezinslid.famillienaam }} Bewerken
            </h5>
            <button type="button" class="btn-close" @click="hide" aria-label="Close"></button>
          </div>
          <form autocomplete="off" @submit="save">
            <div class="modal-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <label for="voornaam" class="form-label">Voornaam</label>
                  <input type="text" class="form-control" id="voornaam" v-model="gezinslid.voornaam" autocomplete="off"
                    :required="strict" />
                </div>
                <div class="col-12 mb-3">
                  <label for="famillienaam" class="form-label">
                    Famillienaam</label>
                  <input type="text" class="form-control" id="famillienaam" v-model="gezinslid.achternaam"
                    autocomplete="off" :required="strict" />
                </div>
                <div class="col-12 mb-3">
                  <label for="geboortedatum" class="form-label">Geboortedatum</label>
                  <datepicker id="geboortedatum" :format="'yyyy-MM-dd'" :language="nl" class="form-control"
                    v-model="gezinslid.geboortedatum" initial-view="year" :disabled-dates="{ from: new Date() }"
                    autocomplete="off" typeable="true" :required="strict"></datepicker>
                </div>
                <div class="col-12 mb-3">
                  <label for="relatie" class="form-label">Soort Relatie</label>
                  <select class="form-select" id="relatie" v-model="gezinslid.type" :required="strict">
                    <option value="" disabled>Selecteer een optie</option>
                    <option value="kind">Kind</option>
                    <option value="kleinkind">Kleinkind</option>
                    <option value="ouder">Ouder</option>
                    <option value="grootouder">Grootouder</option>
                    <option value="ander">Andere</option>
                  </select>
                </div>
                <div class="col-12 mb-3">
                  <label for="geslacht" class="form-label">Geslacht</label>
                  <select class="form-select" id="geslacht" v-model="gezinslid.geslacht" :required="strict">
                    <option value="" disabled>Selecteer een optie</option>
                    <option value="Man">Man</option>
                    <option value="Vrouw">Vrouw</option>
                  </select>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-success" :disabled="saving">
                <i class="fas fa-save" /> Opslaan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "@maartje/vuejs-datepicker";
import { Modal } from "bootstrap";

import { nl } from "@maartje/vuejs-datepicker/dist/locale";

export default {
  props: ["hideModal", "enummer", "data", "service", "strict", "sendUpdate"],
  components: {
    Datepicker,
  },
  data: function () {
    return {
      nl,
      gezinslid: {
        ID: 0,
        voornaam: "",
        famillienaam: "",
        geslacht: "",
      },
      saving: false,
      modal: null,
    };
  },
  methods: {
    hide: function () {
      this.modal.hide();
      this.hideModal();
    },
    save: async function (e) {
      e.preventDefault();
      try {
        this.saving = true;
        if (this.gezinslid.ID) {
          await this.service.updateGezinsLid(
            this.enummer,
            this.gezinslid.ID,
            this.gezinslid
          );
        } else {
          await this.service.addGezinsLid(this.enummer, this.gezinslid);
        }
        this.saving = false;
        this.sendUpdate();

        this.hide();
      } catch (error) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: error,
          type: "error",
          customCloseBtnText: "Sluiten",
        });

        this.saving = false;
      }
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal);
    this.modal.show();
  },

  unmounted() {
    this.modal.hide();
    this.modal = null;
  },

  created: async function () {
    if (this.data) {
      this.gezinslid = JSON.parse(JSON.stringify(this.data));
    }
  },
};
</script>


<style>
/* this is a pain */
.vdp-datepicker {
  width: 150px;
}

.vdp-datepicker input {
  width: 150px;
}

.table .table-dark th .vdp-datepicker {
  color: #000;
}
</style>
