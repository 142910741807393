<template>
  <div class="container" id="app">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row">
        <h3 class="col-12">
          <button
            type="button"
            class="btn btn-secondary"
            v-on:click="print()"
            :disabled="saving"
          >
            <i class="fas fa-print" /> Printen
          </button>
          <span class="float-end">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="addRow()"
              :disabled="saving || !canAddRow"
            >
              <i class="fas fa-plus-square" /> Rij Toevoegen
            </button>
            <button
              type="button"
              class="btn btn-success btn ms-2"
              v-on:click="save()"
              :disabled="saving || !hasChanges()"
            >
              <i class="far fa-save" /> Opslaan
            </button>
          </span>
        </h3>
      </div>

      <div>
        <table class="table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead class="table-dark">
            <tr>
              <th>Print</th>
              <th>Datum</th>
              <th>Aantal</th>
              <th>Item</th>
              <th>Ontvanger</th>
              <th>Maat</th>
              <th>Opmerking</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in gekregen"
              v-bind:key="item.ID || item.intID"
              v-bind:class="rowColors[getDate(item.datum)] ? 'stripe' : ''"
            >
              <td><input type="checkbox" v-model="item.print" /></td>
              <td style="width: 160px">
                <datepicker
                  :format="'yyyy-MM-dd'"
                  v-model="item.datum"
                  :language="nl"
                ></datepicker>
              </td>
              <td style="width: 70px">
                <input
                  v-model.number="item.aantal"
                  class="form-control"
                  type="number"
                  min="1"
                  style="width: 60px"
                />
              </td>
              <td>
                <multiselect
                  v-model="item.object"
                  track-by="ID"
                  label="selectNaam"
                  :options="objecten"
                  placeholder="Selecteer een"
                  @select="selectedNewItem($event, item)"
                  :allow-empty="false"
                  deselect-label=""
                  selectLabel=""
                ></multiselect>
              </td>
              <td>
                <span v-if="item.object && item.object.categorie.perPersoon"
                  ><multiselect
                    v-model="item.gezinsLid"
                    track-by="ID"
                    label="label"
                    :options="gezinsLeden"
                    placeholder="Selecteer een"
                    :allow-empty="false"
                    deselect-label=""
                    selectLabel=""
                  ></multiselect>
                </span>
              </td>
              <td>
                <span v-if="item.object && item.object.categorie.opMaat"
                  ><multiselect
                    v-model="item.maat"
                    track-by="ID"
                    label="naam"
                    :options="item.object.maten"
                    placeholder="Selecteer een"
                    @select="forceRender++"
                    @remove="item.maat = null"
                    :allow-empty="false"
                    deselect-label=""
                    selectLabel=""
                  ></multiselect>
                </span>
              </td>
              <td>
                <input
                  v-model="item.opmerking"
                  class="form-control"
                  placeholder="Opmerking"
                  style="width: 170px"
                />
              </td>
              <td style="width: 50px">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  v-on:click="removeRow(item)"
                >
                  <i class="fad fa-trash" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-none">{{ forceRender }}</div>

      <paginate
        :page-count="pageCount"
        :page-range="3"
        :margin-pages="0"
        containerClass="pagination"
        page-class="page-item"
        page-link-class="page-link"
        prev-text="Vorige"
        prev-class="page-item"
        prev-link-class="page-link"
        next-text="Volgende"
        next-class="page-item"
        next-link-class="page-link"
        :click-handler="pageClickCallback"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import { punctueleService } from "../../_services/punctuele.service";
import { getExactAge } from "../../_helpers/dates";
import { materiaalPrint, voedingPrint } from "../../_helpers/printer";

import { nl } from "@maartje/vuejs-datepicker/dist/locale";

// import { find } from "lodash";

import Datepicker from "@maartje/vuejs-datepicker";
import Multiselect from "vue-multiselect";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default {
  props: ["id"],
  components: {
    Datepicker,
    Multiselect,
  },
  data: function () {
    return {
      nl,
      klant: {},
      loading: true,
      originalGekregen: "", // JSON of gekregen
      saving: false,
      pageCount: 0,
      gekregen: [],
      objecten: [],
      deleted: [],
      gezinsLeden: [],
      pageNumber: 1,
      forceRender: 0,
      canAddRow: true,
    };
  },
  computed: {
    rowColors: function () {
      let out = {};

      let prevDate = null;
      let prevColor = true; // true = colored line
      for (let item of this.gekregen) {
        if (this.getDate(prevDate) != this.getDate(item.datum)) {
          prevColor = !prevColor;
          prevDate = this.getDate(item.datum);

          out[this.getDate(item.datum)] = prevColor;
        }
      }

      return out;
    },
  },
  methods: {
    selectedNewItem: function (selectedOption, item) {
      item.maat = null;
      if (
        selectedOption &&
        selectedOption.maten &&
        selectedOption.maten.length == 1
      ) {
        item.maat = selectedOption.maten[0];
      }
    },
    getDate: (d) => {
      const date = new Date(d);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    validate: function () {
      for (let gekregen of this.gekregen) {
        if (!gekregen.object || !gekregen.object.ID) {
          throw new Error("Gekregen niet ingevuld!");
        }
        if (gekregen.object.categorie.perPersoon) {
          if (!gekregen.gezinsLid || !gekregen.gezinsLid.ID) {
            throw new Error("Gezinslid niet ingevuld!");
          }
        }
        if (gekregen.object.categorie.opMaat) {
          if (!gekregen.maat || !gekregen.maat.ID) {
            throw new Error("Maat niet ingevuld!");
          }
        }
        if (!gekregen.datum) {
          throw new Error("Datum niet ingevuld!");
        }
      }
    },
    addRow: function () {
      if (!this.canAddRow) {
        return;
      }
      this.canAddRow = false;
      this.gekregen = [
        {
          aantal: 1,
          datum: new Date(),
          print: true,
          intID: Math.floor(Math.random() * 100000),
        },
      ].concat(this.gekregen);
      setTimeout(() => {
        this.canAddRow = true;
      }, 500);
    },
    removeRow: function (obj) {
      if (obj.ID > 0) {
        this.deleted.push(obj.ID);
      }
      this.gekregen = this.gekregen.filter((aObj) => aObj != obj);
    },
    print: async function () {
      try {
        await this.saveData();

        let items = [];
        let voedingItems = [];

        for (let gekregen of this.gekregen) {
          if (!gekregen.print) {
            continue;
          }
          if (gekregen.object.naam == "Voeding") {
            voedingItems.push({
              ticketCount: 0,
              eenmaligenNummer: `E${this.klant.enummer}`,
              code: "",
              typeVoeding: this.klant.gezin.typeVoeding,
              specialeVoeding: gekregen.opmerking,
              voornaam: this.klant.gezin.leden.find((l) => l.gezinsHoofd)
                .voornaam,
              naam: this.klant.gezin.leden.find((l) => l.gezinsHoofd)
                .achternaam,
              volwassenen: this.klant.gezin.leden.filter(
                (l) =>
                  getExactAge(l.geboortedatum) >= 12 ||
                  getExactAge(l.geboortedatum) == -1 // no age probably means adult
              ).length,
              kinderen: this.klant.gezin.leden.filter(
                (l) =>
                  getExactAge(l.geboortedatum) < 12 &&
                  getExactAge(l.geboortedatum) >= 0
              ).length,
            });
            continue;
          }
          console.log(gekregen.object);
          const item = {
            object: gekregen.object.selectNaam
              .replace(" - ", "\n")
              .replace("Materiaal ", ""),
            opmerking: gekregen.opmerking,
            maat: gekregen.maat ? gekregen.maat.naam : null,
            ontvanger: {},
            prijs: gekregen.object.prijs,
          };
          if (gekregen.gezinsLid) {
            item.ontvanger.naam = gekregen.gezinsLid.label;
            item.ontvanger.geslacht = gekregen.gezinsLid.geslacht;
          }

          items.push(item);
        }

        if (items.length < 1 && voedingItems.length < 1) {
          throw new Error("Niets om te printen");
        }

        if (items.length > 0) {
          await materiaalPrint({
            klant: {
              eenmaligenNummer: `E${this.klant.enummer}`,
              voornaam: this.klant.gezin.leden.find((l) => l.gezinsHoofd)
                .voornaam,
              naam: this.klant.gezin.leden.find((l) => l.gezinsHoofd)
                .achternaam,
            },
            items,
          });
        }

        if (voedingItems.length > 0) {
          await voedingPrint(voedingItems[0]); // more than one should be a mistake...
        }

        this.gekregen = this.gekregen.map((obj) => {
          obj.print = false;
          return obj;
        });
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij printen!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }
      this.saving = false;
    },
    saveData: async function () {
      // saves but can throw errors
      this.saving = true;
      this.validate();

      for (let gekregen of this.gekregen) {
        let resp;
        gekregen.maatID = gekregen.maat ? gekregen.maat.ID : null;
        if (!gekregen.ID) {
          resp = await punctueleService.addGekregen(this.id, gekregen);
        } else {
          resp = await punctueleService.updateGekregen(
            this.id,
            gekregen.ID,
            gekregen
          );
        }
        if (resp.status !== "ok") {
          throw new Error(resp.message);
        }
      }

      for (let id of this.deleted) {
        const resp = await punctueleService.removeGekregen(this.id, id);
        if (resp.status !== "ok") {
          throw new Error(resp.message);
        }
      }

      this.deleted = [];

      let printIndex = [];
      for (let i in this.gekregen) {
        if (this.gekregen[i].print) {
          printIndex.push(i);
        }
      }
      await this.loadPage(this.pageNumber);

      // re-set them to print!
      for (let i in printIndex) {
        this.gekregen[printIndex[i]].print = true;
      }
    },
    save: async function () {
      try {
        await this.saveData();
        this.$Simplert.open({
          title: "Opgeslagen!",
          message: "",
          type: "success",
          customCloseBtnText: "Sluiten",
        });
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }
      this.saving = false;
    },
    hasChanges: function () {
      if (this.deleted.length > 0) {
        return true;
      }

      if (
        JSON.stringify(
          // copy data
          JSON.parse(JSON.stringify(this.gekregen)).map((l) => {
            delete l.print; // print is a local property that never will be saved
            return l;
          })
        ) !== this.originalGekregen
      ) {
        return true;
      }

      return false;
    },
    pageClickCallback: async function (num) {
      if (this.hasChanges()) {
        this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: () => {
            this.save().then((ok) => {
              if (ok) {
                this.loadPage(num);
              }
            });
          },
          onClose: () => {
            this.loadPage(num);
          },
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Verdergaan zonder opslagen",
        });

        return;
      }
      this.loadPage(num);
    },
    loadPage: async function (num) {
      this.pageNumber = num;

      const { data, totalEntries } = await punctueleService.getGekregen(
        this.id,
        num
      );

      this.gekregen = data;

      this.gekregen = this.gekregen.map((g) => {
        g.object.selectNaam = this.composeObjectLabel(g.object);
        console.log(this.gezinsLeden);
        if (g.gezinsLid) {
          g.gezinsLid.label = g.gezinsLid.voornaam
            ? `${g.gezinsLid.voornaam} ${g.gezinsLid.achternaam}`
            : `Kind ${this.gezinsLeden.findIndex(
                (lid) => lid.ID == g.gezinsLid.ID
              )}`;
        }
        return g;
      });

      this.originalGekregen = JSON.stringify(this.gekregen);

      this.pageCount = Math.ceil(totalEntries / 20);
      if (this.pageCount < 1) {
        this.pageCount = 1;
      }
    },
    loadGezin: async function () {
      const data = await punctueleService.get(this.id);
      this.klant = data;
      this.gezinsLeden = data.gezin.leden;
      // add label to leden
      this.gezinsLeden = this.gezinsLeden.map((lid, i) => {
        lid.label = lid.voornaam
          ? `${lid.voornaam} ${lid.achternaam}`
          : `Kind ${i}`;
        return lid;
      });
    },
    composeObjectLabel: function (obj) {
      if (obj.categorie.naam == obj.naam) {
        return obj.naam;
      }

      // very ugly fix till i have a better way to deduplicate names that are a multiple
      if (
        obj.categorie.afdeling.toLowerCase() === "zeephuisje" &&
        obj.categorie.naam.toLowerCase() === "pakketten"
      ) {
        obj.categorie.naam = "";
      }
      return `${
        obj.categorie.afdeling !== obj.categorie.naam
          ? toTitleCase(obj.categorie.afdeling)
          : ""
      } ${obj.categorie.naam.replace("materiaal", "")} - ${obj.naam}`;
    },
  },

  created: async function () {
    this.loading = true;
    this.$parent.$on("update", this.loadGezin);
    try {
      this.objecten = await punctueleService.getObjectOptions();
      let objecten = this.objecten.map((obj) => {
        obj.selectNaam = this.composeObjectLabel(obj);
        if (obj.punctueleOrder === 0) {
          obj.punctueleOrder = 999999;
        }
        return obj;
      });

      // sort by punctueleOrder
      objecten = objecten.sort((a, b) => {
        if (a.punctueleOrder === b.punctueleOrder) return 0;
        if (a.punctueleOrder > b.punctueleOrder) return 1;
        return -1;
      });

      this.objecten = objecten;

      await this.loadGezin();
      await this.loadPage(1);
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }
    this.loading = false;
  },
};
</script>

<style
  src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>

