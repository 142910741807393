<template>
  <div>
    <div class="fade modal" style="display: block" data-bs-backdrop="static" tabindex="1" ref="modal">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Evenement Toevoegen</h5>
            <button type="button" class="btn-close" @click="hide" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form autocomplete="off">
              <div class="row">
                <div class="col-6 mb-3">
                  <label for="datum" class="form-label">Naam</label>
                  <input type="text" class="form-control" id="naam" v-model="data.naam" autocomplete="off" required/>
                </div>
                <div class="col-6 mb-3">
                  <label for="datum" class="form-label">Datum</label>
                  <datepicker id="datum" :format="'yyyy-MM-dd'" :language="nl" class="form-control"
                    v-model="data.datum" initial-view="year" autocomplete="off" typeable="true"></datepicker>
                </div>
                <div class="col-6 mt-4">
                  <div class="form-check form-switch">
                    <label class="form-check-label" for="perKind">Inschrijven per kind</label>
                    <input class="form-check-input" type="checkbox" id="perKind"
                      v-model="data.perKind" autocomplete="off" />
                  </div>
                </div>
                <div class="col-6" v-if="data.perKind">
                  <label for="maxKindLeeftijd" class="form-label">Maximale leeftijd</label>
                  <input type="number" class="form-control" id="maxKindLeeftijd" v-model="data.maxKindLeeftijd" autocomplete="off" />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer justify-content-end">
            <button type="button" class="btn btn-success" @click="save" :disabled="saving">
              <i class="fas fa-save" /> Opslaan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "@maartje/vuejs-datepicker";
import { evenementenService } from "../../_services/evenementen.service";
import { Modal } from "bootstrap";

import { nl } from "@maartje/vuejs-datepicker/dist/locale";

export default {
  props: ["hideModal"],
  components: {
    Datepicker,
  },
  data: function () {
    return {
      nl,
      saving: false,
      modal: null,
      data: {
        datum: new Date(),
        naam: "",
        perKind: false,
        maxKindLeeftijd: 12,
      }
    };
  },
  methods: {
    hide: function () {
      this.modal.hide();
      this.hideModal();
    },
    save: async function () {
      try {
        this.saving = true;
        await evenementenService.createEvenement(this.data);
        this.saving = false;
        this.hide();
      } catch (error) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: error,
          type: "error",
          customCloseBtnText: "Sluiten",
        });

        this.saving = false;
      }
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal);
    this.modal.show();
  },

  unmounted() {
    this.modal.hide();
    this.modal = null;
  },
};
</script>


<style>
/* this is a pain */
.vdp-datepicker {
  width: 150px;
}

.vdp-datepicker input {
  width: 150px;
}

.table .table-dark th .vdp-datepicker {
  color: #000;
}
</style>
