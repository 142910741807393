<template id="motivation">
  <div>
    <div class="btn-group" role="group">
      <input type="radio" class="btn-check" :name="id" :id="id + 'btnradio1'" autocomplete="off" v-model.number="value"
        value="1" />
      <label :class="'btn btn-outline-danger ' + (value == 1 ? 'active' : '')" :for="id + 'btnradio1'">--</label>

      <input type="radio" class="btn-check" :name="id" :id="id + 'btnradio2'" autocomplete="off" v-model.number="value"
        value="2" />
      <label :class="'btn btn-outline-warning ' + (value == 2 ? 'active' : '')" :for="id + 'btnradio2'">-</label>

      <input type="radio" class="btn-check" :name="id" :id="id + 'btnradio3'" autocomplete="off" v-model.number="value"
        value="3" />
      <label :class="'btn btn-outline-success ' + (value == 3 ? 'active' : '')" :for="id + 'btnradio3'">+</label>

      <input type="radio" class="btn-check" :name="id" :id="id + 'btnradio4'" autocomplete="off" v-model.number="value"
        value="4" />
      <label :class="'btn btn-outline-success ' + (value == 4 ? 'active' : '')" :for="id + 'btnradio4'">++</label>
    </div>
  </div>
</template>

<script>
export default {
  template: "#motivation",
  components: {},
  props: ["id", "score"],
  emits: ['changeValue'],

  data: function () {
    return {
      value: 0,
    };
  },

  created: function () {
    this.value = parseInt(this.score, 10);
  },

  watch: {
    value: function (val) {
      if (!val) return;
      this.$emit('changeValue', val);
    },
    score: function (val) {
      if (!val) return;
      this.value = parseInt(val, 10);
    },
  },

  methods: {},
};
</script>

<style scoped>
.btn-group label {
  width: 60px;
}
</style>
