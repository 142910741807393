export const CountryList = [
  "België",
  "Afghanistan",
  "Albanië",
  "Algerije",
  "Andorra",
  "Angola",
  "Antigua en Barbuda",
  "Argentinië",
  "Armenië",
  "Australië",
  "Azerbeidzjan",
  "Bahamas",
  "Bahrein",
  "Bangladesh",
  "Barbados",
  "Belize",
  "Benin",
  "Bezette Palestijnse gebieden",
  "Bhutan",
  "Birma",
  "Bolivië",
  "Bosnië en Herzegovina",
  "Botswana",
  "Brazilië",
  "Brunei Darussalam",
  "Bulgarije",
  "Burkina Faso",
  "Burundi",
  "Cambodja",
  "Canada",
  "Centraal-Afrika",
  "Chili",
  "China",
  "Colombia",
  "Comoren",
  "Costa Rica",
  "Cuba",
  "Cyprus",
  "Dahomey",
  "Democratische Republiek Congo",
  "Denemarken",
  "Djibouti",
  "Dominica",
  "Dominicaanse Republiek",
  "Duitsland",
  "Ecuador",
  "Egypte",
  "El Salvador",
  "Equatoriaal-Guinea",
  "Eritrea",
  "Estland",
  "Ethiopië",
  "Fiji",
  "Filipijnen",
  "Finland",
  "Frankrijk",
  "Gabon",
  "Gambia",
  "Georgië",
  "Ghana",
  "Grenada",
  "Griekenland",
  "Guatemala",
  "Guinea",
  "Guinee-Bissau",
  "Guyana",
  "Haïti",
  "Honduras",
  "Hongarije",
  "Ierland",
  "Ijsland",
  "India",
  "Indonesië",
  "Irak",
  "Iran",
  "Israël",
  "Italië",
  "Ivoorkust",
  "Jamaica",
  "Japan",
  "Jemen",
  "Joegoslavië",
  "Jordanië",
  "Kaapverdië",
  "Kameroen",
  "Kampuchea",
  "Kazachstan",
  "Kenia",
  "Khmerische Rep. Cambodja",
  "Kirgistan",
  "Kiribati",
  "Koeweit",
  "Kongo",
  "Kosovo",
  "Kroatië",
  "Laos",
  "Lesotho",
  "Letland",
  "Libanon",
  "Liberia",
  "Libië",
  "Liechtenstein",
  "Litouwen",
  "Luxemburg",
  "Macedonië",
  "Madagascar",
  "Malawi",
  "Maldiven",
  "Maleisië",
  "Mali",
  "Malta",
  "Marokko",
  "Marshalleilanden",
  "Mauritanië",
  "Mauritius",
  "Mexico",
  "Micronesië",
  "Moldavië",
  "Monaco",
  "Mongolië",
  "Montenegro",
  "Mozambique",
  "Myanmar",
  "Namibië",
  "Nauru",
  "Nederland",
  "Nepal",
  "Nicaragua",
  "Nieuw-Zeeland",
  "Niger",
  "Nigeria",
  "Noord-Jemen",
  "Noord-Korea",
  "Noord-Vietnam",
  "Noorwegen",
  "Oeganda",
  "Oekraïne",
  "Oezbekistan",
  "Oman",
  "Onbekend",
  "Oost-Duitsland",
  "Oost-Timor",
  "Oostenrijk",
  "Opper-Volta",
  "Pakistan",
  "Palau",
  "Panama",
  "Papoea-Nieuw-Guinea",
  "Paraguay",
  "Peru",
  "Polen",
  "Portugal",
  "Qatar",
  "Republiek Congo",
  "Roemenië",
  "Rusland",
  "Rwanda",
  "Saint Kitts en Nevis",
  "Saint Vincent en de Grenadines",
  "Salomonseilanden",
  "Samoa",
  "San Marino",
  "São Tomé en Príncipe",
  "Saoedi-Arabië",
  "Senegal",
  "Servië",
  "Servië en Montenegro",
  "Seychellen",
  "Sierra Leone",
  "Singapore",
  "Sint Lucia",
  "Slovenië",
  "Slowakije",
  "Soedan",
  "Somalië",
  "Sovjet-Unie",
  "Spanje",
  "Sri Lanka",
  "Suriname",
  "Swaziland",
  "Syrië",
  "Tadzjikistan",
  "Tanzanië",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad en Tobago",
  "Tsjaad",
  "Tsjechië",
  "Tsjecho-Slowakije",
  "Tunesië",
  "Turkije",
  "Turkmenistan",
  "Tuvalu",
  "Uruguay",
  "Vanuatu",
  "Vaticaanstad",
  "Venezuela",
  "Verenigd Koninkrijk",
  "Verenigde Arabische Emiraten",
  "Verenigde Staten",
  "Vietnam",
  "West-Duitsland",
  "Wit-Rusland",
  "Zaïre",
  "Zambia",
  "Zimbabwe",
  "Zuid Korea",
  "Zuid-Afrika",
  "Zuid-Jemen",
  "Zuid-Soedan",
  "Zuid-Vietnam",
  "Zweden",
  "Zwitserland",
];
