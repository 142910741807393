<template>
  <div>
    <div
      class="fade modal"
      style="display: block"
      data-bs-backdrop="static"
      tabindex="1"
      ref="modal"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ gezinslid.voornaam }} {{ gezinslid.achternaam }} - DANGER ZONE
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="hide"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <button type="button" class="btn btn-danger" @click="remove()">
              <i class="fad fa-trash" /> Verwijder Gezin
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { punctueleService } from "../../../_services/punctuele.service";

export default {
  props: ["hideModal", "gezinslid", "id"],
  data: function () {
    return {
      modal: null,
    };
  },
  methods: {
    hide: function () {
      this.modal.hide();
      this.hideModal();
    },
    remove: function () {
      let vm = this;
      this.$Simplert.open({
        title: `Ben je zeker?`,
        message: `Ben je zeker dat je ${this.gezinslid.voornaam} ${this.gezinslid.achternaam} wil verwijderen?`,
        type: "warning",
        useConfirmBtn: true,
        onConfirm: () => {
          punctueleService.remove(this.id).then(() => {
            vm.hide();
            vm.$router.push({ name: "punctuele-search" });
            console.log("Gezin verwijderd");
          });
        },
        onClose: () => {
          this.hide();
        },
        customConfirmBtnClass: "btn btn-warning",
        customConfirmBtnText: "Verwijder",
        customCloseBtnText: "Annuleren",
      });
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal);
    this.modal.show();
  },

  unmounted() {
    this.modal.hide();
    this.modal = null;
  },

  created: async function () {},
};
</script>


<style>
/* this is a pain */
.vdp-datepicker {
  width: 150px;
}
.vdp-datepicker input {
  width: 150px;
}
.table .table-dark th .vdp-datepicker {
  color: #000;
}
</style>
