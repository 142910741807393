<template>
  <div class="container mb-4" id="app">
    <div v-if="showModal">
      <GezinEdit :hideModal="hideModal" :enummer="id" :data="modalData" :service="service" :strict="strict"
        :sendUpdate="sendUpdate" />
    </div>
    <div class="row">
      <h3 class="col-12">
        <span class="float-end">
          <button type="button" class="btn btn-success" v-on:click="addGezinslid()" :disabled="!service.addGezinsLid">
            <i class="fas fa-plus-square" /> Gezinslid Toevoegen
          </button>
        </span>
      </h3>
    </div>
    <div>
      <table class="table">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <thead class="table-dark">
          <tr>
            <th>Voornaam</th>
            <th>Famillienaam</th>
            <th>Soort relatie</th>
            <th>Leeftijd</th>
            <th>Geslacht</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(lid, i) of gezinsleden" :key="lid.ID">
            <td>{{ lid.voornaam || `Kind ${i}` }}</td>
            <td>{{ lid.achternaam }}</td>
            <td>{{ lid.type }}</td>
            <td>{{ calculateAge(lid.geboortedatum) || "onbekend" }}</td>
            <td>{{ lid.geslacht || "onbekend" }}</td>
            <td>
              <span v-if="!lid.gezinsHoofd">
                <button type="button" class="btn btn-success me-2" v-on:click="editGezinslid(lid)"
                  :disabled="!service.updateGezinsLid">
                  <i class="fas fa-pencil" />
                </button>
                <button type="button" class="btn btn-outline-danger" v-on:click="removeGezinslid(lid)"
                  :disabled="!service.deleteGezinsLid">
                  <i class="fad fa-trash" />
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import GezinEdit from "./GezinEdit.vue";

export default {
  props: ["id", "sendUpdate", "service", "strict"],
  components: {
    GezinEdit,
  },
  data: function () {
    return {
      gezinsleden: [],
      originalData: "[]",
      showModal: false,
      modalData: null,
    };
  },
  methods: {
    calculateAge: function (birthday) {
      if (!birthday) {
        return "";
      }
      const birthDate = new Date(birthday);
      if (birthDate.getFullYear() === 1) {
        return "";
      }
      var ageDifMs = Date.now() - birthDate.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.floor(ageDate.getUTCFullYear() - 1970) + " jaar";
    },
    hideModal: function () {
      this.showModal = false;
      this.modalData = null;
    },
    validate: function () { },
    addGezinslid: function () {
      this.showModal = true;
    },
    editGezinslid: function (d) {
      this.showModal = true;
      this.modalData = d;
    },
    removeGezinslid: function (d) {
      this.$Simplert.open({
        title: `Ben je zeker?`,
        message: `Ben je zeker dat je ${d.voornaam} ${d.achternaam} wil verwijderen?`,
        type: "warning",
        useConfirmBtn: true,
        onConfirm: () => {
          this.service.deleteGezinsLid(this.id, d.ID).then(() => {
            this.load();
          });
        },
        customConfirmBtnClass: "btn btn-danger",
        customConfirmBtnText: "Verwijder",
        customCloseBtnText: "Annuleren",
      });
    },
    load: async function () {
      if (this.id == 0) {
        return;
      }
      const data = await this.service.get(this.id);
      this.gezinsleden = data.gezin.leden;
    },
    save: async function () {
      try {
        this.validate();
        this.saving = true;

        this.$Simplert.open({
          title: "Opgeslagen!",
          message: "",
          type: "success",
          customCloseBtnText: "Sluiten",
        });
        this.originalData = JSON.stringify(
          { gekregen: this.gekregen, opmerking: this.opmerking },
          getCircularReplacer()
        );

        this.saving = false;
        return true;
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
        return false;
      }
    },
  },

  created: async function () {
    this.$parent.$on("update", this.load);

    this.loading = true;

    try {
      this.load();
      this.loading = false;
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }
  },
};

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
</script>

<style
  src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
>

</style>
<style>
.table .table-dark th .vdp-datepicker {
  color: #000;
}

.form-control>div>input {
  width: 100%;
  border: none;
}

.rrn-bad {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.rrn-bad:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6) !important;
}

.rrn-good:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(36, 156, 5, 0.6) !important;
}
</style>
