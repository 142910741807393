import Vue from "vue";
import { createRouter, createWebHistory } from 'vue-router';
import MateriaalSearch from "../views/materiaal/Search.vue";
import MateriaalDetails from "../views/materiaal/Details.vue";
import VoedingSearch from "../views/voeding/Search.vue";
import VoedingDetails from "../views/voeding/Details.vue";
import SinterklaasSearch from "../views/sinterklaas/Search.vue";
import OnthaalSearch from "../views/onthaal/Search.vue";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Nav from "../views/Nav.vue";
import ZeephuisjeSearch from "../views/zeephuisje/Search.vue";
import ZeephuisjeDetails from "../views/zeephuisje/Details.vue";
import PunctueleSearch from "../views/punctuele/Search.vue";
import PunctueleDetails from "../views/punctuele/Details.vue";
import Gebruikers from "../views/beheer/Gebruikers.vue";
import LedenSearch from "../views/leden/Search.vue";
import LedenDetails from "../views/leden/Details.vue";
import EvenementenSearch from "../views/evenementen/Search.vue";
import EvenementenDetails from "../views/evenementen/Details.vue";
import MarktenBeheer from "../views/markten/Beheer.vue";
import MarktenDetails from "../views/markten/Details.vue"
import MarktenScan from "../views/markten/Scan.vue"

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    redirect: '/home' 
  },
  {
    path: "/mvm",
    name: "nav",
    component: Nav,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
      },
      {
        path: "/materiaal/search",
        name: "materiaal-search",
        component: MateriaalSearch,
      },
      {
        path: "/materiaal/details/:id",
        name: "materiaal-details",
        component: MateriaalDetails,
        props: true,
      },
      {
        path: "/voeding/search",
        name: "voeding-search",
        component: VoedingSearch,
      },
      {
        path: "/voeding/details/:id",
        name: "voeding-details",
        component: VoedingDetails,
        props: true,
      },
      {
        path: "/sinterklaas/search",
        name: "sinterklaas-search",
        component: SinterklaasSearch,
      },
      {
        path: "/onthaal/search",
        name: "onthaal-search",
        component: OnthaalSearch,
      },
      {
        path: "/zeephuisje/search",
        name: "zeephuisje-search",
        component: ZeephuisjeSearch,
      },
      {
        path: "/zeephuisje/details/:id",
        name: "zeephuisje-details",
        component: ZeephuisjeDetails,
        props: true,
      },
      {
        path: "/punctuele/search",
        name: "punctuele-search",
        component: PunctueleSearch,
      },
      {
        path: "/punctuele/details/:id",
        name: "punctuele-details",
        component: PunctueleDetails,
        props: true,
      },
      {
        path: "/beheer/gebruikers",
        name: "gebruikers",
        component: Gebruikers,
      },
      {
        path: "/leden/search",
        name: "leden-search",
        component: LedenSearch,
      },
      {
        path: "/leden/details/:id",
        name: "leden-details",
        component: LedenDetails,
        props: true,
      },
      {
        path: "/evenementen/search",
        name: "evenementen-search",
        component: EvenementenSearch,
      },
      {
        path: "/evenementen/details/:evenementid/:id",
        name: "evenementen-details",
        component: EvenementenDetails,
        props: true,
      },
      {
        path: "/markten/beheer",
        name: "markten-beheer",
        component: MarktenBeheer,
      },
      {
        path: "/martken/details/:id",
        name: "markten-details",
        component: MarktenDetails,
        props: true,
      },
      {
        path: "/markten/scan",
        name: "markten-scan",
        component: MarktenScan,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

Vue.use(router);

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

export default router;
