export const bufferDecode = function (value) {
  return Uint8Array.from(atob(value), (c) => c.charCodeAt(0));
};

export const bufferEncode = function (value) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};
