<template id="gebruikers">
  <div class="container">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <h1>Gebruikers</h1>
      <div class="container p-0">
        <table class="table">
          <colgroup>
            <col />
            <col />
          </colgroup>
          <thead class="table-dark">
            <tr>
              <th>Gebruikersnaam</th>
              <th>Naam</th>
              <th>USB Login</th>
              <th>Opties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="gebruiker in gebruikers" v-bind:key="gebruiker.ID">
              <td>
                {{ gebruiker.username }}
              </td>
              <td>
                {{ gebruiker.name }}
              </td>
              <td>
                {{
                  gebruiker.webauthnCredentials.length > 0
                    ? `Ja: ${gebruiker.webauthnCredentials.length} sleutel(s) bekend`
                    : "Nee"
                }}
              </td>
              <td>
                <button
                  class="btn btn-info"
                  :disabled="submitted"
                  type="button"
                  @click="makeUSBLogin(gebruiker.username)"
                >
                  <i class="fas fa-key"></i> USB Sleutel aanmaken
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { beheerService } from "../../_services/beheer.service";
import { bufferDecode, bufferEncode } from "../../_helpers/webauthn";
import { authService } from "../../_services/auth.service";

export default {
  template: "#gebruikers",
  components: {},
  data: function () {
    return {
      loading: false,
      gebruikers: [],
    };
  },

  methods: {
    makeUSBLogin: async function (username) {
      if (!window.PublicKeyCredential) {
        alert("Deze browser ondersteunt geen USB-sleutels");
        return;
      }

      const challenge = await authService.fetchWebauthRegisterChallenge(
        username
      );

      challenge.publicKey.challenge = bufferDecode(
        challenge.publicKey.challenge
      );
      challenge.publicKey.user.id = bufferDecode(challenge.publicKey.user.id);

      const credential = await navigator.credentials.create({
        publicKey: challenge.publicKey,
      });

      const attestationObject = credential.response.attestationObject;
      const clientDataJSON = credential.response.clientDataJSON;
      const rawId = credential.rawId;

      await authService.fetchWebauthRegisterFinish(username, {
        id: credential.id,
        rawId: bufferEncode(rawId),
        type: credential.type,
        response: {
          attestationObject: bufferEncode(attestationObject),
          clientDataJSON: bufferEncode(clientDataJSON),
        },
      });

      this.load();
    },
    load: async function () {
      this.loading = true;
      try {
        this.gebruikers = await beheerService.getGebruikers();
      } catch (e) {
        this.$Simplert.open({
          title: "Error!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });

        return;
      }
      this.loading = false;
    },
  },
  created: function () {
    this.load();
  },
};
</script>

<style scoped>
.col-title {
  font-weight: bold;
  display: block;
}
</style>
