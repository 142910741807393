import config from "config";
import { authHeader } from "../_helpers/auth-header";
import { authService } from "../_services/auth.service";
import { getCircularReplacer } from "../_helpers/json";

const lookUpNumber = (mvmNummer) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  if (mvmNummer.toString().indexOf("MVM") !== 0) {
    mvmNummer = "MVM" + mvmNummer;
  }

  return fetch(
    `${config.apiUrl}/v1/materiaal/klant/${mvmNummer}`,
    requestOptions
  ).then(handleResponse);
};

const saveForNumber = (mvmNummer, data) => {
  if (mvmNummer.toString().indexOf("MVM") !== 0) {
    mvmNummer = "MVM" + mvmNummer;
  }

  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/materiaal/klant/${mvmNummer}`,
    requestOptions
  ).then(handleResponse);
};

const getObjectOptions = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/materiaal/objects`, requestOptions).then(
    handleResponse
  );
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

const deleteRow = (mvmNummer, catID, id) => {
  if (mvmNummer.toString().indexOf("MVM") !== 0) {
    mvmNummer = "MVM" + mvmNummer;
  }
  const requestOptions = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/v1/materiaal/klant/${mvmNummer}/gekregen/${catID}/${id}`,
    requestOptions
  ).then(handleResponse);
};

const saveRow = (mvmNummer, data) => {
  if (mvmNummer.toString().indexOf("MVM") !== 0) {
    mvmNummer = "MVM" + mvmNummer;
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/materiaal/klant/${mvmNummer}/gekregen/${
      data.object.categorieID
    }/${data.ID ? data.ID : 0}`,
    requestOptions
  ).then(handleResponse);
};

const lookPageUpNumberAndCategorie = (mvmNummer, catID, page, filters = {}) => {
  if (mvmNummer.toString().indexOf("MVM") !== 0) {
    mvmNummer = "MVM" + mvmNummer;
  }
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  let filterStr = "";
  for (let key in filters) {
    filterStr += `&${key}=${filters[key]}`;
  }

  return fetch(
    `${config.apiUrl}/v1/materiaal/klant/${mvmNummer}/gekregen/${catID}?page=${page}&page_size=10${filterStr}`,
    requestOptions
  ).then(handlePagedResponse);
};

const handlePagedResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    const totalEntries = parseInt(
      response.headers.get("Num-Total-Entries"),
      10
    );

    return { data, totalEntries };
  });
};

export const materiaalService = {
  lookUpNumber,
  getObjectOptions,
  saveForNumber,
  lookPageUpNumberAndCategorie,
  saveRow,
  deleteRow,
};
