<template>
  <div class="container" id="app">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row">
        <div class="col-12 mb-2 mt-2">
          <span class="float-start">
            <button type="button" class="btn btn-light btn-lg" :disabled="saving" v-on:click="goBack()">
              <i class="fad fa-long-arrow-left" />Terug
            </button>
          </span>
          <span class="float-end">
            <div class="btn-group">
              <button type="button" class="btn btn-info btn-lg" v-on:click="pakketVandaag()">
                <i class="far fa-box-heart" /> Pakket Vandaag
              </button>
            </div>
            &nbsp;
            <button type="button" class="btn btn-success btn-lg" v-on:click="save()"
              :disabled="saving || !hasChanges()">
              <i class="far fa-save" /> Opslaan
            </button>
          </span>
        </div>
      </div>
      <h3>Info</h3>
      <div class="row mb-3 materiaal-info" v-if="getStatus(klant) == 'Niet Actief'">
        <div class="col-3 mb-3">
          <h5>Status</h5>
          <p class="info" style="color: red">Niet Actief</p>
        </div>
      </div>
      <div class="row mb-3 materiaal-info" v-if="klant.nietBetalen">
        <div class="col-3 mb-3">
          <h5>Info</h5>
          <p class="info" style="color: red">Niet Betalen</p>
        </div>
      </div>
      <div class="row mb-3 materiaal-info">
        <div class="col-5 mb-3">
          <h5>Naam</h5>
          <p class="info">
            {{ klant.gezin.leden.find((l) => l.gezinsHoofd).voornaam }}
            {{ klant.gezin.leden.find((l) => l.gezinsHoofd).achternaam }}
          </p>
        </div>
        <div class="col-4 mb-3">
          <h5>MVM Nummer</h5>
          <p class="info">MVM{{ klant.mvmnummer }}</p>
        </div>
        <div class="col-3 mb-3">
          <h5>Woonplaats</h5>
          <p class="info">
            {{ klant.gezin.postcode }} {{ klant.gezin.gemeente }}
          </p>
        </div>
        <div class="col-4">
          <h5>Gezinsleden</h5>
          <div class="gezinsleden" v-html="klant.huishouden"></div>
        </div>
        <div class="col-4">
          <h5>Opmerking</h5>
          <textarea class="form-control" v-model="info.opmerking" rows="2"></textarea>
        </div>
        <div class="col-4">
          <h5>Totalen</h5>
          <ul>
            <div v-for="total in totals" v-bind:key="total.naam">
              <li v-if="total.naam !== 'Pakket' && total.naam !== 'Luiers'">
                {{ total.naam }}: {{ total.aantal }}
                <span v-if="total.naam == 'Overtrek 2 Personen'">/ 2</span>
                <span v-if="total.naam == 'Overtrek 1 Persoon'">/ {{ 2 * aantalKinderen }}</span>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div id="accordion" class="accordion">
            <div v-for="categorie in categoryForID" v-bind:key="categorie.ID">
              <div class="accordion-item">
                <div v-bind:id="'cat' + categorie.ID + 'heading'" class="accordion-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 font-weight-bold">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      v-bind:data-bs-target="'#cat' + categorie.ID" aria-expanded="false"
                      v-bind:aria-controls="'cat' + categorie.ID">
                      {{ categorie.naam }}
                    </button>
                  </h6>
                </div>
                <div v-bind:id="'cat' + categorie.ID" v-bind:aria-labelledby="'cat' + categorie.ID"
                  data-bs-parent="#accordion" class="collapse">
                  <div class="card-body">
                    <h3 class="col-12">
                      <span class="float-end mb-2">
                        <button type="button" class="btn btn-success" v-on:click="addRow(categorie.ID)">
                          <i class="fas fa-plus-square" /> Rij Toevoegen
                        </button>
                      </span>
                    </h3>
                    <table class="table">
                      <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col v-if="categoryForID[categorie.ID].opMaat" />
                        <col />
                        <col />
                      </colgroup>
                      <thead class="thead-dark">
                        <tr>
                          <th>Datum</th>
                          <th>Aantal</th>
                          <th>Item</th>
                          <th v-if="categoryForID[categorie.ID].opMaat">
                            Maat
                          </th>
                          <th>Opmerking</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in gekregen[categorie.ID]" v-bind:key="item.ID" v-bind:class="
                          rowColors[categorie.ID][getDate(item.datum)]
                            ? 'stripe'
                            : ''
                        ">
                          <td style="width: 160px">
                            <datepicker :format="'yyyy-MM-dd'" v-model="item.datum" :language="nl"></datepicker>
                          </td>
                          <td style="width: 70px">
                            <input v-model.number="item.aantal" @change="updateCount++" class="form-control"
                              type="number" min="1" style="width: 60px" />
                          </td>
                          <td>
                            <multiselect v-model="item.object" track-by="naam" label="naam"
                              :options="categoryForID[categorie.ID].opties" placeholder="Selecteer een"
                              @select="selectedNewItem($event, item)" :allow-empty="false" deselect-label=""
                              selectLabel=""></multiselect>
                          </td>
                          <td v-if="categoryForID[categorie.ID].opMaat">
                            <multiselect v-model="item.maat" track-by="naam" label="naam"
                              :options="(item.object || []).maten || []" placeholder="Selecteer een"
                              @remove="item.maat = null && forceUpdate()" @select="forceUpdate()" :allow-empty="false"
                              deselect-label="" selectLabel=""></multiselect>
                          </td>
                          <td>
                            <input v-model="item.opmerking" @change="updateCount++" class="form-control"
                              placeholder="Opmerking" style="width: 170px" />
                          </td>
                          <td style="width: 50px">
                            <button type="button" class="btn btn-outline-danger"
                              v-on:click="removeRow(categorie.ID, item)">
                              <i class="fad fa-trash" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <paginate :page-count="pageCount[categorie.ID]" :page-range="3" :margin-pages="0"
                      containerClass="pagination" page-class="page-item" page-link-class="page-link" prev-text="Vorige"
                      prev-class="page-item" prev-link-class="page-link" next-text="Volgende" next-class="page-item"
                      next-link-class="page-link" :click-handler="
                        (num) => pageClickCallback(num, categorie.ID)
                      ">
                    </paginate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none">Ugly hack here: {{ updateCount }}</div>
  </div>
</template>

<script>
import { find } from "lodash";

import { nl } from "@maartje/vuejs-datepicker/dist/locale";
import Datepicker from "@maartje/vuejs-datepicker";
import Multiselect from "vue-multiselect";

import { ledenService } from "../../_services/leden.service";
import { zeephuisjeService } from "../../_services/zeephuisje.service";
import { getCircularReplacer } from "../../_helpers/json";
import { needsLuiers } from "../../_helpers/zeephuisje";
import { getStatus } from "../../_helpers/leden";
import { getExactAge } from "../../_helpers/dates";

export default {
  props: ["id"],
  components: {
    Datepicker,
    Multiselect,
  },
  data: function () {
    return {
      nl,
      getStatus,
      klant: {},
      loading: true,
      originalData: "", //JSON stored here
      originalGekregen: {}, //catID: JSON of gekregen
      saving: false,
      pageCount: {}, // catID: count
      gekregen: {}, // catID: []
      categoryForID: {},
      updateCount: 0, // hack to render page update
      info: {
        opmerking: "",
      },
      objecten: [],
      contacten: [],
      deleted: [],
      pageNumber: {}, // catID: count
      invisibleTotalen: {}, // objectID: count
    };
  },

  computed: {
    aantalKinderen: function () {
      return this.klant.gezin.leden.filter(
        (l) =>
          getExactAge(l.geboortedatum) < 18 && getExactAge(l.geboortedatum) >= 0
      ).length;
    },
    totals: function () {
      let totalen = {};

      for (let catID in this.gekregen) {
        if (!this.gekregen[catID]) {
          continue;
        }
        for (let entry of this.gekregen[catID]) {
          // if less than a year ago add
          if (
            new Date(entry.datum).getTime() >
            new Date().getTime() - 1000 * 60 * 60 * 24 * 365
          ) {
            if (!entry.objectID && !entry.object) {
              continue
            } else if (!entry.objectID && entry.object) {
              entry.objectID = entry.object.ID;
            }

            if (!totalen[entry.objectID]) {
              totalen[entry.objectID] = 0;
            }
            totalen[entry.objectID] += entry.aantal;
          }
        }
      }

      console.log(this.invisibleTotalen);
      for (let objectID in this.invisibleTotalen) {
        if (!this.invisibleTotalen[objectID]) {
          continue;
        }
        console.log(this.invisibleTotalen);
        if (!totalen[objectID]) {
          totalen[objectID] = 0;
        }
        totalen[objectID] += this.invisibleTotalen[objectID];
      }

      let totalsArray = [];
      for (let id in totalen) {
        console.log(id);
        totalsArray.push({
          naam: (find(this.objecten, { ID: parseInt(id) }) || {}).naam,
          aantal: totalen[id],
        });
      }

      return totalsArray.sort((a, b) => (a || {}).naam > (b || {}).naam);
    },
    rowColors: function () {
      let out = {};

      let prevDate = null;
      let prevColor = true; // true = colored line
      for (let key in this.gekregen) {
        out[key] = [];
        for (let item of this.gekregen[key]) {
          let date = this.getDate(item.datum);
          if (prevDate !== date) {
            prevColor = !prevColor;
          }
          prevDate = date;
          out[key][date] = prevColor;
        }
      }

      return out;
    },
  },
  methods: {
    pakketVandaag: function () {
      // Get ID for category Pakketten
      let catID = find(this.categoryForID, { naam: "Pakketten" }).ID;

      for (let row of this.gekregen[catID]) {
        if (
          new Date(row.datum).getMonth() === new Date().getMonth() &&
          new Date(row.datum).getFullYear() === new Date().getFullYear() &&
          row.object &&
          row.object.naam === "Pakket"
        ) {
          this.$Simplert.open({
            title: "Error!",
            message: "Klant heeft deze maand al een pakket gehad",
            type: "error",
            customCloseBtnText: "sluiten",
          });

          return;
        }
      }

      const pakket = find(this.objecten, { naam: "Pakket" });
      this.addRow(catID, {
        aantal: 1,
        object: pakket,
        maat: pakket.maten[0],
        maatID: pakket.maten[0].ID,
        datum: new Date(),
      });

      this.save();
    },
    forceUpdate: function () {
      this.updateCount++;
    },
    selectedNewItem: function (selectedOption, item) {
      item.maat = null;
      if (
        selectedOption &&
        selectedOption.maten &&
        selectedOption.maten.length == 1
      ) {
        item.maat = selectedOption.maten[0];
      }
      this.updateCount++;
    },
    getDate: (d) => {
      const date = new Date(d);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    validate: function () {
      for (let i in this.gekregen) {
        if (!this.gekregen[i]) {
          continue;
        }
        for (let gekregen of this.gekregen[i]) {
          if (!gekregen.object || !gekregen.object.ID) {
            throw new Error("Gekregen niet ingevuld!");
          }

          if (gekregen.object.categorie.opMaat && !gekregen.maat) {
            throw new Error("Maat niet ingevuld");
          }
          if (!gekregen.datum) {
            throw new Error("Datum niet ingevuld!");
          }
        }
      }
    },
    addRow: function (catID, obj) {
      if (!obj) {
        this.gekregen[catID] = [
          {
            aantal: 1,
            datum: new Date(),
          },
        ].concat(this.gekregen[catID]);
      } else {
        this.gekregen[catID] = [obj].concat(this.gekregen[catID]);
      }
      this.updateCount++;
    },
    removeRow: function (catID, obj) {
      if (obj.ID > 0) {
        this.deleted.push(obj.ID);
      }
      this.gekregen[catID] = this.gekregen[catID].filter((aObj) => aObj != obj);
      this.updateCount++;
    },
    save: async function () {
      try {
        this.validate();

        const response = await zeephuisjeService.saveForNumber(
          this.id,
          this.info
        );
        if (response.status !== "ok") {
          throw new Error(response.message);
        }
        this.storeInfo(response.data);

        for (let i in this.gekregen) {
          if (!this.gekregen[i]) {
            continue;
          }

          for (let j in this.gekregen[i]) {
            if (!this.gekregen[i][j]) {
              continue;
            }
            if (this.gekregen[i][j].maat) {
              this.gekregen[i][j].maatID = this.gekregen[i][j].maat.ID;
            }
            const resp = await zeephuisjeService.saveRow(
              this.id,
              this.gekregen[i][j]
            );
            if (resp.status !== "ok") {
              throw new Error(resp.message);
            }
          }
        }

        for (let id of this.deleted) {
          const resp = await zeephuisjeService.deleteRow(this.id, id);
          if (resp.status !== "ok") {
            throw new Error(resp.message);
          }
        }

        this.deleted = [];

        await this.loadAllCategories();

        this.$Simplert.open({
          title: "Opgeslagen!",
          message: "",
          type: "success",
          customCloseBtnText: "Sluiten",
        });

        return true;
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
        return false;
      }
    },
    hasChanges: function () {
      if (this.deleted.length > 0) {
        return true;
      }

      if (!this.originalData) {
        return false;
      }
      const old = JSON.parse(this.originalData);
      if (old.opmerking !== this.info.opmerking) {
        return true;
      }

      for (let catID in this.gekregen) {
        if (!this.gekregen[catID]) {
          continue;
        }

        if (
          JSON.stringify(this.gekregen[catID], getCircularReplacer()) !==
          this.originalGekregen[catID]
        ) {
          return true;
        }
      }

      return false;
    },
    storeInfo: function (data) {
      this.originalData = JSON.stringify(data);

      // a poor woman's copy function
      this.info = JSON.parse(this.originalData);
      this.info.gekregen = null;
    },
    goBack: function () {
      let vm = this;
      if (this.hasChanges()) {
        this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: function () {
            vm.save().then((ok) => {
              if (ok) {
                vm.$router.push({ name: "zeephuisje-search" });
              }
            });
          },
          onClose: function () {
            vm.$router.push({ name: "zeephuisje-search" });
          },
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Teruggaan zonder opslagen",
        });
      } else {
        vm.$router.push({ name: "zeephuisje-search" });
      }
    },
    pageClickCallback: async function (num, catID) {
      if (this.hasChanges()) {
        this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: () => {
            this.save().then((ok) => {
              if (ok) {
                this.loadPage(num, catID);
              }
            });
          },
          onClose: () => {
            this.loadPage(num, catID);
          },
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Verdergaan zonder opslagen",
        });

        return;
      }
      this.loadPage(num, catID);
    },
    loadInvisibleTotalen: async function () {
      try {
        for (let catID in this.categoryForID) {
          if (!this.categoryForID[catID]) {
            continue;
          }

          let page = 2;
          let needsMore = true;
          while (needsMore) {
            const { data } = await zeephuisjeService.lookPageUpNumber(
              this.id,
              page,
              catID
            );
            page++;
            if (data.length <= 0) {
              needsMore = false;
              break;
            }
            console.log(data);
            for (let entry of data) {
              // if more than a year ago
              if (
                new Date(entry.datum).getTime() <
                new Date().getTime() - 1000 * 60 * 60 * 24 * 365
              ) {
                needsMore = false;
                break;
              }

              if (!this.invisibleTotalen[entry.objectID]) {
                this.invisibleTotalen[entry.objectID] = 0;
              }
              this.invisibleTotalen[entry.objectID] += entry.aantal;
            }
          }
        }

        console.log(this.invisibleTotalen);
        return;
      } catch (e) {
        this.$Simplert.open({
          title: "Error!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
      }
    },
    loadPage: async function (num, catID) {
      this.pageNumber[catID] = num;

      const { data, totalEntries } = await zeephuisjeService.lookPageUpNumber(
        this.id,
        num,
        catID
      );

      this.gekregen[catID] = data;

      this.originalGekregen[catID] = JSON.stringify(data);
      this.pageCount[catID] = Math.ceil(totalEntries / 10);

      if (this.pageCount[catID] < 1 || isNaN(this.pageCount[catID])) {
        this.pageCount[catID] = 1;
      }

      this.updateCount++;
    },
    loadAllCategories: async function () {
      for (let key in this.categoryForID) {
        try {
          if (this.categoryForID[key]) {
            await this.loadPage(1, this.categoryForID[key].ID);
          }
        } catch (e) {
          this.$Simplert.open({
            title: "Error!",
            message: e,
            type: "error",
            customCloseBtnText: "Sluiten",
          });
        }
      }
    },
  },
  created: async function () {
    this.loading = true;

    let ledenResponse;
    let zeephuisjeResponse;
    try {
      ledenResponse = await ledenService.get(this.id);
      zeephuisjeResponse = await zeephuisjeService.lookUpNumber(this.id);
      this.objecten = await zeephuisjeService.getObjectOptions();
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }

    this.klant = ledenResponse;

    const huishoudenData = [];

    for (let lid of ledenResponse.gezin.leden) {
      // note to future self, you will regret this slice. I told you so!
      // update november 2021, still not! the double code however...
      // update july 2022, THANK GOD FOR THE NEW API!

      // sv is because they use the ISO format for dates :D
      let info = `${new Date(lid.geboortedatum).toLocaleDateString("sv")} ${lid.geslacht
        } - ${lid.voornaam} ${lid.achternaam}`;

      if (info.length > 45) {
        info = info.substr(0, 42) + "...";
      }

      huishoudenData.push(
        `${info} <span class="jarig"> ${needsLuiers(lid.geboortedatum) ? "-- LUIERS" : ""
        }</span>`
      );
    }
    this.klant.huishouden = huishoudenData.sort().reverse().join("</br>");

    this.storeInfo(zeephuisjeResponse);

    for (let object of this.objecten) {
      // add info to categoryForID for cat info lookup
      if (!this.categoryForID[object.categorie.ID]) {
        this.categoryForID[object.categorie.ID] = object.categorie;
        this.categoryForID[object.categorie.ID].opties = [];
      }

      this.categoryForID[object.categorie.ID].opties.push(object);
    }

    await this.loadAllCategories();
    await this.loadInvisibleTotalen();

    this.loading = false;
  },
};
</script>

<style
  src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
>

</style>
<style>
.stripe {
  background-color: #c6cbd0;
}

.jarig {
  color: #ff391e;
}

.gezinsleden {
  font-family: "Roboto Mono", monospace;
}
</style>
