<template>
  <div class="container" id="app">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-12 mb-2 mt-2">
          <span class="float-start">
            <button type="button" class="btn btn-light btn-lg" :disabled="saving" v-on:click="goBack()">
              <i class="fad fa-long-arrow-left" />Terug
            </button>
          </span>
        </div>
      </div>
      <h3 class="mb-3"><span v-if="id == 0">Markt Aanmaken</span><span v-else>Markt &quot;{{ data.naam }}&quot;
          Bewerken</span></h3>

      <form autocomplete="off">
        <div class="row">
          <div class="col-6">
            <label for="naam" class="form-label">Naam</label>
            <input type="text" class="form-control" id="naam" v-model="data.naam" autocomplete="off" />
          </div>
          <div class="col-6">
            <label for="beschrijving" class="form-label">Beschrijving
            </label>
            <textarea class="form-control" v-model="data.beschrijving" rows="5"></textarea>
          </div>
          <div class="col-6">
            <label for="startdatum" class="form-label">Startdatum</label>
            <datepicker id="startdatum" :format="'yyyy-MM-dd'" class="form-control" v-model="data.startDatum"
              initial-view="month" autocomplete="off" :language="nl" typeable="true">
            </datepicker>
          </div>
          <div class="col-6">
            <label for="einddatim" class="form-label">Einddatum</label>
            <datepicker id="einddatim" :format="'yyyy-MM-dd'" class="form-control" v-model="data.eindDatum"
              initial-view="month" autocomplete="off" :language="nl" typeable="true">
            </datepicker>
          </div>
          <div class="col-12 mt-5">
            <div class="form-check controle form-switch">
              <label class="form-check-label" for="voorKinderen">Markt voor kindermateriaal </label>

              <input class="form-check-input" type="checkbox" id="controle" v-model="data.voorKinderen" />
            </div>
          </div>
        </div>
        <hr />
        <div class="row" v-if="data.voorKinderen">
          <h4>Lijst Kinderen</h4>
          <div class="col-6">
            <label for="naam" class="form-label">Minimum leeftijd (inclusief)</label>
            <input type="number" class="form-control" id="naam" v-model="data.minKindLeeftijd" autocomplete="off"
              min="0" />
          </div>
          <div class="col-6">
            <label for="naam" class="form-label">Maximum leeftijd (inclusief)</label>
            <input type="number" class="form-control" id="naam" v-model="data.maxKindLeeftijd" autocomplete="off"
              min="0" />
          </div>
        </div>
        <hr v-if="data.voorKinderen" />
        <div class="row">
          <div class="col-12">
            <span class="float-end">
              <button type="button" class="btn btn-success btn-lg" v-on:click="save()"
                :disabled="saving || !hasChanges()">
                <i class="far fa-save" /> Opslaan
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { marktenService } from "../../_services/markten.service";

import Datepicker from "@maartje/vuejs-datepicker";

export default {
  props: ["id"],
  components: {
    Datepicker,
  },
  data: function () {
    return {
      loading: true,
      originalData: "", //JSON stored here
      saving: false,
      data: {},
    };
  },
  methods: {
    hasChanges: function () {
      if (JSON.stringify(this.data) != this.originalData) {
        return true;
      }

      return false;
    },
    validate: function () {
      if (!this.data.naam) {
        throw new Error("Naam niet ingevuld!");
      }
      if (!this.data.beschrijving) {
        throw new Error("Beschrijving niet ingevuld!");
      }
      if (!this.data.startDatum) {
        throw new Error("Startdatum niet ingevuld!");
      }
      if (!this.data.eindDatum) {
        throw new Error("Einddatum niet ingevuld!");
      }
      // if eindDatum is before startDatum
      if (this.data.eindDatum < this.data.startDatum) {
        throw new Error("Einddatum mag niet voor de startdatum zijn!");
      }
    },
    save: async function () {
      try {
        this.validate();

        if (this.id == 0) {
          await marktenService.add(this.data);
        } else {
          await marktenService.update(this.id, this.data);
        }

        this.originalData = JSON.stringify(this.data);
      } catch (e) {
        this.$Simplert.open({
          title: "Error bij opslaan!",
          message: e,
          type: "error",
          customCloseBtnText: "Sluiten",
        });
        return false;
      }

      this.$Simplert.open({
        title: "Opgeslagen!",
        message: "De markt is opgeslagen!",
        type: "success",
        customCloseBtnText: "Sluiten",
        onClose: function () {
          this.$router.push({ name: "markten-beheer" });
        },
      });
    },
    goBack: function () {
      let vm = this;
      if (this.hasChanges()) {
        this.$Simplert.open({
          title: "Er zijn niet opgeslagen wijzigingen!",
          message: "wil je deze opslaan?",
          type: "info",
          useConfirmBtn: true,
          onConfirm: function () {
            vm.save().then((ok) => {
              if (ok) {
                vm.$router.push({ name: "markten-beheer" });
              }
            });
          },
          onClose: function () {
            vm.$router.push({ name: "markten-beheer" });
          },
          customConfirmBtnClass: "btn btn-warning",
          customConfirmBtnText: "Opslaan",
          customCloseBtnText: "Teruggaan zonder opslagen",
        });
      } else {
        vm.$router.push({ name: "markten-beheer" });
      }
    },
  },

  created: async function () {
    this.loading = true;

    try {
      if (this.id == 0) {
        // new
        this.data = {
          naam: "",
          beschrijving: "",
          minKindLeeftijd: 0,
          maxKindLeeftijd: 12,
        }

        this.loading = false;
        this.originalData = JSON.stringify(this.data);
        return;
      }

      const markt = await marktenService.get(this.id);
      this.data = markt;
      this.originalData = JSON.stringify(markt);

      this.loading = false;
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }
  },
};
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
ul {
  padding-left: 20px;
}

.multiselect__element {
  display: block;
  background-color: #e4f1e4;
}

.materiaal-info h5 {
  font-size: 1.1rem;
}

.info {
  font-weight: bolder;
  font-size: 1.4rem;
}

/* this is a pain */
.vdp-datepicker {
  width: 150px;
}

.vdp-datepicker input {
  width: 150px;
}

.table .table-dark th .vdp-datepicker {
  color: #000;
}

#v-pills-tabContent {
  width: 100%;
}

.nav-link[disabled] {
  color: rgb(190, 190, 190);
}

.nav-link[disabled]:hover {
  color: rgb(190, 190, 190);
}
</style>
