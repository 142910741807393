import { DateTime } from "luxon";

const toInternalDate = (input) => {
  const d = new Date(input);
  return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
};

export const needsMelkpoeder = (result) => {
  if (!result.gekregen || result.gekregen.length < 1) {
    return false;
  }

  const datum = toInternalDate(result.gekregen[0].datum);

  for (let entry of result.gekregen) {
    if (
      entry.object &&
      entry.object.naam &&
      entry.object.naam.indexOf("Melkpoeder") > -1
    ) {
      if (toInternalDate(entry.days) !== datum) {
        return false; // not the last date anymore
      }
      return true;
    }
  }

  return false;
};

export const needsVerjaardag = (lid) => {
  for (let gezinsLid of lid.gezin.leden) {
    if (
      isJarig(gezinsLid.geboortedatum) &&
      (gezinsLid.type == "kind" || gezinsLid.gezinsHoofd)
    ) {
      return true;
    }
  }

  return false;
};

// JS version of ZOHO button
export const voedingVandaag = async (data) => {
  const today = new Date();
  for (let pakket of data.Geschiedenis) {
    if (pakket.Datum == formatDate(today)) {
      throw new Error("Al pakket gekregen vandaag");
    }
  }

  const newGeschiedenis = [
    {
      Datum: formatDate(today),
      Gekregen: ["Voeding"],
    },
  ].concat(data.Geschiedenis);

  data.Geschiedenis = newGeschiedenis;

  await window.ZOHO.CRM.API.updateRecord({
    Entity: "Voeding",
    Trigger: ["workflow"],
    APIData: data,
  });
};

function formatDate(d) {
  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

export const isJarig = (geboorteDatum) => {
  if (!geboorteDatum) {
    return false;
  }
  geboorteDatum = new Date(geboorteDatum);
  if (geboorteDatum.getFullYear() < 1900) {
    return -1;
  }
  console.log(geboorteDatum);
  const gebDatum = DateTime.fromObject({
    year: DateTime.now().year,
    month: new Date(geboorteDatum).getMonth() + 1,
    day: new Date(geboorteDatum).getDate(),
  }).startOf("day");

  const nextWeek = DateTime.now().startOf("day").plus({ days: 7 });
  const today = DateTime.now().startOf("day");

  return (
    today.valueOf() <= gebDatum.valueOf() &&
    nextWeek.valueOf() > gebDatum.valueOf()
  );
};
