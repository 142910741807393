import config from "config";

const read = () => {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `http://localhost:8090/read?token=${config.eidToken}`,
    requestOptions
  ).then(handleResponse);
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

export const eidService = {
  read,
};
