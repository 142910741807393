import config from "config";
import { authHeader } from "../_helpers/auth-header";
import { authService } from "../_services/auth.service";
import { getCircularReplacer } from "../_helpers/json";

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

const get = (id) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/lid/${id}`, requestOptions).then(
    handleResponse
  );
};

const getTransitional = (id) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/zoho/transitional/lid/${id}`,
    requestOptions
  ).then(handleResponse);
};

const getAllForRRNTransitional = (rrn) => {
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/zoho/transitional/leden/?page=$1&page_size=10&rijksregisternummer=${rrn}`,
    requestOptions
  ).then(handlePagedResponse);
};

const getAllForRRN = (rrn) => {
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/v1/leden/?page=$1&page_size=10&rijksregisternummer=${rrn}`,
    requestOptions
  ).then(handlePagedResponse);
};

const getAllTransitional = (page, search) => {
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/zoho/transitional/leden/?page=${page}&page_size=10${
      search ? "&search=" + search : ""
    }`,
    requestOptions
  ).then(handlePagedResponse);
};

const getAll = (page, search, orderBy = '', pageSize = 10) => {
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/v1/leden/?page=${page}&page_size=${pageSize}&order=${orderBy}${
      search ? "&search=" + search : ""
    }`,
    requestOptions
  ).then(handlePagedResponse);
};

const search = (search = '', rijksregisternummer = '') => {
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/v1/lid/search?search=${search}&rijksregisternummer=${rijksregisternummer}`,
    requestOptions
  ).then(handlePagedResponse);
};

const handlePagedResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    const totalEntries = parseInt(
      response.headers.get("Num-Total-Entries"),
      10
    );

    return { data, totalEntries };
  });
};

const add = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(`${config.apiUrl}/v1/lid/`, requestOptions).then(handleResponse);
};

const remove = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/lid/${id}`, requestOptions).then(
    handleResponse
  );
};

const update = (id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(`${config.apiUrl}/v1/lid/${id}`, requestOptions).then(
    handleResponse
  );
};

const addGezinsLid = (mvmnummer, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/lid/${mvmnummer}/gezinslid`,
    requestOptions
  ).then(handleResponse);
};

const updateGezinsLid = (mvmnummer, id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/lid/${mvmnummer}/gezinslid/${id}`,
    requestOptions
  ).then(handleResponse);
};

const deleteGezinsLid = (mvmnummer, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/lid/${mvmnummer}/gezinslid/${id}`,
    requestOptions
  ).then(handleResponse);
};

const addLidmaatachap = (mvmnummer, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/lid/${mvmnummer}/lidmaatschap`,
    requestOptions
  ).then(handleResponse);
};

const updateLidmaatschap = (mvmnummer, id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/lid/${mvmnummer}/lidmaatschap/${id}`,
    requestOptions
  ).then(handleResponse);
};

const getGekregen = (mvmnummer, page) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/lid/${mvmnummer}/gekregen?page=${page}&page_size=20`,
    requestOptions
  ).then(handlePagedResponse);
};

const getGekregenVoeding = (mvmnummer) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/lid/${mvmnummer}/gekregen/voeding`,
    requestOptions
  ).then(handleResponse);
};


export const ledenService = {
  get: (id) => (config.zoho ? getTransitional(id) : get(id)),
  getAll: (page, search, orderBy, pageSize) =>
    config.zoho ? getAllTransitional(page, search) : getAll(page, search, orderBy, pageSize),
  getAllForRRN: (rrn) =>
    config.zoho ? getAllForRRNTransitional(rrn) : getAllForRRN(rrn),
  search,
  add,
  remove,
  update,
  addGezinsLid,
  updateGezinsLid,
  deleteGezinsLid,
  addLidmaatachap,
  updateLidmaatschap,
  getGekregen,
  getGekregenVoeding,
};
