export const formatRRN = (newRRN) => {
  // format to xx.xx.xx-xxx.xx
  newRRN = `${newRRN.substring(0, 2)}.${newRRN.substring(
    2,
    4
  )}.${newRRN.substring(4, 6)}-${newRRN.substring(6, 9)}.${newRRN.substring(
    9,
    11
  )}`;
  return newRRN.replace(/\.*-*\.*$/g, "");
};
