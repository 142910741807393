import config from "config";
import { authHeader } from "../_helpers/auth-header";
import { authService } from "../_services/auth.service";
import { getCircularReplacer } from "../_helpers/json";

const getObjectOptions = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/objects`,
    requestOptions
  ).then(handleResponse);
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

const add = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(`${config.apiUrl}/v1/punctuelehulp/`, requestOptions).then(
    handleResponse
  );
};

const get = (id) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/punctuelehulp/${id}`, requestOptions).then(
    handleResponse
  );

  // transitional API demo
  /*return fetch(
    `${config.apiUrl}/zoho/transitional/lid/MVM1958`,
    requestOptions
  ).then(handleResponse);*/
};

const getAllForRRN = (rrn) => {
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/?page=$1&page_size=10&rijksregisternummer=${rrn}`,
    requestOptions
  ).then(handlePagedResponse);
};

const getAll = (page, search) => {
  const requestOptions = {
    method: "GET",
    headers: {
      mode: "cors",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/?page=${page}&page_size=10${
      search ? "&search=" + search : ""
    }`,
    requestOptions
  ).then(handlePagedResponse);
};

const handlePagedResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        authService.logout();
        location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    const totalEntries = parseInt(
      response.headers.get("Num-Total-Entries"),
      10
    );

    return { data, totalEntries };
  });
};

const remove = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/v1/punctuelehulp/${id}`, requestOptions).then(
    handleResponse
  );
};

const update = (id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(`${config.apiUrl}/v1/punctuelehulp/${id}`, requestOptions).then(
    handleResponse
  );
};

const addGezinsLid = (enummer, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/${enummer}/gezinslid`,
    requestOptions
  ).then(handleResponse);
};

const updateGezinsLid = (enummer, id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/${enummer}/gezinslid/${id}`,
    requestOptions
  ).then(handleResponse);
};

const deleteGezinsLid = (enummer, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/${enummer}/gezinslid/${id}`,
    requestOptions
  ).then(handleResponse);
};

const getGekregen = (enummer, page) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/${enummer}/gekregen?page=${page}&page_size=20`,
    requestOptions
  ).then(handlePagedResponse);
};

const addGekregen = (enummer, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/${enummer}/gekregen`,
    requestOptions
  ).then(handleResponse);
};

const updateGekregen = (enummer, id, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(data, getCircularReplacer()),
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/${enummer}/gekregen/${id}`,
    requestOptions
  ).then(handleResponse);
};

const removeGekregen = (enummer, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/v1/punctuelehulp/${enummer}/gekregen/${id}`,
    requestOptions
  ).then(handleResponse);
};

export const punctueleService = {
  getObjectOptions,
  add,
  get,
  getAll,
  remove,
  update,
  addGezinsLid,
  updateGezinsLid,
  deleteGezinsLid,
  getGekregen,
  addGekregen,
  updateGekregen,
  removeGekregen,
  getAllForRRN,
};
